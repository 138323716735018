import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "form",
  initialState: {
    createData: null,
    snackbarData: null,
    propertyData: null,
  },
  reducers: {
    setCreateSuccess: (state, action) => {
      state.createData = action.payload;
    },
    setCreateFailed: (state, action) => {
      state.createData = action.payload;
    },
    setCreateSnackbar: (state, action) => {
      state.snackbarData = action.payload;
    },
    UpdatePropertyDetailsSuccess: (state, action) => {
      state.propertyData = action.payload;
    },
  },
});

export const {
  setCreateSuccess,
  setCreateFailed,
  setCreateSnackbar,
  UpdatePropertyDetailsSuccess,
} = formSlice.actions;

export default formSlice.reducer;
