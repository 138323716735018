const ModalHead = ({ title, children }) => {
  return (
    <div className="modal_header">
      <p className="modal_title">{title}</p>
      {children}
    </div>
  );
};

export default ModalHead;
