import React, { useEffect, useState } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

export default function Search({
  panTo,
  description,
  onSelect,
  innerRef,
  placeholder,
  hideMap,
}) {
  const [locDetails, setLocDetails] = useState({});
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: ["us"] },
      types: ["address"],
    },
    debounce: 300,
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const LoadAddress = (description) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => {
        console.log("Result=--------->", results[0]);

        const addressComponents = results[0].address_components;
        let streetNumber = "";
        let area = "";
        let city = "";
        let county = "";
        let state = "";
        let country = "";
        let postalCode = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("street_number")) {
            streetNumber = component.long_name;
          }
          if (component.types.includes("route")) {
            area = component.long_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_2")) {
            county = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            postalCode = component.long_name;
          }
        });
        console.log("streetNumber=------->", streetNumber);
        console.log("area=------->", area);
        console.log("city=------->", city);
        console.log("county=------->", county);

        console.log("state=------->", state);
        console.log("country=------->", country);
        console.log("postalCcode=------->", postalCode);

        onSelect(description, {
          streetNumber: streetNumber,
          area: area,
          city: city,
          county: county,
          state: state,
          country: country,
          postalCode: postalCode,
        });
        setLocDetails(results[0]);
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        if (!hideMap) {
          panTo({ lat, lng });
        }
      })
      .catch((error) => {});
  };

  const handleSelect =
    ({ description }) =>
    () => {
      console.log("description=--------->", description);
      LoadAddress(description);
    };

  useEffect(() => {
    if (description && description !== "") {
      LoadAddress(description);
    } else {
      setValue("");
    }
  }, [description]);

  const renderSuggestions = () =>
    data.map((suggestion) => {
      console.log("Data=------->", data);
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          style={{ padding: "10px", cursor: "pointer" }}
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          {main_text} {secondary_text}
        </li>
      );
    });

  return (
    <div>
      <input
        refs={innerRef}
        value={value ?? ""}
        onChange={handleInput}
        disabled={!ready}
        placeholder={placeholder}
        className="location_search_input"
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
}
