import React, { useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";

function TextEditor({
  label,
  value,
  // change,
  focus,
  onChange,
  modules,
  innerRef,
  onKeyDown,
  height,
}) {
  const qillRefs = React.useRef(null);

  useEffect(() => {
    if (focus) qillRefs.current.editor.focus();
  }, [focus]);

  return (
    <div style={{ width: "100%", marginBottom: "50px" }}>
      <label className="label_text">{label}</label>
      <ReactQuill
        ref={innerRef ?? qillRefs}
        id="xys"
        theme="snow"
        value={value}
        onChange={onChange}
        style={{ height: height ?? "200px" }}
        modules={modules}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}

export default TextEditor;
