import { takeLatest, put, fork, call } from "redux-saga/effects";
import { createItem, editItem, getItem } from "../../services/service";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";
import {
  cancelAppointment,
  createNewAppointment,
  editAppointment,
  getAppointmentList,
  setAppointmentLoadingFalse,
  setAppointmentList,
} from "../slices/appointmentSlice";
import { setDialog } from "../slices/dialogSlice";

function* onCreateAppointmentAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAppointmentLoadingFalse());
  }
}

function* onCreateAppointment() {
  yield takeLatest(createNewAppointment.type, onCreateAppointmentAsync);
}

function* onLoadAppointmentListAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setAppointmentList([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAppointmentLoadingFalse());
  }
}

function* onLoadAppointmentList() {
  yield takeLatest(getAppointmentList.type, onLoadAppointmentListAsync);
}

function* onEditAppointmentAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAppointmentLoadingFalse());
  }
}

function* onEditAppointment() {
  yield takeLatest(editAppointment.type, onEditAppointmentAsync);
}

function* onCancelAppointmentAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAppointmentLoadingFalse());
  }
}

function* onCancelAppointment() {
  yield takeLatest(cancelAppointment.type, onCancelAppointmentAsync);
}

export const appointmentSagas = [
  fork(onCreateAppointment),
  fork(onLoadAppointmentList),
  fork(onEditAppointment),
  fork(onCancelAppointment),
];
