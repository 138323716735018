import { useEffect } from "react";
import LoginBg from "../../assets/images/loginbg.png";
import Button from "../../components/buttons/Button";
import "./style.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserRegister } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LeftBanner from "./LeftBanner";

const schema = yup.object().shape({
  displayName: yup
    .string()
    .min(3, "Name must be 3 characters at minimum")
    .required("Name is required"),
  email: yup
    .string()
    .email("Email address is invalid")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be 8 characters at minimum")
    .max(32, '"Password must be 32 characters at maximum"')
    .required("Password is required"),
  terms: yup
    .boolean()
    .oneOf([true], "Please agree our terms and condition to register"),
});

const Register = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const loading = useSelector((state) => state.auth.loading);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { terms: false },
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (data.terms) {
      dispatch(getUserRegister(data));
    }
  };

  useEffect(() => {
    if (!loading && userDetails) {
      navigate("/");
    }
  }, [loading, userDetails]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="login">
      <LeftBanner />
      <div className="login_right">
        <div className="login_form_wrapper">
          <h2 className="lets_started">Let’s Get Started</h2>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <label className="text_medium">FullName</label>
            <input
              type="text"
              name="displayName"
              {...register("displayName", {
                required: true,
              })}
            />

            <p className="error_message">{errors.displayName?.message}</p>
            <label className="text_medium">Email</label>
            <input
              type="email"
              name="email"
              {...register("email", {
                required: true,
                pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              })}
            />
            <p className="error_message">{errors.email?.message}</p>
            <label className="text_medium">Password</label>
            <input
              type="password"
              name="password"
              {...register("password", { required: true, minLength: 6 })}
            />
            <p className="error_message">{errors.password?.message}</p>
            <label className="custom_checkbox">
              <input
                type="checkbox"
                name="terms"
                {...register("terms", { required: true })}
              />
              I agree with
              <Link to={"/"} className="terms_text">
                Terms & Conditions
              </Link>
              <span className="greenmark"></span>
            </label>
            <p className="error_message">{errors.terms?.message}</p>

            <Button type="submit" className="button_lg primary_button">
              Sign Up
            </Button>
            <p className="text_medium text_light_gray text_center">
              Already have an account?
              <Link className="terms_text" to="/login">
                Sign In
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
