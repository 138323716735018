import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ComingSoon = () => {
  return (
    <div className="d_flex flex_column align_items_center justify_content_center">
      <WarningAmberIcon />
      <p style={{ margin: 0 }}>Under Construction</p>
    </div>
  );
};

export default ComingSoon;
