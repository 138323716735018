import ComingSoon from "../../../components/common/ComingSoon";

const TransferOwnership = () => {
  return (
    <div style={{ padding: "40px" }}>
      <ComingSoon />
    </div>
  );
};

export default TransferOwnership;
