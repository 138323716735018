import { useEffect, useRef, useState } from "react";
import Loading from "../../../components/loading/Loading";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import "./style.scss";
import CustomBreadcrumb from "../../../components/breadcrumb/CustomBreadcrumb";
import { CardItem } from "../../../utils/CardItem";
import AssignedToCard from "../../../components/project/view/AssignedToCard";
import { useDispatch, useSelector } from "react-redux";
import {
  editProject,
  getProject,
  getProjectMainStatusList,
  setProjectProperty,
} from "../../../redux/slices/projectSlice";
import ProjectAppointments from "../../../components/project/view/appointment/ProjectAppointments";
import ProjectDeals from "../../../components/project/view/ProjectDeals";
import CustomerDetails from "../../../components/project/view/CustomerDetails";
import ProjectDetailsTab from "../../../components/project/view/ProjectDetailsTab";
import ProjectInfo from "../../../components/project/view/ProjectInfo";
import ProjectForm from "../create/ProjectForm";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import Storage from "../../../utils/Storage";
import CustomChat from "../../../components/chat/CustomChat";
import Talk from "talkjs";
import { setCreateSuccess } from "../../../redux/slices/formSlice";
import StatusTimeline from "../../../components/project/view/StatusTimeline";
import { getAppointmentList } from "../../../redux/slices/appointmentSlice";
import { clearCurrentFile } from "../../../redux/slices/fileUploadSlice";
import BOMCard from "../../../components/project/view/bom/BOMCard";
import FileFolders from "../../../components/project/view/filefolders/FileFolders";
import BomAdderCard from "../../../components/project/view/bom/BomAdderCard";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import ViewTitleBar from "../../../components/viewtitle/ViewTitleBar";
import Status from "../../../components/status/Status";
import ProjectAssignDetails from "../../../components/project/create/ProjectAssignDetails";
import AssignToIcon from "../../../assets/images/assignto.svg";
import CreateDateIcon from "../../../assets/images/createdate.svg";
import { getOrgUserList } from "../../../redux/slices/orgSlice";
import { stringAvatar } from "../../../utils/util";
import DateView from "../../../components/date/DateView";
import PropertyDetailsCard from "../../../components/project/view/propertydetails/PropertyDetailsCard";
import HoaDetails from "../../../components/project/view/hoa/HoaDetails";
import FinancialDetails from "../../../components/project/view/financialinfo/FinancialDetails";
import SystemDetails from "../../../components/project/view/systemdetails/SystemDetails";
import AhjInfo from "../../../components/project/view/AhjInfo";
import { Edit } from "@mui/icons-material";
import { getAdditionalInfo } from "../../../redux/slices/additionalInfoSlice";
import {
  a11yProps,
  CustomTabPanel,
  StyledTab,
} from "../../../components/constants/Constants";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import { getLeadOffice, getLeadSource } from "../../../redux/slices/leadSlice";
import DescriptionCard from "../../../components/project/view/DescriptionCard";
import OfficeSourceCard from "../../../components/project/view/OfficeSourceCard";

const options = [
  { name: "Edit", value: "edit" },
  { name: "Delete", value: "delete" },
];

const ProjectView = () => {
  const loading = useSelector((state) => state.project.loading);
  const projectData = useSelector((state) => state.project.projectData);
  const ahjDetails = useSelector((state) => state.additionalInfo.ahjDetails);
  const projectMainStatusList = useSelector(
    (state) => state.project.projectMainStatusList
  );
  const mainStatusLoading = useSelector(
    (state) => state.project.mainStatusLoading
  );

  const [isOpen, setIsOpen] = useState(false);

  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const { id } = useParams();
  const [conversationValue, setConversationValue] = useState();
  const [chatboxValue, setChatboxValue] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [portalCreated, setPortalCreated] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const createData = useSelector((state) => state.form.createData);
  const propertyData = useSelector((state) => state.form.propertyData);
  const [tabValue, setTabValue] = useState(0);
  const [anchorElMainStatus, setAnchorElMainStatus] = useState(null);
  const [assignee, setAssignee] = useState(projectData?.assignedto?.name ?? "");
  const orgUserLoading = useSelector((state) => state.org.orgUserLoading);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const orgUsers = useSelector((state) => state.org.orgUsers);
  const [value, setValue] = useState(0);

  const [isExpanded, setIsExpanded] = useState(false);

  const breadcrumbs = [
    <Link key="1" to="/projects">
      Projects
    </Link>,
    <Typography key="2" color="text.primary">
      Project detail
    </Typography>,
  ];

  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const chatboxRef = useRef(null);

  const loadPortal = async () => {
    await Talk.ready
      .then(() => {
        /* Create the two users that will participate in the conversation */

        const me = new Talk.User({
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-7.jpg",
          // welcomeMessage: welcomeMessage ?? null,
          // welcomeMessage: welcomeMessage,
          role: "default",

          custom: {
            type: "projects",
            typeId: id,
          },
        });
        const other = new Talk.User({
          id: projectData?.customerInfo?.pContact?.id,
          name:
            projectData?.customerInfo?.pContact?.firstName +
            " " +
            projectData?.customerInfo?.pContact?.lastName,
          email: projectData?.customerInfo?.pContact?.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-8.jpg",
          // welcomeMessage: "Hey, how can I help?",

          role: "customer",
          // custom: {
          //   typeId: projectData?.id,
          //   type: "projects",
          // },
        });

        /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId: process.env.REACT_APP_CHAT_APP_ID,
            me: me,
          });
        }

        // /* Get a conversation ID or create one */

        const conversation = window.talkSession.getOrCreateConversation(
          projectData?.chatId
        );

        /* Set participants of the conversations */
        conversation.setParticipant(me);
        conversation.setParticipant(other);

        const chatbox = window.talkSession.createChatbox(conversationValue);
        chatboxRef.current = chatbox;
        // Mount the chatbox to the container
        if (containerRef.current && chatbox) {
          chatbox.mount(containerRef.current).then(() => {
            setPortalCreated(true);
          });
        }
      })
      .catch((e) => console.error(e));
  };

  const loadTalkApp = () => {
    Talk.ready
      .then(() => {
        /* Create the two users that will participate in the conversation */
        const me = new Talk.User({
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-7.jpg",

          role: "default",

          custom: {
            type: "projects",
            typeId: id,
          },
        });
        const other = new Talk.User({
          id: projectData?.customerInfo?.pContact?.id,
          name:
            projectData?.customerInfo?.pContact?.firstName +
            " " +
            projectData?.customerInfo?.pContact?.lastName,
          email: projectData?.customerInfo?.pContact?.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-8.jpg",
          // welcomeMessage: "Hey, how can I help?",

          role: "customer",
          // custom: {
          //   typeId: projectData?.id,
          //   type: "projects",
          // },
        });

        /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId: process.env.REACT_APP_CHAT_APP_ID,
            me: me,
          });
        }

        // /* Get a conversation ID or create one */
        const conversation = window.talkSession.getOrCreateConversation(
          projectData?.chatId
        );

        /* Set participants of the conversations */
        conversation.setParticipant(me);
        conversation.setParticipant(other);
        setConversationValue(conversation);
        // containerRef.current.style.display = "none";
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (anchorElUser) {
      dispatch(
        getOrgUserList({
          url: `crm/user/organization/${orgId}/users`,
          params: {},
        })
      );
    }
  }, [anchorElUser]);

  useEffect(() => {
    /* Retrieve the two users that will participate in the conversation */

    /* Session initialization code */
    if (!loading && userInfo && projectData && projectData.chatId) {
      loadTalkApp();
    }

    if (projectData?.assignedto?.name) {
      setAssignee(projectData?.assignedto?.name);
    }
  }, [loading, projectData]);

  const handleClick = () => {
    if (conversationValue) {
      // setChatVisible(true);
      /* Create and mount chatbox in container */

      const chatbox = window.talkSession.createChatbox(conversationValue);
      chatboxRef.current = chatbox;
      setChatboxValue(chatbox);
      // Mount the chatbox to the container
      if (containerRef.current && chatbox) {
        chatbox.mount(containerRef.current);
        if (containerRef.current.style.display === "none") {
          containerRef.current.style.display = "block";
        }
      }
    }
  };

  const handleSendPortal = () => {
    loadPortal();
  };

  const sentPortalMessage = async () => {
    const chatData = [
      {
        text: "Hi!, Welcome to sunscape",
        sender: userInfo.id.toString(),
        type: "UserMessage",
      },
    ];

    try {
      const response = await fetch(
        `https://api.talkjs.com/v1/${process.env.REACT_APP_CHAT_APP_ID}/conversations/${projectData?.chatId}/messages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CHAT_APP_SECRET_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(chatData),
        }
      );

      dispatch(
        setCreateSuccess({
          message: "Email notification sent successfully",
          createSuccess: true,
          statusCode: response?.status,
        })
      );
      if (!response.ok) {
        throw new Error("Failed to send notification");
      }
    } catch (error) {
      console.error("Error sending email notification:", error.message);
    }
  };

  useEffect(() => {
    if (portalCreated) {
      sentPortalMessage();
    }

    return () => setPortalCreated(false);
  }, [portalCreated]);

  useEffect(() => {
    if (window.talkSession) {
      window.talkSession.unreads.on("change", function (unreadConversations) {
        setUnreadCount(unreadConversations[0]?.unreadMessageCount);
      });
    }
  }, [conversationValue]);

  useEffect(() => {
    if (chatboxValue) {
      chatboxValue.onCustomConversationAction("close-chat", (event) => {
        // Send the message to your backend here, so a moderator can review it.
        containerRef.current.style.display = "none";
        chatboxValue.destroy();
      });
    }
  }, [chatboxValue]);

  // const handleDocumentClick = (event) => {
  //   if (containerRef.current && !containerRef.current.contains(event.target)) {
  //     chatboxValue?.destroy();
  //   }
  // };

  const loadProjects = () => {
    dispatch(setProjectProperty(null));
    dispatch(
      getProject({
        url: `crm/org/${orgId}/proj/${id}`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (orgId) {
      loadProjects();
    }
  }, []);

  const loadAdditionalInfo = () => {
    dispatch(
      getAdditionalInfo({
        url: `/crm/org/${orgId}/proj/${id}/info?data=financialdtl&data=hoa&data=systemdtl&data=ahjdtl&data=utldtl`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (projectData?.id) {
      loadAdditionalInfo();
    }
  }, [projectData?.id]);

  const handleItem = (value) => {
    switch (value) {
      case "edit":
        setIsOpen(true);
        break;
      case "delete":
        console.log("value=------>", value);
        break;
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsViewOpen(false);
  };

  const handleMultiple = () => {
    setIsViewOpen(true);
  };

  const loadAppointmentList = () => {
    dispatch(
      getAppointmentList({
        url: `crm/org/${orgId}/proj/${id}/appointment/status/${tabValue}`,
        params: {},
      })
    );
  };

  const appointmentTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleMainStatus = (event) => {
    setAnchorElMainStatus(event.currentTarget);
  };

  const handleCloseMainStatusMenu = () => {
    setAnchorElMainStatus(null);
  };
  const loadProjectMainStatusList = () => {
    dispatch(
      getProjectMainStatusList({
        url: `crm/org/${orgId}/proj/mainStatus`,
        params: {},
      })
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (anchorElMainStatus) {
      loadProjectMainStatusList();
    }
  }, [anchorElMainStatus]);

  useEffect(() => {
    if (createData?.createSuccess || propertyData?.createSuccess) {
      loadProjects();
      dispatch(clearCurrentFile());

      // if (!loading && value === 1) {
      //   loadProjectsProgess();
      // }
      loadAdditionalInfo();
      setIsViewOpen(false);
      // dispatch(setCreateSuccess(null));
      if (!loading) {
        loadAppointmentList();
      }
    }
  }, [createData, propertyData]);

  const handleAssign = (event) => {
    event.preventDefault();
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setAnchorElUser(null);
  };

  if (!isOpen && loading) {
    return <Loading />;
  }
  console.log("project data", projectData);
  return (
    <div className="project_view">
      {/* <CustomBreadcrumb breadcrumbs={breadcrumbs} /> */}
      <div className={"title_bar"}>
        {/* <div>
          <Typography variant="h6" key="2" color="text.primary">
            {projectData?.customerInfo?.pContact?.firstName +
              " " +
              projectData?.customerInfo?.pContact?.lastName}
          </Typography>
          <div className="d_flex align_items_center">
            <img src={LocationIcon} alt="Location" />
            <p style={{ margin: 0 }}>{projectData?.address}</p>
          </div>
        </div> */}
        <div className="d_flex align_items_center">
          <ViewTitleBar
            firstName={projectData?.customerInfo?.pContact?.firstName}
            lastName={projectData?.customerInfo?.pContact?.lastName}
            email={projectData?.customerInfo?.pContact?.email}
            mobile={projectData?.customerInfo?.pContact?.mobile}
            address={projectData?.address}
          />
        </div>
        <div className="d_flex created_details">
          {/* {assignee && (
            <div className="">
              <ProjectAssignDetails
                name="assignee"
                label="Assignee"
                icon={AssignToIcon}
                value={assignee ?? "No assignee"}
                onClick={handleAssign}
                type="project_view"
              />
              {!orgUserLoading && (
                <Menu
                  sx={{ mt: "30px" }}
                  style={{ height: "400px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseStatusMenu}
                >
                  {orgUsers?.map((user, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        setAssignee(user?.name);
                        const assigneeData = {
                          assignedTo: {
                            userId: user?.userId,
                            name: user?.name,
                            uid: user?.uid,
                          },
                        };

                        dispatch(
                          editProject({
                            url: `crm/org/${orgId}/proj/${projectData?.id}`,
                            data: assigneeData,
                            params: {},
                          })
                        );
                        handleCloseStatusMenu();
                      }}
                      // {...register("assignedTo", {})}
                    >
                      <IconButton sx={{ p: 0, marginRight: "8px" }}>
                        <Avatar
                          style={{ width: "30px", height: "30px" }}
                          {...stringAvatar(user?.name)}
                        />
                      </IconButton>
                      <Typography textAlign="center">{user?.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </div>
          )} */}
          {projectData?.CreatedAt && (
            <div className="marginL20">
              <p
                className="m_0 font_12_dark_gray"
                style={{
                  textAlign: "center",
                }}
              >
                <small>Created</small>
              </p>
              <div className="d_flex align_items_center">
                <img src={CreateDateIcon} alt="Assign to" />
                <p className="font_14_dark m_0 marginL10">
                  <DateView date={projectData?.CreatedAt} />
                </p>
              </div>
            </div>
          )}

          {projectData?.dueDate && (
            <div className="marginL20">
              <p
                className="m_0 font_12_dark_gray"
                style={{
                  textAlign: "center",
                }}
              >
                <small>Due Date</small>
              </p>
              <div className="d_flex align_items_center">
                <img src={CreateDateIcon} alt="Assign to" />
                <p className="font_14_dark m_0 marginL10">
                  <DateView date={projectData?.dueDate} />
                </p>
              </div>
            </div>
          )}
          {projectData?.createdBy?.name && (
            <div className="marginL20">
              <p
                className="m_0 font_12_dark_gray"
                style={{
                  textAlign: "center",
                }}
              >
                <small>Created By</small>
              </p>
              <div className="d_flex align_items_center justify_content_center">
                <p className="font_14_dark m_0 marginL10 ">
                  {projectData?.createdBy?.name}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="d_flex align_items_center">
          {/* <ThreeDotDropdown
            options={options}
            onSelect={(value) => handleItem(value)}
          /> */}
          {projectData?.finalStatus?.name && (
            <div className="marginT10">
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={handleMainStatus}
              >
                <Status
                  status={projectData?.finalStatus?.name}
                  statusType="success"
                  type="dropdown"
                />
              </div>
              {!mainStatusLoading && (
                <Menu
                  sx={{ mt: "30px" }}
                  style={{ height: "400px" }}
                  id="menu-appbar"
                  anchorEl={anchorElMainStatus}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElMainStatus)}
                  onClose={handleCloseMainStatusMenu}
                >
                  {projectMainStatusList?.map((stage, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        // setStatus(stage);
                        // setValue("statusId", status?.id);
                        const editedData = {
                          isfinalst: stage.id,
                        };
                        console.log("editedData=---------->", editedData);

                        dispatch(
                          editProject({
                            url: `crm/org/${orgId}/proj/${id}`,
                            data: editedData,
                            params: {},
                          })
                        );
                        handleCloseMainStatusMenu();
                      }}
                    >
                      <Typography textAlign="center">{stage?.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </div>
          )}

          <IconButton
            className="marginL10"
            size="small"
            onClick={() => handleItem("edit")}
          >
            <Edit />
          </IconButton>
        </div>
      </div>
      <div
        className="view_page_content"
        style={{ paddingLeft: "25px", paddingRight: "25px" }}
      >
        <Grid sx={{ marginTop: "16px" }} container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <CardItem height={isExpanded ? "auto" : "100px"}>
              <DescriptionCard
                data={projectData}
                onToggle={() => setIsExpanded(!isExpanded)}
                isExpanded={isExpanded}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="100px">
              <OfficeSourceCard type="project" data={projectData} />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <div className="card_container">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    // textColor="secondary"
                    // indicatorColor="secondary"

                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#189d3d",
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <StyledTab
                      icon={<HomeWorkIcon />}
                      iconPosition="start"
                      label="Property Details"
                      sx={{
                        fontSize: "10px",
                      }}
                      {...a11yProps(0)}
                    />
                    <StyledTab
                      icon={<SolarPowerIcon />}
                      iconPosition="start"
                      label="System Details"
                      sx={{
                        fontSize: "10px",
                      }}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <div className="card_content">
                  <CustomTabPanel value={value} index={0}>
                    <PropertyDetailsCard
                      type="project"
                      cardData={projectData}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <SystemDetails />
                  </CustomTabPanel>
                </div>
              </div>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <StatusTimeline
                // value={value}
                isViewOpen={isViewOpen}
                handleMultiple={handleMultiple}
                // onChange={handleChange}
                onClose={handleClose}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <ProjectDetailsTab projectData={projectData} type="proj" />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <ProjectInfo viewType="project" data={projectData} />
            </CardItem>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <AhjInfo />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="570px">
              <BomAdderCard
                folderList={projectData?.folders}
                projectData={projectData}
              />
            </CardItem>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <HoaDetails />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <FinancialDetails />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <FileFolders type="proj" folderList={projectData?.folders} />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <ProjectAppointments
                type="proj"
                loadAppointmentList={loadAppointmentList}
                onChange={appointmentTabChange}
                tabValue={tabValue}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <CustomerDetails
                pContact={projectData?.customerInfo?.pContact}
                sContact={projectData?.customerInfo?.sContact}
                onChat={handleClick}
                unreadCount={unreadCount}
                onSendPortal={handleSendPortal}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="360px">
              <AssignedToCard
                assignedto={projectData?.assignedto}
                assigneelist={projectData?.assigneelist}
              />
            </CardItem>
          </Grid>

          {projectData?.leadId && (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <CardItem height="360px">
                <ProjectDeals leadId={projectData?.leadId} />
              </CardItem>
            </Grid>
          )}
        </Grid>
        <CustomChat innerRef={containerRef} />
        <BasicModal isOpen={isOpen} onClose={handleClose} sx={modalStyle}>
          <ProjectForm
            id={id}
            projectData={projectData}
            onClose={handleClose}
          />
        </BasicModal>
      </div>
    </div>
  );
};

export default ProjectView;
