import { takeLatest, put, fork, call } from "redux-saga/effects";
import { createItem, editItem, getItem } from "../../services/service";
import {
  setCreateFailed,
  setCreateSnackbar,
  setCreateSuccess,
} from "../slices/formSlice";
import { setDialog } from "../slices/dialogSlice";
import {
  createNewBom,
  createNewMaterials,
  editBom,
  editMaterials,
  getBomList,
  getMaterialList,
  getMaterialType,
  setBomList,
  setBomLoadingFalse,
  setMaterialList,
  setMaterialLoadingFalse,
  setMaterialType,
} from "../slices/materialSlice";

function* onCreateMaterialAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(
      setCreateSnackbar({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
    // yield put(
    //   setCreateSuccess({
    //     message: "material added",
    //     createSuccess: response?.status === 201 ? true : false,
    //     statusCode: response?.status,
    //   })
    // );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setMaterialLoadingFalse());
  }
}

function* onCreateMaterial() {
  yield takeLatest(createNewMaterials.type, onCreateMaterialAsync);
}

function* onCreateBomAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setBomLoadingFalse());
  }
}

function* onCreateBom() {
  yield takeLatest(createNewBom.type, onCreateBomAsync);
}

function* onLoadMaterialListAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setMaterialList([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setMaterialLoadingFalse());
  }
}

function* onLoadMaterialList() {
  yield takeLatest(getMaterialList.type, onLoadMaterialListAsync);
}

function* onLoadMaterialTypeAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setMaterialType([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setMaterialLoadingFalse());
  }
}

function* onLoadMaterialType() {
  yield takeLatest(getMaterialType.type, onLoadMaterialTypeAsync);
}

function* onEditMaterialAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setMaterialLoadingFalse());
  }
}

function* onEditMaterial() {
  yield takeLatest(editMaterials.type, onEditMaterialAsync);
}

function* onLoadBomListAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setBomList([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setBomLoadingFalse());
  }
}

function* onLoadBomList() {
  yield takeLatest(getBomList.type, onLoadBomListAsync);
}

function* onEditBomAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setBomLoadingFalse());
  }
}

function* onEditBom() {
  yield takeLatest(editBom.type, onEditBomAsync);
}

export const MaterialSagas = [
  fork(onCreateMaterial),
  fork(onLoadMaterialList),
  fork(onLoadMaterialType),
  fork(onEditMaterial),
  fork(onCreateBom),
  fork(onLoadBomList),
  fork(onEditBom),
];
