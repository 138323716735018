import "./style.scss";

const Button = ({
  children,
  className,
  type,
  onClick,
  leftIcon,
  style,
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      style={style ?? {}}
      className={className}
      type={type}
      onClick={onClick}
    >
      {leftIcon && (
        <span style={{ marginRight: "5px", marginTop: "3px" }}>{leftIcon}</span>
      )}
      {children}
    </button>
  );
};

export default Button;
