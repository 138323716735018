import { takeLatest, put, fork, call } from "redux-saga/effects";
import { createItem, getItem, editItem } from "../../services/service";
import {
  createNewLead,
  editLead,
  getLead,
  getLeadList,
  getLeadOffice,
  getLeadSource,
  setLead,
  setLeadList,
  setLeadOffice,
  setLeadSource,
  setleadLoadingFalse,
} from "../slices/leadSlice";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";
import { setDialog } from "../slices/dialogSlice";

function* onLoadLeadSourceAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setLeadSource([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setleadLoadingFalse());
  }
}

function* onLoadLeadSource() {
  yield takeLatest(getLeadSource.type, onLoadLeadSourceAsync);
}

function* onLoadLeadOfficeAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setLeadOffice([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setleadLoadingFalse());
  }
}

function* onLoadLeadOffice() {
  yield takeLatest(getLeadOffice.type, onLoadLeadOfficeAsync);
}

function* onCreateLeadAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data[0].leadoutput,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setleadLoadingFalse());
  }
}

function* onCreateLead() {
  yield takeLatest(createNewLead.type, onCreateLeadAsync);
}

function* onEditLeadAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setleadLoadingFalse());
  }
}

function* onEditLead() {
  yield takeLatest(editLead.type, onEditLeadAsync);
}

function* onLoadLeadListAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    if (response?.data?.message === "Leads are not available") {
      yield put(setLeadList([]));
    } else {
      yield put(setLeadList([...response.data]));
    }
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }

    yield put(setleadLoadingFalse());
  }
}

function* onLoadLeadList() {
  yield takeLatest(getLeadList.type, onLoadLeadListAsync);
}

function* onLoadLeadAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setLead({ ...response.data }));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setleadLoadingFalse());
  }
}

function* onLoadLead() {
  yield takeLatest(getLead.type, onLoadLeadAsync);
}

export const leadSagas = [
  fork(onLoadLeadSource),
  fork(onLoadLeadOffice),
  fork(onCreateLead),
  fork(onLoadLeadList),
  fork(onLoadLead),
  fork(onEditLead),
];
