import { call, fork, put, takeEvery } from "redux-saga/effects";
import { getItem } from "../../services/service";
import { getUserList, setUserList } from "../slices/userSlice";
import { setDialog } from "../slices/dialogSlice";

function* onLoadUserListAsync(payload){
   try {
    const {url,params}=payload.payload;
    const response=yield call(getItem,{url,params});
    console.log('response',response);
    if(response.status===200){
        yield put(setUserList([...response.data]))
    }
   } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
        yield put(setDialog(true));
      }
   }
    
}
export function* watchGetProfileData() {
    yield takeEvery(getUserList.type, onLoadUserListAsync);
  }
export const userSagas=[
    fork(watchGetProfileData)
]