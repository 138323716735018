const UserDetails = ({ image, name, email }) => {
  return (
    <div className="d_flex">
      <img className="dealer_image" src={image} alt="dealer" />
      <div className="marginL10">
        {name && <p className="no_margin dealer_name">{name}</p>}
        {email && (
          <p className="no_margin dealer_email">
            <small>{email}</small>
          </p>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
