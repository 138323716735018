import { createSlice } from "@reduxjs/toolkit";

const groupSlice=createSlice({
    name:'group',
    initialState:{
        usersInGroup:[],
        loading:false
    },
    reducers:{
        getUsersInGroup(state){
            state.loading=true;
            return state;
        },
        setUsersInGroup(state,action){
            state.usersInGroup=action.payload;
            state.loading=false;
        },
        removeUsersFromGroup(state) {
            state.loading = true;
            return state;
          },
    }
});

export const {
    getUsersInGroup,
    setUsersInGroup,
    removeUsersFromGroup
}=groupSlice.actions;

export default groupSlice.reducer;