// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map_places .location_search_input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: block;
  border-radius: 4px;
  border: 1px solid var(--input-field-border);
  background: var(--white);
  box-sizing: border-box;
  outline-color: var(--primary-green);
}
.map_places ul {
  list-style-type: none;
  padding: 0;
}
.map_places ul li:hover {
  background-color: var(--light-bg-background);
}`, "",{"version":3,"sources":["webpack://./src/components/maps/style.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;EACA,2CAAA;EACA,wBAAA;EACA,sBAAA;EACA,mCAAA;AAAJ;AAGE;EACE,qBAAA;EACA,UAAA;AADJ;AAGM;EACE,4CAAA;AADR","sourcesContent":[".map_places {\n  .location_search_input {\n    width: 100%;\n    padding: 12px 20px;\n    margin: 8px 0;\n    display: block;\n    border-radius: 4px;\n    border: 1px solid var(--input-field-border);\n    background: var(--white);\n    box-sizing: border-box;\n    outline-color: var(--primary-green);\n  }\n\n  ul {\n    list-style-type: none;\n    padding: 0;\n    li {\n      &:hover {\n        background-color: var(--light-bg-background);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
