import { takeLatest, put, fork, call } from "redux-saga/effects";
import { createItem, editItem, getItem } from "../../services/service";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";
import {
  createNewComment,
  getCommentList,
  setCommentList,
  setCommentLoadingFalse,
} from "../slices/commentSlice";
import { setDialog } from "../slices/dialogSlice";

function* onCreateCommentAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setCommentLoadingFalse());
  }
}

function* onCreateComment() {
  yield takeLatest(createNewComment.type, onCreateCommentAsync);
}

function* onLoadCommentListAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setCommentList(response.data));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setCommentLoadingFalse());
  }
}

function* onLoadCommentList() {
  yield takeLatest(getCommentList.type, onLoadCommentListAsync);
}

export const commentSagas = [fork(onCreateComment), fork(onLoadCommentList)];
