import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import { createNewGroup, editGroup } from "../../../redux/slices/orgSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import "./style.scss";

const GroupForm = ({ groupValue, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const createData = useSelector((state) => state.form.createData);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.orgId = id;

    if (groupValue && groupValue?.id) {
      dispatch(
        editGroup({
          url: `crm/user/groups/${groupValue?.id}`,
          data: data,
          params: {},
        })
      );
    } else {
      dispatch(
        createNewGroup({ url: "crm/user/groups", data: data, params: {} })
      );
    }
  };

  const onErrors = (errors) => {
    console.log("errors=---->", errors);
  };

  useEffect(() => {
    if (groupValue?.name) {
      setValue("name", groupValue?.name);
    }

    if (groupValue?.description) {
      setValue("name", groupValue?.description);
    }
  }, [groupValue]);

  useEffect(() => {
    if (createData?.createSuccess) {
      navigate(`/org/${id}/group`);
    }
  }, [createData]);

  return (
    <form
      className={type === "modal" ? "group_form" : ""}
      noValidate
      onSubmit={handleSubmit(onSubmit, onErrors)}
    >
      <label className="text_medium">Group Name</label>
      <input
        type="text"
        name="name"
        {...register("name", {
          required: true,
        })}
      />
      <p className="error_message">{errors.name?.message}</p>

      <label className="text_medium">Description</label>
      <textarea
        type="text"
        name="description"
        rows={8}
        {...register("description", {})}
      />
      <p className="error_message">{errors.description?.message}</p>

      <Button type="submit" className="primary_button marginT10">
        Save
      </Button>
    </form>
  );
};

export default GroupForm;
