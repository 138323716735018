import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import GridViewIcon from "@mui/icons-material/GridView";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { modalStyle } from "../constants/ConstData";
import BasicModal from "../modal/Modal";
import MobileMore from "./MobileMore";

export default function FixedBottomNavigation() {
  const pathname = window.location.pathname;
  const splitPath = pathname.split("/");

  const [value, setValue] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const ref = React.useRef(null);
  const navigate = useNavigate();

  const handleMore = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  function getKeyByValue(object, value) {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop] === value) return prop;
      }
    }
  }

  const navigationUrl = {
    0: "/dashboard",
    1: "/leads",
    2: "/projects",
    3: "/calendar",
  };

  React.useEffect(() => {
    setValue(Number(getKeyByValue(navigationUrl, `/${splitPath[1]}`)));
  }, [pathname]);

  return (
    <Box sx={{ pb: 7, display: { sm: "none" } }} ref={ref}>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 9 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            if (newValue <= 3) {
              setValue(newValue);
              navigate(navigationUrl[newValue]);
            }
          }}
        >
          <BottomNavigationAction label="Dashboard" icon={<GridViewIcon />} />
          <BottomNavigationAction label="Leads" icon={<LeaderboardIcon />} />
          <BottomNavigationAction label="Projects" icon={<FolderOpenIcon />} />
          <BottomNavigationAction
            label="Calendar"
            icon={<CalendarMonthIcon />}
          />
          <BottomNavigationAction
            onClick={handleMore}
            label=""
            icon={<MoreVertIcon />}
          />
        </BottomNavigation>
      </Paper>
      <BasicModal isOpen={isOpen} onClose={handleClose} sx={modalStyle}>
        <MobileMore onClose={handleClose} />
      </BasicModal>
    </Box>
  );
}
