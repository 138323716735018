import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      d="M.93 7.988c0-.635.155-1.23.465-1.787a3.702 3.702 0 0 1 1.27-1.344 4.508 4.508 0 0 1 .794-2.03 4.438 4.438 0 0 1 1.588-1.406A4.514 4.514 0 0 1 7.168.899c.749 0 1.455.174 2.12.522.644.34 1.173.809 1.589 1.406.43.613.695 1.29.794 2.03a3.674 3.674 0 0 1 1.678 2.479c.09.51.074 1.02-.05 1.53-.126.511-.347.97-.664 1.379a3.703 3.703 0 0 1-1.163.986 3.57 3.57 0 0 1-1.469.431l-5.67.012a3.637 3.637 0 0 1-1.719-.59 3.763 3.763 0 0 1-1.23-1.316 3.648 3.648 0 0 1-.454-1.78Zm8.983 2.54a2.443 2.443 0 0 0 1.457-.595c.42-.36.696-.805.828-1.338a2.49 2.49 0 0 0-.113-1.571 2.452 2.452 0 0 0-1.016-1.203l-.453-.283-.068-.533a3.406 3.406 0 0 0-1.804-2.586 3.352 3.352 0 0 0-1.576-.386c-.56 0-1.085.129-1.577.386a3.407 3.407 0 0 0-1.803 2.586l-.068.533-.454.283a2.452 2.452 0 0 0-1.015 1.203 2.49 2.49 0 0 0-.113 1.57c.132.534.408.98.828 1.339s.905.558 1.457.595l.091.012h5.308l.09-.012Zm-2.178-3.39v2.267H6.601V7.137H4.9l2.268-2.835 2.268 2.835h-1.7Z"
    />
  </svg>
);
export default SvgComponent;
