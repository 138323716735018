import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from "@mui/material";
import CloseIcon from "../../../../assets/images/close";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectStatusList } from "../../../../redux/slices/projectSlice";
import Loading from "../../../loading/Loading";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";

const MultipleStatus = ({ onClose, selected, onChange, onSave }) => {
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const dispatch = useDispatch();

  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );

  const loading = useSelector((state) => state.project.loading);

  const loadProjectStatusList = () => {
    dispatch(
      getProjectStatusList({
        url: `crm/org/${orgId}/proj/status`,
        params: {},
      })
    );
  };

  useEffect(() => {
    loadProjectStatusList();
  }, []);

  if (projectStatusList.length === 0) {
    return <Loading />;
  }

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">Add Multiple Status</p>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal_body">
        {projectStatusList.length > 0 && (
          <FormGroup>
            {projectStatusList.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selected?.find(
                      (st) => st.statusName === item.displayName
                    )}
                    onChange={(event) => onChange(event, item)}
                    name="gilad"
                    disabled={selected?.find(
                      (st) => st.statusName === item.displayName
                    )}
                  />
                }
                label={item?.displayName}
              />
            ))}
            <Button
              onClick={onSave}
              type="button"
              className="primary_button marginT10"
            >
              Save
            </Button>
          </FormGroup>
        )}
      </div>
    </>
  );
};

export default MultipleStatus;
