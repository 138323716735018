import dayjs from "dayjs";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./style.scss";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const CustomDate = ({
  className,
  startDate,
  open,
  onChange,
  dateFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  timeCaption,
  minTime,
  maxTime,
  innerRef,
}) => {
  return (
    <>
      <DatePicker
        refs={innerRef ?? null}
        className={className ?? ""}
        selected={startDate}
        dateFormat={dateFormat ?? "MM/dd/yyyy"}
        onChange={onChange}
        open={open}
        //   closeOnScroll={true}
        timeinputlabel="time:"
        //   shouldCloseOnSelect={shouldCloseOnSelect}
        //   shouldCloseOnSelect={true}
        showtimeinput
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        minTime={minTime}
        maxTime={maxTime}
      />
    </>
  );
};

export default CustomDate;
