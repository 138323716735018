import { takeLatest, put, fork, call } from "redux-saga/effects";
import {
  createNewProject,
  createProjectAhj,
  createProjectStatusGroup,
  createStatusGroupUser,
  editProject,
  fileUploadProject,
  getAhjCodes,
  getProject,
  getProjectActivity,
  getProjectDashboard,
  getProjectList,
  getProjectMainStatusList,
  getProjectProgress,
  getProjectStageList,
  getProjectStatusList,
  getProperty,
  setActivityLoadingFalse,
  setAhjCodes,
  setGroupsAndUsersOfStatusList,
  setMainStatusLoadingFalse,
  setProgressLoadingFalse,
  setProject,
  setProjectActivity,
  setProjectDashboard,
  setProjectList,
  setProjectLoadingFalse,
  setProjectMainStatusList,
  setProjectProgress,
  setProjectProperty,
  setProjectStageList,
  setProjectStatusList,
  setProperty,
  setPropertyLoadingFalse,
  setStageLoadingFalse,
  setStatusLoadingFalse,
  updateProjectStatus,
  updatePropertyDetails,
} from "../slices/projectSlice";
import {
  createItem,
  editItem,
  getItem,
  getPropertyImage,
} from "../../services/service";
import {
  setCreateFailed,
  setCreateSuccess,
  UpdatePropertyDetailsSuccess,
} from "../slices/formSlice";
import { setDialog } from "../slices/dialogSlice";
import { current } from "@reduxjs/toolkit";
import { setLeadProperty } from "../slices/leadSlice";

function* onLoadPropertyAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getPropertyImage, {
      url,
      params,
    });
    // if (response.status === 200) {
    console.log("Res=--------->", response);

    yield put(setProjectProperty({ ...response }));

    yield put(setProjectLoadingFalse());
    // }
  } catch (error) {
    console.log("SagaError=------------>", error);
    yield put(setProjectLoadingFalse());
    yield put(setPropertyLoadingFalse());
  }
}

function* onLoadProperty() {
  yield takeLatest(getProperty.type, onLoadPropertyAsync);
}

function* onCreateProjectAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProjectLoadingFalse());
  }
}

function* onCreateProjectAhjAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProjectLoadingFalse());
  }
}

function* onCreateProject() {
  yield takeLatest(createNewProject.type, onCreateProjectAsync);
}

function* onCreateProjectAhj() {
  yield takeLatest(createProjectAhj.type, onCreateProjectAhjAsync);
}

function* onEditProjectAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProjectLoadingFalse());
  }
}

function* onEditProject() {
  yield takeLatest(editProject.type, onEditProjectAsync);
}

function* onUpdatePropertyDetailsAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      UpdatePropertyDetailsSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProjectLoadingFalse());
  }
}

function* onUpdatePropertyDetails() {
  yield takeLatest(updatePropertyDetails.type, onUpdatePropertyDetailsAsync);
}

function* onFileUploadProjectAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProjectLoadingFalse());
  }
}

function* onFileUploadProject() {
  yield takeLatest(fileUploadProject.type, onFileUploadProjectAsync);
}

function* onUpdateProjecStatusAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(editItem, { url, data, params });
    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setStatusLoadingFalse());
  }
}

function* onUpdateProjectStatus() {
  yield takeLatest(updateProjectStatus.type, onUpdateProjecStatusAsync);
}

function* onCreateProjectStatusGroupAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(setStatusLoadingFalse());

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setStatusLoadingFalse());
  }
}

function* onCreateProjectStatusGroup() {
  yield takeLatest(
    createProjectStatusGroup.type,
    onCreateProjectStatusGroupAsync
  );
}

function* onCreateStatusGroupUserAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(setStatusLoadingFalse());

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setStatusLoadingFalse());
  }
}

function* onCreateStatusGroupUser() {
  yield takeLatest(createStatusGroupUser.type, onCreateStatusGroupUserAsync);
}

function* onLoadProjectMainStatusAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url });

    yield put(setProjectMainStatusList([...response.data]));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setMainStatusLoadingFalse());
  }
}

function* onLoadProjectMainStatus() {
  yield takeLatest(getProjectMainStatusList.type, onLoadProjectMainStatusAsync);
}

function* onLoadProjectStatusAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url });
    let groupsAndUsersOfStatus=[];
    console.log('status response',response.data);
    response.data.map((status)=>{
      console.log('status response*** ',status);
      groupsAndUsersOfStatus.push({
        status: {
          id: status.id,
          name: status?.name,
        },
        groups: status.groups,
        users: status.users,
      });
    });
    yield put(setGroupsAndUsersOfStatusList(groupsAndUsersOfStatus))
    yield put(setProjectStatusList([...response.data]));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setStatusLoadingFalse());
  }
}

function* onLoadProjectStatus() {
  yield takeLatest(getProjectStatusList.type, onLoadProjectStatusAsync);
}

function* onLoadProjectStageAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url });

    yield put(setProjectStageList([...response.data]));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setStageLoadingFalse());
  }
}

function* onLoadProjectStage() {
  yield takeLatest(getProjectStageList.type, onLoadProjectStageAsync);
}

function* onLoadProjectListAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setProjectList([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProjectLoadingFalse());
  }
}

function* onLoadProjectList() {
  yield takeLatest(getProjectList.type, onLoadProjectListAsync);
}

function* onLoadProjectAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setProject({ ...response.data }));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProjectLoadingFalse());
  }
}

function* onLoadProject() {
  yield takeLatest(getProject.type, onLoadProjectAsync);
}

function* onLoadProjectDashboardAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    console.log("project dashboard response", response.data);
    yield put(setProjectDashboard({ ...response.data }));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProjectLoadingFalse());
  }
}

function* onLoadProjectDashboard() {
  yield takeLatest(getProjectDashboard.type, onLoadProjectDashboardAsync);
}

function* onLoadProjectProgressAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(setProjectProgress([...response.data]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setProgressLoadingFalse());
  }
}

function* onLoadProjectProgress() {
  yield takeLatest(getProjectProgress.type, onLoadProjectProgressAsync);
}

function* onLoadProjectActivityAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    // if (response.status === 200) {
    yield put(
      setProjectActivity({ ...response.data, currentPage: params.page })
    );
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setActivityLoadingFalse());
  }
}

function* onLoadAhjCodesAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    //console.log('ahj code response',response,url,params);
    // if (response.status === 200) {
    yield put(setAhjCodes([...response.data.coldtl]));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setActivityLoadingFalse());
  }
}

function* onLoadProjectActivity() {
  yield takeLatest(getProjectActivity.type, onLoadProjectActivityAsync);
}
function* onLoadAhjCodes() {
  yield takeLatest(getAhjCodes.type, onLoadAhjCodesAsync);
}

export const projectSagas = [
  fork(onLoadProperty),
  fork(onCreateProject),
  fork(onEditProject),
  fork(onUpdatePropertyDetails),
  fork(onFileUploadProject),
  fork(onLoadProjectMainStatus),
  fork(onLoadProjectStatus),
  fork(onLoadProjectStage),
  fork(onLoadProjectList),
  fork(onLoadProject),
  fork(onLoadProjectDashboard),
  fork(onUpdateProjectStatus),
  fork(onLoadProjectProgress),
  fork(onLoadProjectActivity),
  fork(onCreateProjectStatusGroup),
  fork(onCreateStatusGroupUser),
  fork(onLoadAhjCodes),
  fork(onCreateProjectAhj),
];
