// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workflow {
  padding: 30px;
}
.workflow .flow_card_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.workflow .flow_card_container .flow_card {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--input-field-border);
  background-color: var(--white);
}
.workflow .flow_card_container .flow_card .flow_status {
  font-size: 12px;
  text-align: center;
}
.workflow .flow_card_container .line {
  border-bottom: 1px solid var(--grey);
  position: absolute;
  top: 40px;
  width: 100%;
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/pages/organisation/workflow/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AACJ;AAAI;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,2CAAA;EACA,8BAAA;AAEN;AADM;EACE,eAAA;EACA,kBAAA;AAGR;AAAI;EACE,oCAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,WAAA;AAEN","sourcesContent":[".workflow {\n  padding: 30px;\n\n  .flow_card_container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    position: relative;\n    .flow_card {\n      width: 80px;\n      height: 80px;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      justify-content: center;\n      border: 1px solid var(--input-field-border);\n      background-color: var(--white);\n      .flow_status {\n        font-size: 12px;\n        text-align: center;\n      }\n    }\n    .line {\n      border-bottom: 1px solid var(--grey);\n      position: absolute;\n      top: 40px;\n      width: 100%;\n      z-index: -1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
