import { Box, Grid } from "@mui/material";
import AutoCompleteAsync from "../../../fields/autoComplete/AutoCompleteAsync";
import { useEffect, useState } from "react";
import {
  editLead,
  getLeadOffice,
  getLeadSource,
} from "../../../redux/slices/leadSlice";
import { editProject } from "../../../redux/slices/projectSlice";

import { useSelector } from "react-redux";
import Storage from "../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

const OfficeSourceCard = ({ type, data }) => {
  const [openOffice, setOpenOffice] = useState(false);
  const [openSource, setOpenSource] = useState(false);
  const leadLoading = useSelector((state) => state.lead.loading);

  const officeList = useSelector((state) => state.lead.leadOffice);
  const sourceList = useSelector((state) => state.lead.leadSource);
  const [officeSelected, setOfficeSelected] = useState(data?.office ?? null);
  const [sourceSelected, setSourceSelected] = useState(data?.source ?? null);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (openSource) {
      dispatch(
        getLeadSource({
          url: `crm/org/${orgId}/leads/source`,
          params: {},
        })
      );
    }
  }, [openSource]);

  useEffect(() => {
    if (openOffice) {
      dispatch(
        getLeadOffice({
          url: `crm/org/${orgId}/leads/offices`,
          params: {},
        })
      );
    }
  }, [openOffice]);

  return (
    <div
      style={{ paddingLeft: "10px", paddingRight: "10px" }}
      className="d_flex h_100  justify_content_between align_items_center"
    >
      <AutoCompleteAsync
        sx={{
          width: "175px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
        name="office"
        label="Office"
        open={openOffice}
        onOpen={() => {
          setOpenOffice(true);
        }}
        onClose={() => {
          setOpenOffice(false);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        loading={leadLoading}
        options={officeList}
        onChange={(event, newValue, reason) => {
          if (reason === "clear") {
            setOfficeSelected(null);
          } else {
            setOfficeSelected(newValue);
            const editedData = {
              office: newValue,
            };

            if (type === "lead") {
              dispatch(
                editLead({
                  url: `crm/org/${orgId}/leads/${id}`,
                  data: editedData,
                  params: {},
                })
              );
            } else {
              dispatch(
                editProject({
                  url: `crm/org/${orgId}/proj/${id}`,
                  data: editedData,
                  params: {},
                })
              );
            }
          }
        }}
        // register={register}
        renderOption={(props, option) => (
          <Box style={{ fontSize: 13 }} {...props}>
            {option.name}
          </Box>
        )}
        value={officeSelected}
        getOptionLabel={(option) => {
          if (option.name) {
            return option?.name;
          } else {
            return "Please Select";
          }
        }}
        // variant="standard"
        fieldSize="small"
      />
      <AutoCompleteAsync
        sx={{
          width: "175px",
          marginBottom: "10px",
          marginTop: "10px",
        }}
        name="source"
        label="Source"
        open={openSource}
        onOpen={() => {
          setOpenSource(true);
        }}
        onClose={() => {
          setOpenSource(false);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        loading={leadLoading}
        options={sourceList}
        onChange={(event, newValue, reason) => {
          if (reason === "clear") {
            setSourceSelected(null);
          } else {
            setSourceSelected(newValue);
            const editedData = {
              source: newValue,
            };
            if (type === "lead") {
              dispatch(
                editLead({
                  url: `crm/org/${orgId}/leads/${id}`,
                  data: editedData,
                  params: {},
                })
              );
            } else {
              dispatch(
                editProject({
                  url: `crm/org/${orgId}/proj/${id}`,
                  data: editedData,
                  params: {},
                })
              );
            }
          }
        }}
        // register={register}
        renderOption={(props, option) => (
          <Box style={{ fontSize: 13 }} {...props}>
            {option.name}
          </Box>
        )}
        value={sourceSelected}
        getOptionLabel={(option) => {
          if (option.name) {
            return option?.name;
          } else {
            return "Please Select";
          }
        }}
        // variant="standard"
        fieldSize="small"
      />
    </div>
  );
};

export default OfficeSourceCard;
