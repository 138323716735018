import { MenuItem, Select } from "@mui/material";

const SelectMenu = ({ options, border, onChange, selected, className }) => {
  return (
    <Select
      className={className}
      value={selected}
      size="small"
      sx={
        !border && {
          ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
            top: "calc(42% - 0.5em)",
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
        }
      }
      onChange={onChange}
    >
      {options.map((item, index) => (
        <MenuItem
          sx={{
            color: "#8C9097",
            fontFamily: "Inter",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "18px",
          }}
          key={index}
          value={item.value}
        >
          {item.title}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectMenu;
