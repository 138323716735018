import React, { useEffect } from "react";

import "./style.scss";

const CustomChat = ({ innerRef }) => {
  return (
    <div className="users">
      <div className="users-container">
        <div
          style={{ display: "none" }}
          className="chatbox-container"
          ref={innerRef}
        >
          <div id="talkjs-container" style={{ height: "400px" }}>
            <i></i>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomChat;
