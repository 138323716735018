import { createSlice } from "@reduxjs/toolkit";

const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    // commentList: [],

    loading: false,
  },
  reducers: {
    createNewFeedback(state) {
      state.loading = true;
      return state;
    },

    setFeedbackLoadingFalse: (state, action) => {
      state.loading = false;
    },
  },
});

export const { createNewFeedback, setFeedbackLoadingFalse } =
  feedbackSlice.actions;

export default feedbackSlice.reducer;
