import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { Badge } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import MailIcon from "../../assets/images/message.svg";
import BellIcon from "../../assets/images/notification.svg";
import CountryIcon from "../../assets/images/us_flag.svg";
import ChevronDown from "../../assets/images/chevrondown.svg";
import "./style.scss";
import SearchBar from "../searchbar/SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { getUserLogout } from "../../redux/slices/authSlice";
import { stringAvatar } from "../../utils/util";
import Storage from "../../utils/Storage";
import { switchOrg } from "../../redux/slices/orgSlice";
import KnockNotification from "../notification/KnockNotification";
import NotificationFeed from "../notification/KnockNotification";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { modalStyle } from "../constants/ConstData";
import BasicModal from "../modal/Modal";
import Feedback from "../feedback/Feedback";

const settings = [
  "Profile",
  "My Organisation",
  // "Dashboard",
  "Logout",
];

function ResponsiveAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [notify, setNotify] = React.useState(null);
  const createData = useSelector((state) => state.form.createData);

  const pageTitle = useSelector((state) => state.title.title);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const orgLoading = useSelector((state) => state.org.loading);
  const [showOrgDropdown, setShowOrgDropdown] = React.useState(null);
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const loading = useSelector((state) => state.auth.loading);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const org = Storage.read(`org${userInfo?.id}`);
  // const [currentOrg, setCurrentOrg] = React.useState(org);
  const [isOpen, setIsOpen] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const Acronym = (str) => {
  //   var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  //   var acronym = matches.join(""); // JSON
  //   return acronym;
  // };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const openNotification = (event) => {
    setNotify(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setNotify(null);
  };

  const handleProfile = (value) => {
    switch (value) {
      case "Logout":
        dispatch(getUserLogout());
        navigate("/");
        break;
      case "Dashboard":
        navigate("/dashboard");
        break;
      case "My Organisation":
        navigate("/org");
        break;
      case "Profile":
        navigate("/profile");
        break;
      default:
        navigate("/projects");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (createData?.createSuccess) {
      setIsOpen(false);
    }
  }, [createData]);

  return (
    <>
      <BasicModal
        isOpen={isOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "550px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <Feedback onClose={handleClose} />
      </BasicModal>
      <AppBar style={{ zIndex: 990 }} className="header" position="fixed">
        <Container maxWidth="100%">
          <Toolbar disableGutters>
            <div className="logo">
              <Link className="logo" to="/">
                SunScape
              </Link>
            </div>
            <Box
              sx={{
                flexGrow: 2,
                display: { xs: "none", md: "none", lg: "flex" },
                alignItems: "center",
              }}
            >
              <Typography
                className="header_title"
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                {pageTitle}
              </Typography>
              <SearchBar />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Box className="header_org_dropdown" marginRight={"16px"}>
                {!orgLoading ? (
                  orgInfo?.length > 0 ? (
                    <>
                      <Typography
                        className="header_org_name"
                        sx={{
                          margin: 0,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        paragraph={true}
                        onClick={(event) =>
                          setShowOrgDropdown(event.currentTarget)
                        }
                      >
                        {org?.name} <img src={ChevronDown} alt="" />
                      </Typography>

                      <Menu
                        sx={{ mt: "45px", width: { sm: "200px", md: "275px" } }}
                        id="menu-appbar"
                        anchorEl={showOrgDropdown}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(showOrgDropdown)}
                        onClose={() => setShowOrgDropdown(null)}
                      >
                        {orgInfo.map((org, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              // setCurrentOrg(org);
                              dispatch(switchOrg(org));
                              setShowOrgDropdown(null);
                              navigate("/projects");
                            }}
                          >
                            <Typography title={org?.name} textAlign="center">
                              {org?.name?.length > 27
                                ? org?.name.substring(0, 27) + "..."
                                : org?.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  ) : (
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "#000",
                      }}
                      to="/org/create"
                    >
                      <div className="d_flex align_items_center">
                        <AddCircleIcon
                          sx={{ color: "#189d3d", marginRight: "5px" }}
                        />
                        New Organisation
                      </div>
                    </Link>
                  )
                ) : (
                  <>Loading...</>
                )}
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* <Avatar
                  className="country_button"
                  sx={{ bgcolor: "#F5F5F9", marginRight: "16px" }}
                >
                  <Badge color="secondary">
                    <img src={CountryIcon} alt="" />
                  </Badge>
                </Avatar> */}
                <Avatar
                  className="country_button"
                  sx={{
                    bgcolor: "#F5F5F9",
                    marginRight: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  <Badge color="secondary">
                    <FeedbackIcon sx={{ fill: "#7375d0" }} />
                  </Badge>
                </Avatar>
                {/* <Avatar sx={{ bgcolor: "#F5F5F9", marginRight: "16px" }}>
                <Badge color="secondary">
                  <img src={MailIcon} alt="" />
                </Badge>
              </Avatar> */}
                <Avatar
                  className="notification_button"
                  sx={{
                    bgcolor: "#F5F5F9",
                    marginRight: "16px",
                    cursor: "pointer",
                  }}
                  // onClick={openNotification}
                >
                  {/* <KnockNotification /> */}
                  <NotificationFeed />
                  {/* <Badge
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "#ffffff",
                      backgroundColor: "#EF4D56",
                    },
                  }}
                  variant="dot"
                >
                  <img src={BellIcon} alt="" />
                </Badge> */}
                </Avatar>
                {/* <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={notify}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(notify)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleProfile(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleOpenUserMenu}
              >
                <IconButton sx={{ p: 0, marginLeft: "8px" }}>
                  <Avatar {...stringAvatar(userDetails.displayName)} />
                </IconButton>
                <Box marginLeft={"8px"}>
                  <Typography
                    className="header_user_name"
                    sx={{ margin: 0, display: "flex", alignItems: "center" }}
                    paragraph={true}
                  >
                    {userDetails.displayName} <img src={ChevronDown} alt="" />
                  </Typography>
                </Box>
              </Box>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleProfile(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
export default ResponsiveAppBar;
