import { createSlice } from "@reduxjs/toolkit";

const profileSlice=createSlice({
    name:"profile",
    initialState:{
        profileData:{},
        loading:false,
        profileProjectData:[],
        profileProjectDataLoading:false   
    },
    reducers:{
        getProfileData(state){
            state.loading=true;
            return state;
        },
        setProfileData(state,action){
            state.profileData=action.payload;
            state.loading=false;
        },
        getProfileProjectData(state){
            state.profileProjectDataLoading=true;
            return state;
        },
        setProfileProjectData(state,action){
            state.profileProjectData=action.payload;
            state.profileProjectDataLoading=false;
        }
    }
});

export const {
    getProfileData,
    setProfileData,
    getProfileProjectData,
    setProfileProjectData
}=profileSlice.actions;

export default profileSlice.reducer;