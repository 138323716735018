import Logo from "../../assets/images/logo/Sunscapebwnotext.svg";
import LoginBg from "../../assets/images/loginbg.png";

const LeftBanner = () => {
  return (
    <div className="login_left">
      <img className="login_bg_image" src={LoginBg} alt="Background" />
      <div className="login_left_text_wrapper">
        <div className="text_content_wrapper">
          <img
            style={{ height: "75px", width: "100px" }}
            src={Logo}
            alt="Sunscape"
          />
          {/* </div> */}
          <h1 className="empower_title">
            Powering Your Success in{" "}
            <span className="dark_text">Renewable Energy!</span>
          </h1>
          <p className="empower_content">
            Join Sunscape and manage your solar projects with ease. Our CRM
            platform is tailored to the unique needs of the renewable energy
            sector, helping you stay organized and informed.
          </p>
          <h2 className="login_now_text">EXPLORE NOW!</h2>
        </div>
      </div>
    </div>
  );
};

export default LeftBanner;
