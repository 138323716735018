import { IconButton } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import profileImage from "../../assets/images/project.jpeg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  linkWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../firebase";
import { getUser } from "../../redux/slices/authSlice";
import { setCreateFailed } from "../../redux/slices/formSlice";

const UserProfile = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const [showNumberField, setShowNumberField] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmationData, setConfirmationData] = useState(null);
  const [otp, setOtp] = useState("");
  const userDetails = useSelector((state) => state.auth.userDetails);

  const confirmationResult = useSelector(
    (state) => state.auth.confirmationResult
  );
  const dispatch = useDispatch();

  const addMobileNumber = () => {
    setShowNumberField(true);
  };

  const sendOtp = async () => {
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {});
      const confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptcha
      );
      setConfirmationData(confirmation);
    } catch (err) {
      console.log(err);
    }
  };

  const verifyOtp = async () => {
    try {
      const userData = PhoneAuthProvider.credential(
        confirmationData.verificationId,
        otp
      );
      console.log("userData=---->", userData);
      console.log("userDetails=---------->", userDetails);

      if (userData) {
        // const finalUser = await userDetails?.linkWithCredential(userData);
        // console.log("finalUser=---------->", finalUser);
        linkPhoneEmail(userData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const linkPhoneEmail = async (userData) => {
    await linkWithCredential(userDetails, userData)
      .then((usercred) => {
        const user = usercred.user;
        dispatch(
          getUser({
            url: `crm/user`,
            params: {},
          })
        );
        setConfirmationData(null);
        console.log("Account linking success", user);
      })
      .catch((error) => {
        console.log("Account linking error", error.message);
        dispatch(
          setCreateFailed({
            message: error.message,
            createSuccess: false,
            statusCode: 404,
          })
        );
      });
  };

  return (
    <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center justify_content_between marginB10">
          <IconButton
            sx={{
              borderRadius: 0,
              border: "1px solid",
              borderColor: "primary.main",
              borderRadius: "8px",
            }}
            onClick={() => navigate("/dashboard")}
          >
            <HomeIcon />
            <p className="font_12_dark_gray">Home </p>
          </IconButton>
        </div>
        <div className="d_flex flex_column align_items_center justify_content_center">
          <img
            style={{ borderRadius: "50%", width: "80px", height: "80px" }}
            src={profileImage}
            alt="Profile"
          />
          <h2>{userInfo?.name}</h2>
          <div>
            <div className="d_flex">
              <p>
                <b>Email:</b>
              </p>
              <p className="marginL10">{userInfo?.email}</p>
            </div>
            <div className="d_flex">
              <p>
                <b>Mobile:</b>
              </p>
              {userInfo?.phone || userDetails?.phoneNumber ? (
                <p className="marginL10">
                  {userInfo?.phone || userDetails?.phoneNumber}
                </p>
              ) : showNumberField ? (
                <div className="marginL10">
                  <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    onChange={(phone) => setPhoneNumber("+" + phone)}
                  />
                  <button
                    className="primary_button_small marginT10"
                    onClick={sendOtp}
                  >
                    Verify
                  </button>

                  <div className="marginT10" id="recaptcha"></div>

                  {confirmationData && (
                    <div>
                      <input
                        type="text"
                        placeholder="Verification Code"
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <button
                        className="primary_button_small marginT10"
                        onClick={verifyOtp}
                      >
                        Verify OTP
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <p onClick={addMobileNumber} className="marginL10 link_blue">
                  Add Mobile Number
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
