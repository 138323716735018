import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../buttons/Button";
import { useSelector } from "react-redux";
import Storage from "../../../utils/Storage";
import { createProjectAhj } from "../../../redux/slices/projectSlice";
import { useDispatch } from "react-redux";

const AhjGenInfo = ({ genIfoEdit }) => {
  const projectData = useSelector((state) => state.project.projectData);
  const ahjDetails = useSelector((state) => state.additionalInfo.ahjDetails);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const dispatch = useDispatch();

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    //resolver: yupResolver(schema),
    defaultValues: {
      // codeDetails: [
      //   {
      //     itemid: null,
      //     code: "",
      //     data: "",
      //   },
      // ],
    },
  });

  useEffect(() => {
    if (ahjDetails?.genReq) {
      setValue("genReq", ahjDetails?.genReq);
    }
  }, [ahjDetails]);

  const onSubmit = (data) => {
    if (ahjDetails?.id) {
      dispatch(
        createProjectAhj({
          url: `/crm/org/${orgId}/proj/${projectData?.id}/AHJ`,
          params: {
            orgId,
            id: projectData?.id,
          },
          data: {
            ahjdtl: [
              { ...ahjDetails, id: ahjDetails?.id, genReq: data?.genReq },
            ],
          },
        })
      );
    } else {
      dispatch(
        createProjectAhj({
          url: `/crm/org/${orgId}/proj/${projectData?.id}/AHJ`,
          params: {
            orgId,
            id: projectData?.id,
          },
          data: {
            ahjdtl: [{ ...ahjDetails, genReq: data?.genReq }],
          },
        })
      );
    }
  };

  return (
    <>
      {genIfoEdit ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <div
            className="organisation"
            style={{ display: "block", padding: "0px" }}
          >
            <textarea
              type="text"
              name="genReq"
              rows={12}
              // onChange={(e) => setDescription(e.target.value)}
              {...register("genReq", {})}
            />
            <div>
              <Button
                className="primary_button marginT10"
                // onClick={() => setOpenViewModal(false)}
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <p>{ahjDetails?.genReq}</p>
      )}
    </>
  );
};

export default AhjGenInfo;
