import { Link } from "react-router-dom";

const Checkbox = ({
  checked,
  type,
  name,
  label,
  subLabel,
  onChange,
  style,
}) => {
  return (
    <label style={style ?? {}} className="custom_checkbox">
      <input
        type={type}
        name={name}
        checked={checked ?? false}
        onChange={onChange}
      />
      {label}{" "}
      {subLabel && (
        <Link to={"/"} className="terms_text">
          {subLabel}
        </Link>
      )}
      <span className="greenmark"></span>
    </label>
  );
};

export default Checkbox;
