import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userDetails: null,
    userInfo: null,
    loading: false,
    error: null,
  },
  reducers: {
    getUserRegister(state) {
      state.loading = true;
      return state;
    },
    getUserLogin(state) {
      state.loading = true;
      return state;
    },

    getUserLogout(state) {
      state.loading = true;
      return state;
    },
    getSignInWithGoogle(state) {
      state.loading = true;

      return state;
    },
    getSignInWithFacebook(state) {
      state.loading = true;

      return state;
    },
    getUser(state) {
      state.loading = true;
      return state;
    },
    getUserResetPassword(state) {
      state.loading = true;
      return state;
    },
    setUser: (state, action) => {
      state.userInfo = action.payload;
      state.loading = false;
    },
    setUserFailed: (state, action) => {
      state.loading = false;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.loading = false;
      state.error = null;
    },

    authSuccess: (state, action) => {
      state.userDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    logoutSuccess: (state, action) => {
      state.userDetails = null;
      state.loading = false;
      state.error = null;
    },
    passwordResetSuccess: (state, action) => {
      // state.userDetails = action.payload;
      state.loading = false;
      state.error = null;
    },
    authFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  getUserRegister,
  getUserLogin,
  getUserLogout,
  getSignInWithGoogle,
  getSignInWithFacebook,
  getUser,
  setUser,
  setUserFailed,
  authSuccess,
  logoutSuccess,
  authFailure,
  setUserDetails,
  getUserResetPassword,
} = authSlice.actions;

export default authSlice.reducer;
