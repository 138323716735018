import { IconButton } from "@mui/material";

const CustomIconButton = ({ icon, sx }) => {
  return (
    <IconButton sx={sx}>
      <img src={icon} alt="Download" />
    </IconButton>
  );
};

export default CustomIconButton;
