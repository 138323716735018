import { call, fork, put, takeLatest } from "redux-saga/effects";
import { createItem, getItem } from "../../services/service";
import { setDialog } from "../slices/dialogSlice";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";
import {
  createAdditionalInfo,
  getAdditionalInfo,
  setAdditionalInfo,
  setAdditionalInfoLoadingFalse,
} from "../slices/additionalInfoSlice";

function* onLoadAdditonalInfoAsync(payload) {
  try {
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      console.log("Res=------->", response);

      yield put(setAdditionalInfo({ ...response.data }));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}

function* onLoadAdditonalInfo() {
  yield takeLatest(getAdditionalInfo.type, onLoadAdditonalInfoAsync);
}

function* onCreateAdditionalInfoAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: response?.status === 200 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setAdditionalInfoLoadingFalse());
  }
}

function* onCreateAdditionalInfo() {
  yield takeLatest(createAdditionalInfo.type, onCreateAdditionalInfoAsync);
}

export const additionalInfoSagas = [
  fork(onLoadAdditonalInfo),
  fork(onCreateAdditionalInfo),
];
