import React from "react";
import currencySymbolMap from "currency-symbol-map";
import Storage from "../../utils/Storage";

const CurrencyView = ({ amount }) => {
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const currencyCode =
    Storage.read(`org${userInfo?.id}`)?.currencyCode ?? "USD";

  const currencySymbol = currencySymbolMap(currencyCode);

  return (
    <div>
      {currencySymbol}
      {amount}
    </div>
  );
};

export default CurrencyView;
