import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CardTitle from "../../../dashboard/CardTitle";
import LabelValue from "../LabelValue";
import { useState } from "react";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";
import FinancialDetailsForm from "./FinancialDetailsForm";
import { useSelector } from "react-redux";
import Loading from "../../../loading/Loading";

const FinancialDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const financeDetails = useSelector(
    (state) => state.additionalInfo.financeDetails
  );
  const loading = useSelector((state) => state.additionalInfo.loading);

  const editFinaceDetails = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      <CardTitle title="Finance" type="project_view">
        <IconButton
          className="marginL10"
          size="small"
          onClick={editFinaceDetails}
        >
          <Edit />
        </IconButton>
      </CardTitle>
      <div className="card_content">
        <div>
          <LabelValue
            className="m_0"
            label="Finance Type"
            value={financeDetails?.financeType}
            buttonLabel="Add Finance Type"
            onClick={editFinaceDetails}
          />
          <LabelValue
            label="Loan Amount"
            value={financeDetails?.loanAmount}
            buttonLabel="Add Loan Amount"
            onClick={editFinaceDetails}
            type="currency"
          />
          <LabelValue
            label="Base PPW"
            value={financeDetails?.baseppw}
            buttonLabel="Add Base PPW"
            onClick={editFinaceDetails}
          />
          {financeDetails?.adderppw && (
            <LabelValue
              label="Adder PPW"
              value={financeDetails?.adderppw}
              buttonLabel="Add Adder PPW"
              onClick={editFinaceDetails}
            />
          )}

          <LabelValue
            label="Dealer Fee"
            value={financeDetails?.dealerFee}
            buttonLabel="Add Dealer Fee"
            onClick={editFinaceDetails}
            type="currency"
          />
          <LabelValue
            label="Total PPW"
            value={financeDetails?.totalppw}
            buttonLabel="Add Total PPW"
            onClick={editFinaceDetails}
          />
        </div>
        <BasicModal
          isOpen={isOpen}
          onClose={handleClose}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            maxHeight: "500px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <FinancialDetailsForm
            // id={id}
            // projectData={projectData}
            financeDetails={financeDetails}
            onClose={handleClose}
          />
        </BasicModal>
      </div>
    </div>
  );
};

export default FinancialDetails;
