import * as React from "react";
import EventView from "../../../../pages/calendar/EventView";
import Button from "../../../buttons/Button";
import DateView from "../../../date/DateView";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAppointmentList } from "../../../../redux/slices/appointmentSlice";
import { formatEvents } from "../../../constants/Constants";
import Storage from "../../../../utils/Storage";
import { setCreateSuccess } from "../../../../redux/slices/formSlice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { orange, yellow } from "@mui/material/colors";
import dayjs from "dayjs";

const AppointmentListItem = ({ code, type, loadAppointmentList }) => {
  const [eventItem, setEventItem] = React.useState({});
  const [isViewOpen, setIsViewOpen] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const [expanded, setExpanded] = React.useState(false);

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const { id } = useParams();
  const dispatch = useDispatch();
  const createData = useSelector((state) => state.form.createData);

  const appointmentList = useSelector(
    (state) => state.appointment.appointmentList
  );

  const loading = useSelector((state) => state.appointment.loading);
  const projectLoading = useSelector((state) => state.project.loading);

  const handleSelectEvent = (event, appointmentData, item) => {
    setIsViewOpen(true);
    setEventItem({
      appointmentData: appointmentData,
      status: {
        statusId: item.id,
        displayName: item.displayname,
        statusName: item.name,
      },
    });
  };

  const handleClose = () => {
    setIsViewOpen(false);
  };

  React.useEffect(() => {
    console.log("Code=-------->", code);

    if (orgId && !projectLoading) {
      loadAppointmentList();
    }
  }, [code]);

  // React.useEffect(() => {
  //   if (appointmentList) {
  //     setEvents(formatEvents(appointmentList));
  //   }
  // }, [appointmentList]);
  const handleAccordion = (item, exp) => (event, isExpanded) => {
    setEvents(formatEvents(item.app_dtls));
    setExpanded(isExpanded ? exp : false);
  };

  const UpdateDueDate = (endDate) => {
    let hours = dayjs(new Date()).diff(endDate, "hour");
    const days = dayjs(new Date()).diff(endDate, "day");

    if (hours > 24) {
      return `${days} ${days > 1 ? "days" : "day"}`;
    } else {
      return `${hours} ${hours > 1 ? "hours" : "hour"}`;
    }
  };

  // React.useEffect(() => {
  //   if (createData?.createSuccess) {
  //     // dispatch(setCreateSuccess(null));
  //   }
  // }, [createData]);

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      {appointmentList && appointmentList.length > 0 ? (
        appointmentList?.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `${item?.name}${index}`}
            onChange={handleAccordion(item, `${item?.name}${index}`)}
            sx={{
              width: "100%",
              borderRadius: "4px",
              background: "rgba(55, 144, 107, 0.08)",
              border: "none",
              boxShadow: "none",
              marginTop: "10px",
              ":before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#189d3d",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  color: "#189d3d",
                }}
                className="font_12_green_600"
              >
                {item.displayname}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {events?.map((appointmentData, id) => (
                <div
                  key={appointmentData.appointmentId}
                  className="light_bg_border"
                >
                  <div className="d_flex align_items_center justify_content_between">
                    <h3 className="m_0">{appointmentData?.title}</h3>
                    {appointmentData?.isTask === 1 ? (
                      <ChecklistIcon sx={{ color: orange[500] }} />
                    ) : (
                      <EventAvailableIcon color="primary" />
                    )}
                  </div>

                  <p className="mt_0 mb_0">
                    <DateView
                      date={appointmentData.start}
                      format={
                        appointmentData.allDay === "true"
                          ? "MMMM D, YYYY"
                          : "MMMM D, YYYY h:mm A"
                      }
                    />
                    <b className="marginR10 marginL10">-</b>

                    <DateView
                      date={appointmentData.end}
                      format={
                        appointmentData.allDay === "true"
                          ? "MMMM D, YYYY"
                          : "h:mm A"
                      }
                    />
                  </p>
                  {code === 0 &&
                    appointmentData?.isTask === 1 &&
                    !dayjs().isBefore(dayjs(appointmentData?.end)) && (
                      <Typography className="m_0" color="red">
                        {UpdateDueDate(appointmentData?.end)} due
                      </Typography>
                    )}
                  <Button
                    onClick={(e) => handleSelectEvent(e, appointmentData, item)}
                    className="icon_button d_flex align_items_center primary_bg text_white"
                  >
                    <OpenInNewIcon className="btn_icon" /> View Details
                  </Button>
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div>
          <p>No Appointment</p>
        </div>
      )}
      {/*  */}

      <BasicModal
        isOpen={isViewOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "30%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "420px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <EventView
          code={code}
          eventItem={eventItem}
          onClose={handleClose}
          type={type}
        />
      </BasicModal>
    </>
  );
};

export default AppointmentListItem;
