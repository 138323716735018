import * as React from "react";
import CardTitle from "../../dashboard/CardTitle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CustomerContact from "../../customer/CustomerContact";
import Button from "../../buttons/Button";
import { Badge } from "@mui/material";

export default function CustomerDetails({
  pContact,
  sContact,
  onChat,
  unreadCount,
  onSendPortal,
}) {
  const [count, setCount] = React.useState(unreadCount ?? 0);

  React.useEffect(() => {
    setCount(unreadCount);
  }, [unreadCount]);

  return (
    <div className="card_container">
      <CardTitle title="Customer Details" type="project_view">
        {/* <Button className="icon_button marginL10 d_flex align_items_center primary_bg text_white">
          <EditIcon className="btn_icon" /> Edit
        </Button> */}
      </CardTitle>
      <div className="card_content">
        {pContact && (
          <CustomerContact
            title="Primary Contact"
            name={pContact?.firstName + " " + pContact?.lastName}
            email={pContact?.email}
            phone={pContact?.mobile}
          />
        )}

        {sContact?.firstName && (
          <CustomerContact
            title="Secondary Contact"
            name={sContact?.firstName + " " + sContact?.lastName}
            email={sContact?.email}
            phone={sContact?.mobile}
          />
        )}

        <div className="d_flex align_items_center">
          <Badge
            badgeContent={count}
            color="secondary"
            invisible={count > 0 ? false : true}
          >
            <Button
              onClick={onChat}
              className="icon_button marginL10 d_flex align_items_center bg_black text_white"
            >
              <ChatBubbleOutlineIcon className="btn_icon" />
              Chat
              {/* {unreadCount > 0 && <small className="mb_0">{unreadCount}</small>} */}
            </Button>
          </Badge>

          <Button
            onClick={onSendPortal}
            className="icon_button marginL10 d_flex align_items_center primary_bg text_white"
          >
            <SendIcon className="btn_icon" /> Send Portal
          </Button>
        </div>
      </div>
    </div>
  );
}
