import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../assets/images/close";
import Button from "../../../components/buttons/Button";
import { modalStyle } from "../../../components/constants/ConstData";
import BasicModal from "../../../components/modal/Modal";
import {
  createProjectStatusOrg,
  createProjectStatusStageOrg,
  getProjectStatusListOrg,
  updateProjectStatusOrder,
} from "../../../redux/slices/orgSlice";
import StatusCard from "./StatusCard";
import Loading from "../../../components/loading/Loading";
import StatusCard2 from "./StatusCard2";
import { AxiosInstance } from "../../../api";
import { setCreateSnackbar } from "../../../redux/slices/formSlice";

const ProjectStatusCard = ({
  orgId,
  showProjectStatusStage,
  projectStatusList,
}) => {
  const dispatch = useDispatch();
  const [openEditStatusModal, setOpenEditStatusModal] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);
  // const projectStatusList = useSelector(
  //   (state) => state.org.projectStatusListOrg
  // );
  const [statusList, setStatusList] = useState(projectStatusList);

  const projectStatusLoading = useSelector(
    (state) => state.org.projectStatusLoading
  );
  const createData = useSelector((state) => state.form.createData);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const handleEdit = (stage) => {
    // let statusItemToEdit = projectStatusList[index];
    setItemToEdit(stage);
    console.log("status item to edit", stage);
    setValue("name", stage.name);
    setValue("displayName", stage.displayName ? stage.displayName : stage.name);
    setOpenEditStatusModal(true);
  };

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = statusList[dragIndex];
    console.log("drag item", dragItem);
    if (dragItem) {
      setStatusList((prevState) => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
        reArrangeOrder(coppiedStateArray);
        return coppiedStateArray;
      });
    }
  };

  const editStatus = (data) => {
    console.log("data", data);
    let status = [
      {
        ...itemToEdit,
        isactive: true,
        name: data.name,
        displayName: data.displayName ? data.displayName : data.name,
      },
    ];
    console.log("edited data", status);

    dispatch(
      createProjectStatusOrg({
        url: `/crm/user/organization/${orgId}/status`,
        params: {
          orgId,
        },
        data: {
          status: status,
        },
      })
    );
  };
  const loadProjectStatus = () => {
    dispatch(
      getProjectStatusListOrg({
        url: `/crm/org/${orgId}/proj/status`,
        params: {
          orgId: orgId,
        },
      })
    );
  };
  const handleDelete = (statusItem) => {
    console.log("statusItem", statusItem);
    // let statusItemToDelete = projectStatusList[index];
    console.log("status item to delete", statusItem);
    console.log(Number(statusItem.count));
    if (Number(statusItem.count) == 0) {
      // if count is null we can delete status Number(statusItem.count) will return 0 if count is null
      let status = [
        {
          ...statusItem,
          isactive: false,
        },
      ];
      console.log("edited data", status);

      dispatch(
        createProjectStatusOrg({
          url: `/crm/user/organization/${orgId}/status`,
          params: {
            orgId,
          },
          data: {
            status: status,
          },
        })
      );
    } else {
      dispatch(
        setCreateSnackbar({
          message: "Cant Delete Status.Project inside",
          createSuccess: false,
          statusCode: 404,
        })
      );
    }
  };
  const handleViewStageClick = async (status) => {
    console.log("inside handle view stage click", status);
    //let statusItemToView = projectStatusList[index];
    console.log("status item to view", status);
    // const response=await AxiosInstance.get(`/crm/org/${id}/proj/status/${status.id}/stages`);
    // console.log('response',response);
    // console.log('before showing project status stage')
    showProjectStatusStage(status);
  };

  const reArrangeOrder = (updatedStatusList) => {
    console.log("re arrange order status list", statusList);
    let newStatusList = updatedStatusList.map((item, index) => {
      return {
        ...item,
        name: item.name,
        displayName: item.displayName ? item.displayName : item.name,
        count: item.count,
        id: item.id,
        order: index,
        isactive: true,
      };
    });
    console.log("new stastaus list", newStatusList);
    dispatch(
      updateProjectStatusOrder({
        url: `/crm/user/organization/${orgId}/status`,
        params: {
          orgId: orgId,
          // id: projectStatus.id,
        },
        data: {
          status: [...newStatusList],
        },
      })
    );
  };
  useEffect(() => {
    loadProjectStatus();
  }, []);

  // const [isInitialRender, setIsInitialRender] = useState(true);
  // useEffect(() => {
  //   console.log("initail render", isInitialRender);
  //   if (!isInitialRender) {
  //     // reArrangeOrder();
  //   } else {
  //     setIsInitialRender(false);
  //   }
  //   return () => {
  //     console.log("initail render", isInitialRender);
  //     if (!isInitialRender) reArrangeOrder();
  //   };
  // }, []);

  // useEffect(() => {
  //    reArrangeOrder()
  // }, [statusList]);
  console.log("project status list inside ", projectStatusList);

  return (
    <>
      {/* {projectStatusLoading ? (
        <>
          <Loading />
        </>
      ) : ( */}
      <>
        <DndProvider backend={HTML5Backend}>
          <div
            className="card_body"
            style={{
              padding: "20px",
              overflowY: "scroll",
              maxHeight: "600px",
            }} // projectStatusLoading ?
            // <>
            // <Loading/>
            // </>
            // :
          >
            <div
              className="d_flex  marginT10"
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {statusList?.map((item, index) => {
                //console.log('index',index);
                return (
                  <>
                    <StatusCard2
                      key={item.id}
                      name={item.name}
                      stage={item}
                      index={index}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      handleViewStageClick={handleViewStageClick}
                      moveCardHandler={moveCardHandler}
                      // onComplete={reArrangeOrder}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </DndProvider>
      </>
      {/* )} */}

      <BasicModal
        isOpen={openEditStatusModal}
        onClose={() => setOpenEditStatusModal(false)}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "400px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Edit Status</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={() => setOpenEditStatusModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(editStatus)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        {...register("name", {
                          required: true,
                          //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <label>Display</label>
                      <input
                        type="text"
                        name="displayName"
                        placeholder="Display Name"
                        {...register("displayName", {
                          required: true,
                          //pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    Edit Status
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </>
  );
};

export default ProjectStatusCard;
