// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-toolbar .ql-bold .ql-stroke,
.ql-toolbar .ql-italic .ql-stroke,
.ql-toolbar .ql-underline .ql-stroke,
.ql-toolbar .ql-link .ql-stroke,
.ql-toolbar .ql-list .ql-stroke,
.ql-toolbar .ql-clean .ql-stroke {
  fill: none;
  stroke: var(--dark-grey);
}
.ql-toolbar .ql-bold:hover .ql-stroke,
.ql-toolbar .ql-italic:hover .ql-stroke,
.ql-toolbar .ql-underline:hover .ql-stroke,
.ql-toolbar .ql-link:hover .ql-stroke,
.ql-toolbar .ql-list:hover .ql-stroke,
.ql-toolbar .ql-clean:hover .ql-stroke {
  fill: none;
  stroke: var(--primary-green) !important;
}
.ql-toolbar .ql-bold .ql-fill,
.ql-toolbar .ql-italic .ql-fill,
.ql-toolbar .ql-underline .ql-fill,
.ql-toolbar .ql-link .ql-fill,
.ql-toolbar .ql-list .ql-fill,
.ql-toolbar .ql-clean .ql-fill {
  fill: var(--dark-grey);
  stroke: none;
}
.ql-toolbar .ql-bold:hover .ql-fill,
.ql-toolbar .ql-italic:hover .ql-fill,
.ql-toolbar .ql-underline:hover .ql-fill,
.ql-toolbar .ql-link:hover .ql-fill,
.ql-toolbar .ql-list:hover .ql-fill,
.ql-toolbar .ql-clean:hover .ql-fill {
  fill: var(--primary-green) !important;
  stroke: none;
}
.ql-toolbar .ql-bold .ql-picker,
.ql-toolbar .ql-italic .ql-picker,
.ql-toolbar .ql-underline .ql-picker,
.ql-toolbar .ql-link .ql-picker,
.ql-toolbar .ql-list .ql-picker,
.ql-toolbar .ql-clean .ql-picker {
  color: var(--dark-grey);
}
.ql-toolbar .ql-bold:hover .ql-picker,
.ql-toolbar .ql-italic:hover .ql-picker,
.ql-toolbar .ql-underline:hover .ql-picker,
.ql-toolbar .ql-link:hover .ql-picker,
.ql-toolbar .ql-list:hover .ql-picker,
.ql-toolbar .ql-clean:hover .ql-picker {
  color: var(--primary-green) !important;
}
.ql-toolbar .ql-picker-label:hover {
  color: var(--primary-green) !important;
}
.ql-toolbar .ql-picker-label:hover .ql-stroke {
  fill: none;
  stroke: var(--primary-green) !important;
}
.ql-toolbar .ql-picker-label.ql-active {
  color: var(--primary-green) !important;
}
.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
  stroke: var(--primary-green) !important;
}
.ql-toolbar button.ql-active .ql-stroke {
  stroke: var(--primary-green) !important;
}`, "",{"version":3,"sources":["webpack://./src/fields/texteditor/style.scss"],"names":[],"mappings":"AAOI;;;;;;EACE,UAAA;EACA,wBAAA;AADN;AAGI;;;;;;EACE,UAAA;EACA,uCAAA;AAIN;AAFI;;;;;;EACE,sBAAA;EACA,YAAA;AASN;AANI;;;;;;EACE,qCAAA;EACA,YAAA;AAaN;AAVI;;;;;;EACE,uBAAA;AAiBN;AAfI;;;;;;EACE,sCAAA;AAsBN;AAlBI;EACE,sCAAA;AAoBN;AAnBM;EACE,UAAA;EACA,uCAAA;AAqBR;AAlBI;EACE,sCAAA;AAoBN;AAnBM;EACE,uCAAA;AAqBR;AAjBE;EACE,uCAAA;AAmBJ","sourcesContent":[".ql-toolbar {\n  .ql-bold,\n  .ql-italic,\n  .ql-underline,\n  .ql-link,\n  .ql-list,\n  .ql-clean {\n    .ql-stroke {\n      fill: none;\n      stroke: var(--dark-grey);\n    }\n    &:hover .ql-stroke {\n      fill: none;\n      stroke: var(--primary-green) !important;\n    }\n    .ql-fill {\n      fill: var(--dark-grey);\n      stroke: none;\n    }\n\n    &:hover .ql-fill {\n      fill: var(--primary-green) !important;\n      stroke: none;\n    }\n\n    .ql-picker {\n      color: var(--dark-grey);\n    }\n    &:hover .ql-picker {\n      color: var(--primary-green) !important;\n    }\n  }\n  .ql-picker-label {\n    &:hover {\n      color: var(--primary-green) !important;\n      .ql-stroke {\n        fill: none;\n        stroke: var(--primary-green) !important;\n      }\n    }\n    &.ql-active {\n      color: var(--primary-green) !important;\n      .ql-stroke {\n        stroke: var(--primary-green) !important;\n      }\n    }\n  }\n  button.ql-active .ql-stroke {\n    stroke: var(--primary-green) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
