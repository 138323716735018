import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.color}
        d="M4.56 11.232h2.268v-1.134H4.56v1.134ZM.59 4.428v1.134h10.207V4.428H.59Zm1.701 3.97h6.805V7.262H2.291v1.134Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.59 14.83H14.2v-14H.59z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
