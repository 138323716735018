import Button from "../../buttons/Button";

const LabelValue = ({ label, value, buttonLabel, onClick, className }) => {
  return (
    <p className={className ?? ""}>
      <b>{label}: </b>
      <span>
        {value && value !== "" ? (
          value
        ) : (
          <Button
            onClick={onClick}
            className="default_button link_blue border_none p_0"
          >
            {buttonLabel}
          </Button>
        )}
      </span>
    </p>
  );
};

export default LabelValue;
