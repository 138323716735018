import ModalBody from "../../components/modal/ModalBody";
import ModalHead from "../../components/modal/ModalHead";
import CloseIcon from "../../assets/images/close";
import { useSelector } from "react-redux";
import { designESRUpdate } from "../../redux/slices/orgSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "../../components/buttons/Button";

const schema = yup.object().shape({
  message: yup
    .string()
    .min(3, "Message must be 3 characters at minimum")
    .required("Message is required"),
});

const DesignWithEsrForm = () => {
  const createdOrg = useSelector((state) => state.org.createdOrg);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   name: newData?.name ?? "",
    //   description: newData?.description ?? "",
    //   email: newData?.email ?? "",
    // },
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    console.log("Data=------->", data);
    data.orgName = createdOrg.name;
    data.email = createdOrg?.mail;
    dispatch(
      designESRUpdate({
        url: `/crm/org/${createdOrg.id}/mail/designRequest`,
        data: data,
        params: {},
      })
    );
  };

  const onErrors = (errors) => {
    console.log("errors=---->", errors);
  };

  return (
    <>
      <ModalHead title="Design with ESR ">
        {/* <div className="modal_header_button_wrapper">
          <IconButton onClick={() => setOpenViewModal(false)}>
            <CloseIcon />
          </IconButton>
        </div> */}
      </ModalHead>
      <ModalBody>
        <form noValidate onSubmit={handleSubmit(onSubmit, onErrors)}>
          <div
            className="organisation"
            style={{ display: "block", padding: "0px" }}
          >
            <textarea
              type="text"
              name="message"
              placeholder="Message to ESR"
              rows={12}
              // onChange={(e) => setDescription(e.target.value)}
              {...register("message", {})}
            />
            <div>
              <Button className="primary_button marginT10" type="submit">
                Send
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
    </>
  );
};

export default DesignWithEsrForm;
