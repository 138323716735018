import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Loading from "../loading/Loading";
import {
  getUser,
  getUserLogout,
  setUserDetails,
  setUserFailed,
} from "../../redux/slices/authSlice";
import BasicModal from "../modal/Modal";
import Button from "../buttons/Button";
import CustomizedSnackbars from "../snackbar/CustomizedSnackbars";
import { getOrgList } from "../../redux/slices/orgSlice";
import { auth } from "../../firebase";
import { setCreateFailed } from "../../redux/slices/formSlice";
import Storage from "../../utils/Storage";

function VerifyEmailPage(props) {
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const userDetails = useSelector((state) => state.auth.userDetails);

  const handleVerfication = () => {
    props.verifyEmail().then(() => {});
  };

  return (
    <BasicModal
      isOpen={props.isOpen}
      onClose={props.handleClose}
      boxStyle={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: "90%", sm: "50%" },
        bgcolor: "background.paper",
        boxShadow: 24,
        height: "400px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{ padding: "0 40px" }}
        className="d_flex h_100 flex_column align_items_center justify_content_center"
      >
        {props.step === 0 && (
          <>
            <p className="text_center">
              Your email is not yet verified. Please click on the{" "}
              <b>Verify Now</b> button to verify
            </p>
            <div className="d_flex align_items_center">
              <Button className="primary_button" onClick={handleVerfication}>
                Verify Now
              </Button>
              <Button
                className="default_button marginL10 bg_black text_white"
                onClick={props.logout}
              >
                Logout
              </Button>
            </div>
          </>
        )}
        {props.step === 1 && (
          <>
            <p style={{ padding: "10px" }} className="text_center">
              We have sent you an email verification link to{" "}
              {userDetails?.email}. Please open the link and then click on the{" "}
              <b>Login Now</b> button here.
            </p>

            <div className="d_flex align_items_center">
              <Button
                className="default_button marginL10 bg_black text_white"
                onClick={props.login}
              >
                Login Now
              </Button>
            </div>
            <Timer
              seconds={seconds}
              minutes={minutes}
              setSeconds={setSeconds}
              setMinutes={setMinutes}
              verifyEmail={handleVerfication}
            />
          </>
        )}
      </div>
    </BasicModal>
  );
}
function Timer(props) {
  const { seconds, minutes, setSeconds, setMinutes } = props;

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return seconds > 0 || minutes > 0 ? (
    <p>
      {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds}
    </p>
  ) : (
    <>
      <p>Didn't recieved a Link?</p>
      <Button className="primary_button" onClick={props.verifyEmail}>
        Send Again
      </Button>
    </>
  );
}

function Protected() {
  const [step, setStep] = useState(0);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const loading = useSelector((state) => state.auth.loading);
  const navigate = useNavigate();
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const orgLoading = useSelector((state) => state.org.loading);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const [emailVerified, setEmailVerified] = useState(
    userDetails?.emailVerified
  );
  const [isOpen, setIsOpen] = useState(false);
  // const [pageLoading, setPageLoading] = useState(false);

  let location = useLocation();

  const dispatch = useDispatch();

  const tokenString = sessionStorage.getItem("token");
  let userToken = null;

  if (tokenString) {
    userToken = JSON.parse(tokenString);
  }

  const verifyEmail = async () => {
    await userDetails
      ?.sendEmailVerification()
      .then(() => {
        // Email verification sent!
        // ...

        if (step === 0) {
          setStep((prev) => prev + 1);
        }
      })
      .catch((error) => {
        // An error ocurred
        // ...
      });
  };

  const Logout = () => {
    dispatch(getUserLogout());
    setIsOpen(false);
  };

  const Login = async () => {
    await auth.currentUser.reload();
    if (auth.currentUser.emailVerified) {
      setEmailVerified(auth.currentUser.emailVerified);
      setIsOpen(false);
      navigate("/");

      // window.location.reload(false);
      // window.location.reload(false);
    } else {
      dispatch(
        setCreateFailed({
          message:
            "Please open the email verification link we have sent to your email id and try again.",
          createSuccess: true,
          statusCode: 600,
        })
      );
    }
  };

  useEffect(() => {
    if (userDetails && !emailVerified) {
      setIsOpen(true);
    }
  }, [userDetails]);

  useEffect(() => {
    if (emailVerified) {
      dispatch(
        getUser({
          url: `crm/user`,
          params: {},
        })
      );
    }
  }, [emailVerified]);

  useEffect(() => {
    if (!orgId && userInfo?.id && orgInfo && orgInfo?.length > 0) {
      Storage.write(`org${userInfo?.id}`, orgInfo[0]);
    } else if (userInfo?.id && !orgInfo) {
      dispatch(getOrgList({ url: "crm/user/organization", params: {} }));
    }
  }, [userInfo?.id]);

  if (!loading && !userDetails) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const handleClose = () => {
    setIsOpen(false);
  };

  if (loading || orgLoading) {
    return <Loading />;
  }

  if (userDetails && !emailVerified) {
    return (
      <VerifyEmailPage
        isOpen={isOpen}
        step={step}
        handleClose={handleClose}
        login={Login}
        logout={Logout}
        verifyEmail={verifyEmail}
      />
    );
  }

  return (
    <>
      <Outlet />;
    </>
  );
}

export default Protected;
