import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateView from "../date/DateView";

export default function ActivityTimeline({
  type,
  timelineData,
  randomColors,
  progressData,
  // dotStyle,
  // connectorStyle,
}) {
  var colors = [
    ["#49b6f51a", "#49B6F5"],
    ["#845adf1a", "#189D3D"],
    ["#e6533c1a", "#E6533C"],
    ["#f5b8491a", "#F5B849"],
    ["#26bf941a", "#26BF94"],

    // etc…
  ];
  const circleSize = 100;

  const [progressValue, setProgressValue] = React.useState(timelineData ?? []);
  const [data, setData] = React.useState([{ name: "progress", value: 13 }]);

  function getRandomColorCombination(colors) {
    var random = parseInt(Math.random() * colors.length);
    return colors[random];
  }

  const customStyles = {
    project_progress: {
      dotStyle: {
        padding: "2px",
        backgroundColor: "#fff",
        boxShadow: `0px 0px 0px 4px #189D3D, 0px 0px 0px 4px #189D3D, 0px 0px 0px 6px #189D3D`,
      },
      connectorStyle: {
        backgroundColor: "#189D3D",
        border: " 1px dashed #fff",
        width: "0",
      },
    },
  };

  React.useEffect(() => {
    if (type === "project_progress") {
      const percent = (progressData.length / timelineData.length) * 100;

      setData([{ name: "progress", value: Math.round(percent) }]);
      const combinedArray = timelineData.map((obj1) => {
        const matchingObj2 = progressData.find(
          (obj2) => obj2.status.name === obj1.name
        );

        // If there is a match, combine the objects, otherwise keep the original object
        return matchingObj2 ? { ...obj1, ...matchingObj2 } : obj1;
      });

      // Output the combined array
      setProgressValue(combinedArray);
    } else {
      setProgressValue(timelineData);
    }
  }, [timelineData, progressData]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {type === "project_progress" && (
        <div className="activity_progress_wrapper">
          <div className="activity_progress">
            <RadialBarChart
              width={100}
              height={100}
              cx="50%"
              cy="50%"
              innerRadius={17}
              outerRadius={20}
              barSize={7}
              data={data}
              startAngle={90}
              endAngle={-270}
            >
              <PolarAngleAxis
                type="number"
                domain={[0, 100]}
                angleAxisId={0}
                tick={false}
              />
              <RadialBar
                clockWise
                dataKey="value"
                cornerRadius={circleSize / 2}
                background={{ fill: "#D9D9D9" }}
                fill="#189d3d"
              />
              <text
                x={circleSize / 2}
                y={circleSize / 2}
                textAnchor="middle"
                dominantBaseline="middle"
                className="progress-label"
                fill="#000"
                fontSize="10px"
                fontWeight="500"
                fontFamily="Montserrat"
              >
                {data[0].value}%
              </text>
            </RadialBarChart>
          </div>
        </div>
      )}

      {progressValue?.length > 0 &&
        progressValue?.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot
                sx={
                  randomColors
                    ? {
                        backgroundColor: getRandomColorCombination(colors)[1],
                        boxShadow: ` 0px 2px 1px -1px ${
                          getRandomColorCombination(colors)[0]
                        }, 0px 1px 1px 4px ${
                          getRandomColorCombination(colors)[0]
                        }, 0px 1px 3px 0px ${
                          getRandomColorCombination(colors)[0]
                        }`,
                      }
                    : item.startDate
                    ? customStyles[type].dotStyle
                    : {
                        padding: "2px",
                        backgroundColor: "#fff",
                        boxShadow: `0px 0px 0px 4px #B0B0B0, 0px 0px 0px 4px #B0B0B0, 0px 0px 0px 6px #B0B0B0`,
                      }
                }
              />
              <TimelineConnector
                sx={
                  randomColors
                    ? {
                        backgroundColor: getRandomColorCombination(colors)[1],
                      }
                    : customStyles[type].connectorStyle
                }
              />
            </TimelineSeparator>
            <TimelineContent>
              {type === "project_progress" && (
                <Accordion
                  sx={{
                    borderRadius: "4px",
                    background: "rgba(55, 144, 107, 0.08)",
                    border: "none",
                    boxShadow: "none",
                    ":before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      ".css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
                        margin: 0,
                      },
                    }}
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: "#189d3d",
                        }}
                      />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="project_progress_header">
                      {item.displayName}
                      {item?.duration && (
                        <p className="project_progress_date">
                          duration:{" "}
                          {item?.duration?.minutes &&
                            `${item?.duration?.minutes} min`}{" "}
                          {item?.duration?.seconds &&
                            `${item?.duration?.seconds} sec`}
                        </p>
                      )}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ padding: "0px 16px 16px" }}>
                    {/* <Typography className="project_progress_description">
                    {item.description}
                  </Typography> */}
                  </AccordionDetails>
                </Accordion>
              )}
              {type === "dashboard_activity" && (
                <>
                  <p className="timeline_header">
                    <b>{item.Content}</b>
                  </p>
                  <p className="timeline_header">
                    {item.subject}{" "}
                    {item.createdBy && <span>by {item.createdBy.name}</span>}
                  </p>
                  {item.createdDate && (
                    <p className="timeline_header">
                      <small>
                        <DateView date={item.createdDate} />
                      </small>
                    </p>
                  )}
                </>
              )}
              {/* {type === "project_progress" && (
              <div className={item.date ? "project_progress_content" : ""}>
                <div className="d_flex justify_content_between">
                  <div className="w_75">
                    <p className="project_progress_header">{item.title}</p>
                    <p className="project_progress_description">
                      {item.description}
                    </p>
                  </div>

                  <p className="project_progress_date">{item.date}</p>
                </div>
              </div>
            )} */}
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}
