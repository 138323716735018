import { useEffect, useRef, useState } from "react";
import "./style.scss";
import Button from "../../buttons/Button";
import LinearProgressBar from "../../progress/LinearProgressBar";
import { useDispatch, useSelector } from "react-redux";

const Dropzone = ({
  onChange,
  onDelete,
  // onIsPurchseOrder,
  showCheckbox,
  // progress,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [sizeErrorMessage, setSizeErrorMessage] = useState(null);
  const [validFiles, setValidFiles] = useState([]);
  // const [isPoName, setIsPOName] = useState([]);
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const progress = useSelector((state) => state.upload.progress);

  // const [isPurchaseOrder, setIsPurchaseOrder] = useState(false);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const validateFile = (file) => {
    // const validTypes = [
    //   "image/jpeg",
    //   "image/jpg",
    //   "image/png",
    //   "image/gif",
    //   "image/x-icon",
    //   "application/pdf",
    //   "application/msword",
    //   "application/vnd.ms-excel",
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   ".doc",
    //   ".docx",
    //   ".xml",
    //   ".csv",
    // ];
    // if (validTypes.indexOf(file.type) === -1) {
    //   return false;
    // }
    return true;
  };

  const fileSize = (size) => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const fileType = (fileName) => {
    return (
      fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = (name) => {
    // find the index of the item
    // remove the item from array

    const validFileIndex = validFiles.findIndex((e) => e.name === name);
    validFiles.splice(validFileIndex, 1);
    // update validFiles array
    setValidFiles([...validFiles]);
    const selectedFileIndex = selectedFiles.findIndex((e) => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);
    // update selectedFiles array
    setSelectedFiles([...selectedFiles]);
    onDelete(name);
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        // add to an array so we can display the name of file
      } else {
        // add a new property called invalid
        // add to the same array so we can display the name of the file
        // set error message
        setErrorMessage("File type not permitted");
      }
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find((item) => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);

    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  useEffect(() => {
    if (validFiles) {
      const isFileTooLarge = validFiles.some(
        (file) => file.size > 500 * 1024 * 1024
      );

      if (isFileTooLarge) {
        setSizeErrorMessage(
          "You can upload maximum of 500 mb for a single file"
        );
      } else {
        setSizeErrorMessage(null);
        onChange(validFiles);
      }
    }
  }, [validFiles]);

  return (
    <div
      className="dropzone_container"
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
    >
      <div className="drop_container">
        <div className="drop_message">
          <div className="upload_icon"></div>
          <p className="font_12_dark_gray_600">
            Drop your files here or just{" "}
            <span className="font_12_green_600">browse</span>
          </p>
          <p className="font_8_dark_gray_inter">
            <small>
              Files should be in PDF, Word,PowerPoint or image format & should
              be less than 500mb for a single file.
            </small>
          </p>
          <Button
            onClick={fileInputClicked}
            className="primary_button marginT10"
          >
            Select file
          </Button>
        </div>
        <input
          ref={fileInputRef}
          className="file_input"
          type="file"
          multiple
          onChange={filesSelected}
        />
      </div>
      <div className="file_display_container">
        {sizeErrorMessage && (
          <span className="file_error_message">{sizeErrorMessage}</span>
        )}
        {validFiles.map((data, i) => (
          <div key={i}>
            <div className="file_status_bar">
              <div className="d_flex align_items_center">
                <div className="file_type_logo"></div>
                <div className="file_type">{fileType(data.name)}</div>
                <span
                  className={`file_name ${data.invalid ? "file_error" : ""}`}
                >
                  {data.name}
                </span>
                <span className="file_size">({fileSize(data.size)})</span>{" "}
                {data.invalid && (
                  <span className="file_error_message">({errorMessage})</span>
                )}
              </div>
              {/* {showCheckbox && (
                <Checkbox
                  style={{ position: "absolute", top: "25px" }}
                  type="checkbox"
                  name="isPurchaseOrder"
                  label="Is this purchase order"
                  onChange={(e) => handlePurchaseOrder(e, data.name)}
                  checked={isPoName.includes(data.name) ? true : false}
                />
              )} */}

              <div
                className="file_remove"
                onClick={() => removeFile(data.name)}
              >
                X
              </div>
            </div>
            <LinearProgressBar value={Number(progress[i]) || 0} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Dropzone;
