import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Storage from './Storage';

export const generatePdf=(headerRow,itemsData,columnWidths,headStyles)=>{
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const orgLogo = Storage.read(`org${userInfo?.id}`)?.logodtls;
  console.log('org logo',orgLogo);
    console.log('inside generate pdf');
    const pdf = new jsPDF();
    const imageUrl=orgLogo;

    pdf.addImage(orgLogo, 'JPEG', 10, 5, 40, 12);
    pdf.setFontSize(10);
    pdf.setFont('custom', 'bold');
    pdf.setFontSize(16);
    pdf.setFont('Newsreader', 'bold');
    pdf.text("BOM Details", 78, 15);
    const itemDetailsYStart = 23;
    pdf.autoTable({
        head: [headerRow],
        body: itemsData,
        startY: itemDetailsYStart, // Adjust the Y position as needed
        headStyles: {
            fillColor: headStyles.fillColor,
            textColor: headStyles.textColor,
            fontStyle: headStyles.fontStyle,
            fontSize: 10, // Adjust the font size as needed
            font: 'Newsreader', // Set the font family
            halign: 'left',
        },
        columnStyles: {
            0: { cellWidth: columnWidths[0] }, // Adjust column widths as needed
            1: { cellWidth: columnWidths[1] },
            2: { cellWidth: columnWidths[2] },
            3: { cellWidth: columnWidths[3] },
            4: { cellWidth: columnWidths[4] },
        },
        alternateRowStyles: { fillColor: [255, 255, 255] },
        bodyStyles: {
            fontSize: 10, // Adjust the font size for the body
            font: 'Newsreader', // Set the font family for the body
            cellPadding: { top: 1, right: 5, bottom: 1, left: 2 }, // Adjust cell padding
            textColor: [0, 0, 0], // Set text color for the body
            rowPageBreak: 'avoid', // Avoid row page breaks
        },
        //margin: { top: 10, left: 13 },
    });
    const summaryYStart = pdf.internal.pageSize.getHeight() - 50;
  
    // pdf.setFont('Newsreader', 'noraml')
    // pdf.text('Thanking You,', 13, summaryYStart + 20)
    // pdf.text('Yours Faithfully,', 13, summaryYStart + 24)
    // pdf.text('For ', 13, summaryYStart + 28)
    // pdf.setFont('Newsreader', 'bold')
    // pdf.text('Aalam Info Solutions LLP', 19, summaryYStart + 28)
  
    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
        pdf.line(10, 283, 200, 283)
        pdf.setPage(i);
        pdf.setFont('Newsreader');
        // pdf.text(
        //     `Page ${i} of ${totalPages}`,
        //     185,
        //     pdf.internal.pageSize.getHeight() - 5
        // );
    }
    pdf.save(`BOM.pdf`);
    // const pdfDataUri = pdf.output('datauristring');
    //    const newTab = window.open();
    //   newTab?.document.write(
    //     `<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`
    //   );
    
}