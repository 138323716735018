import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import "./style.scss";

const EmailChipInput = ({
  placeholder,
  onKeyDown,
  onChange,
  onPaste,
  onBlur,
  onFocus,
  value,
  error,
}) => {
  //   const [items, setItems] = useState(mailList ?? []);
  //   const [value, setValue] = useState("");
  //   const [error, setError] = useState(null);

  //   const handleKeyDown = (evt) => {
  //     if (["Enter", "Tab", ",", " "].includes(evt.key)) {
  //       evt.preventDefault();

  //       var newValue = value?.trim();

  //       if (newValue && isValid(newValue)) {
  //         setItems([...items, value]);
  //         setValue("");
  //       }
  //     }
  //   };

  //   const handleChange = (evt) => {
  //     setValue(evt.target.value);
  //     setError(null);
  //     onChange(evt);
  //   };

  //   const handleDelete = (item) => {
  //     setItems(items.filter((i) => i !== item));
  //   };

  //   const handlePaste = (evt) => {
  //     evt.preventDefault();

  //     var paste = evt.clipboardData.getData("text");
  //     var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

  //     if (emails) {
  //       var toBeAdded = emails.filter((email) => !isInList(email));
  //       setItems([...items, ...toBeAdded]);
  //     }
  //   };

  //   const isValid = (email) => {
  //     let error = null;

  //     if (isInList(email)) {
  //       error = `${email} has already been added.`;
  //     }

  //     if (!isEmail(email)) {
  //       error = `${email} is not a valid email address.`;
  //     }

  //     if (error) {
  //       setError(error);

  //       return false;
  //     }

  //     return true;
  //   };

  //   const isInList = (email) => {
  //     return items.includes(email);
  //   };

  //   const isEmail = (email) => {
  //     return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  //   };

  //   useEffect(() => {
  //     onSelect(items);
  //   }, [items]);

  //   useEffect(() => {
  //     if (mailList) setItems(mailList);
  //   }, [mailList]);

  return (
    <>
      <input
        className={"input " + (error && " has-error")}
        value={value}
        placeholder={placeholder ?? ""}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onPaste={onPaste}
        onBlur={onBlur}
        onFocus={onFocus}
      />

      {error && <p className="error">{error}</p>}
    </>
  );
};

export default EmailChipInput;
