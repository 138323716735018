import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projectList: [],
    projectData: {},
    projectMainStatusList: [],
    projectStatusList: [],
    projectStageList: [],
    projectDashboard: {},
    progressData: [],
    groupsAndUsersOfStatus:[],
    propertyItems: null,
    activityData: {
      activityList: [],
      totalPages: null,
      totalItems: null,
      currentPage: null,
    },
    activityPageDetails: {},
    loading: true,
    statusLoading: true,
    mainStatusLoading: true,
    stageLoading: true,
    progressLoading: true,
    activityLoading: true,
    propertyLoading: true,
    hoaDetails: null,
    financialInfo: null,
    systemDetails: null,
    ahjCodes:[]
  },
  reducers: {
    getProperty(state) {
      state.propertyLoading = true;
      return state;
    },
    getAhjCodes(state){
      return state
    },
    getProjectMainStatusList(state) {
      state.mainStatusLoading = true;
      return state;
    },
    getProjectStatusList(state) {
      state.statusLoading = true;
      return state;
    },
    getProjectStageList(state) {
      state.stageLoading = true;
      return state;
    },
    createNewProject(state) {
      state.loading = true;
      return state;
    },
    editProject(state) {
      state.loading = true;
      return state;
    },
    updatePropertyDetails(state) {
      // state.loading = true;
      return state;
    },
    fileUploadProject(state) {
      state.loading = true;
      return state;
    },
    getProjectList(state) {
      state.loading = true;
      return state;
    },
    getProject(state) {
      state.loading = true;
      return state;
    },
    getProjectDashboard(state) {
      state.loading = true;
      return state;
    },
    updateProjectStatus(state) {
      state.statusLoading = true;
      return state;
    },
    createProjectStatusGroup(state) {
      state.statusLoading = true;
      return state;
    },
    createStatusGroupUser(state) {
      state.statusLoading = true;
      return state;
    },
    getProjectProgress(state) {
      state.progressLoading = true;
      return state;
    },
    getProjectActivity(state) {
      state.activityLoading = true;
      return state;
    },
    setProjectProperty: (state, action) => {
      state.propertyItems = action.payload;
      state.propertyLoading = false;
      // state.loading = false;
    },
    setProjectMainStatusList: (state, action) => {
      state.projectMainStatusList = action.payload;
      state.mainStatusLoading = false;
    },
    setProjectStatusList: (state, action) => {
      state.projectStatusList = action.payload;
      state.statusLoading = false;
    },
    setGroupsAndUsersOfStatusList: (state, action) => {
      console.log('action.payload',action.payload);
      state.groupsAndUsersOfStatus = action.payload;
      state.statusLoading = false;
    },
    setAhjCodes:(state,action)=>{
      state.ahjCodes=action.payload
    },
    setProjectStageList: (state, action) => {
      state.projectStageList = action.payload;
      state.stageLoading = false;
    },
    setProjectList: (state, action) => {
      state.projectList = action.payload;
      state.loading = false;
    },
    setProject: (state, action) => {
      state.projectData = action.payload;
      state.loading = false;
    },
    setProjectDashboard: (state, action) => {
      state.projectDashboard = action.payload;
      state.loading = false;
    },
    setProjectProgress: (state, action) => {
      state.progressData = action.payload;
      state.progressLoading = false;
    },
    setProjectActivity: (state, action) => {
      state.activityData = {
        // ...action.payload,
        activityList:
          action.payload.currentPage > 1
            ? [...state.activityData.activityList, ...action.payload.trackjson]
            : action.payload.trackjson,
        totalPages: action.payload.totalPages,
        totalItems: action.payload.totalItems,
        currentPage: action.payload.currentPage,
      };

      state.activityLoading = false;
    },
    setProjectLoadingFalse: (state, action) => {
      state.loading = false;
    },
    setPropertyLoadingFalse: (state, action) => {
      state.propertyLoading = false;
    },
    setMainStatusLoadingFalse: (state, action) => {
      state.mainStatusLoading = false;
    },
    setStatusLoadingFalse: (state, action) => {
      state.statusLoading = false;
    },
    setStageLoadingFalse: (state, action) => {
      state.stageLoading = false;
    },
    setProgressLoadingFalse: (state, action) => {
      state.progressLoading = false;
    },
    setActivityLoadingFalse: (state, action) => {
      state.activityLoading = false;
    },
    setHOADetails: (state, action) => {
      state.hoaDetails = action.payload;
      state.loading = false;
    },
    setFinancialInfo: (state, action) => {
      state.financialInfo = action.payload;
      state.loading = false;
    },
    setSystemDetails: (state, action) => {
      state.systemDetails = action.payload;
      state.loading = false;
    },
    createProjectAhj:(state)=>{
      return state
    }
  },
});

export const {
  getProperty,
  setProjectProperty,
  createNewProject,
  editProject,
  updatePropertyDetails,
  fileUploadProject,
  getProjectList,
  setProjectList,
  getProject,
  setProject,
  getProjectMainStatusList,
  getProjectStatusList,
  setProjectMainStatusList,
  setProjectStatusList,
  getProjectStageList,
  setProjectStageList,
  getProjectDashboard,
  setProjectDashboard,
  updateProjectStatus,
  createProjectStatusGroup,
  createStatusGroupUser,
  getProjectProgress,
  setProjectProgress,
  getProjectActivity,
  setProjectActivity,
  setProjectLoadingFalse,
  setPropertyLoadingFalse,
  setMainStatusLoadingFalse,
  setStatusLoadingFalse,
  setStageLoadingFalse,
  setProgressLoadingFalse,
  setActivityLoadingFalse,
  setHOADetails,
  setFinancialInfo,
  setSystemDetails,
  getAhjCodes,
  setAhjCodes,
  createProjectAhj,
  setGroupsAndUsersOfStatusList
} = projectSlice.actions;

export default projectSlice.reducer;
