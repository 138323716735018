import React from 'react'
import Button from '../../buttons/Button'
import FeedIcon from "@mui/icons-material/Feed";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddIcon from "@mui/icons-material/Add";
import DateView from "../../date/DateView";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { useNavigate } from 'react-router-dom';

const ProjectsCard = ({project}) => {
  const navigate=useNavigate();
  return (
    <div className="light_bg_border deal_item marginT10" key={project.id}>
    <div className="d_flex align_items_center justify_content_between">
      <h4 className="my_0">{project.name?project.name:"project name"}</h4>
      <div className="d_flex align_items_center">
        <Button className="icon_button marginL10 d_flex align_items_center">
          <FeedIcon className="btn_icon" />
          Project Status
        </Button>
        <Button
          onClick={() => navigate(`/projects/${project.id}`)}
          className="icon_button marginL10 d_flex align_items_center primary_bg text_white"
        >
          <OpenInNewIcon className="btn_icon" /> View
        </Button>
      </div>
    </div>
    <div className="d_flex align_items_center justify_content_between">
      <div>
        <p className="mb_0">
          Created: <DateView date={"12/12/2024"} />{" "}
        </p>
        <p className="my_0">Created By: {"Test User"} </p>

        {/* <p className="my_0">
          <small>survey id: 1381359</small>
        </p> */}
      </div>
      {/* <Button className="icon_button marginL10 d_flex align_items_center bg_black text_white">
        <LowPriorityIcon className="btn_icon" /> Change Order
      </Button> */}
    </div>
  </div>
  )
}

export default ProjectsCard