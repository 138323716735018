import { call, fork, put, takeEvery } from "redux-saga/effects";
import { getItem } from "../../services/service";
import { getProfileData, getProfileProjectData, setProfileData, setProfileProjectData } from "../slices/profileSlice";
import { setDialog } from "../slices/dialogSlice";

function* onLoadProfileDataAsync(payload) {
  try {
    const { url, data, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    yield put(setProfileData({ ...response.data }));
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } 
  }
}

function* onLoadProfileProjectDataAsync(payload) {
  try {
    const { url, data, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if(response.data.trackjson){
    yield put(setProfileProjectData([...response.data.trackjson]))
    }else{
      yield put(setProfileProjectData([]))
    }
    
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } 
  }
}


export function* watchGetProfileData() {
  yield takeEvery(getProfileData.type, onLoadProfileDataAsync);
}
export function* watchGetProfileProjectData() {
  yield takeEvery(getProfileProjectData.type, onLoadProfileProjectDataAsync);
}
export const profileSagas = [
  fork(watchGetProfileData),
  fork(watchGetProfileProjectData)
];
