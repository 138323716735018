import { createSlice } from "@reduxjs/toolkit";
import Storage from "../../utils/Storage";

const orgSlice = createSlice({
  name: "org",
  initialState: {
    orgInfo: null,
    group: [],
    Users: [],
    currentOrg: null,
    orgUsers: null,
    loading: false,
    groupLoading: false,
    orgUserLoading: false,
    projectStatusStageLoading: false,
    projectStatusLoading:false,
    projectStatusUpdateLoading: false,
    isDesign: false,
    createdOrg: null,
    designESR: null,
    updatedOrg: null,
    projectListOrg: [],
    leadListOrg: [],
    userListOrg: [],
    moderatorsListOrg: [],
    pendingUserListOrg: [],
    projectStatusListOrg: [],
    mainProjectStatusListOrg: [],
    projectStatusStageListOrg: null,
    organisationDetails: null,
    organisationDetailsLoading: false,
    creatingProjectStatus: false,
  },
  reducers: {
    getOrganisationDetails(state) {
      state.organisationDetailsLoading = true;
      return state;
    },
    createNewOrg(state) {
      state.loading = true;
      return state;
    },
    designESRUpdate(state) {
      state.loading = true;
      return state;
    },
    createProjectStatusStageOrg(state) {
     /// state.projectStatusLoading=true;
      return state;
    },
    updateProjectStatusStageOrderOrg(state) {
      /// state.projectStatusLoading=true;
      state.projectStatusUpdateLoading=true;

       return state;
     },
    createProjectStatusOrg(state) {
      state.creatingProjectStatus = true;
      return state;
    },
    updateProjectStatusOrder(state) {
      //state.creatingProjectStatus = true;
      return state;
    },
    editOrg(state) {
      // state.loading = true;
      return state;
    },
    getOrgList(state) {
      state.loading = true;
      return state;
    },
    getProjectListOrg(state) {
      // state.loading=true;
      return state;
    },
    getLeadListOrg(state) {
      return state;
    },
    getUserListOrg(state) {
      return state;
    },
    getMainProjectStatusListOrg(state) {
      return state;
    },
    setOrgDefault(state) {
      // state.loading = true;
      return state;
    },
    setOrgDefaultSO(state) {
      // state.loading = true;
      return state;
    },
    createNewGroup(state) {
      state.groupLoading = true;
      return state;
    },
    editGroup(state) {
      state.loading = true;
      return state;
    },
    getGroupList(state) {
      state.groupLoading = true;
      return state;
    },
    getGroupUserList(state) {
      state.groupLoading = true;
      return state;
    },
    getOrgUserList(state) {
      state.orgUserLoading = true;
      return state;
    },
    getModeratorsListOrg(state) {
      return state;
    },
    getPendingUserListOrg(state) {
      return state;
    },
    getProjectStatusListOrg(state) {
      state.projectStatusLoading=true;
      return state;
    },
    getProjectStatusStageListOrg(state) {
      state.projectStatusStageLoading = true;
      return state;
    },
    setOrganisationDetails: (state, action) => {
      state.organisationDetails = action.payload;
      state.organisationDetailsLoading = false;
    },
    setMainProjectStatusListOrg: (state, action) => {
      state.mainProjectStatusListOrg = action.payload;
    },
    setOrgList: (state, action) => {
      state.orgInfo = action.payload;
      state.loading = false;
    },
    setProjectListOrg: (state, action) => {
      state.projectListOrg = action.payload;
      // state.loading=false;
    },
    setLeadListOrg: (state, action) => {
      state.leadListOrg = action.payload;
    },
    setUserlistOrg: (state, action) => {
      state.userListOrg = action.payload;
    },
    setModeratorsListOrg: (state, action) => {
      state.moderatorsListOrg = action.payload;
    },
    setPendingUserListOrg: (state, action) => {
      state.pendingUserListOrg = action.payload;
    },
    setOrgCreateSuccess: (state, action) => {
      state.createdOrg = action.payload;
      state.loading = false;
    },
    setDesignESRSuccess: (state, action) => {
      state.designESR = action.payload;
      state.loading = false;
    },
    setProjectStatusStageCreateSuccess: (state, action) => {
      state.projectStatusStageLoading = false;

      state.loading = false;
    },
    setProjectStatusCreateSuccess: (state, action) => {
      state.creatingProjectStatus = false;
    },
    setOrgUpdateSuccess: (state, action) => {
      state.updatedOrg = action.payload;
      state.loading = false;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
      state.groupLoading = false;
    },
    setGroupUserList: (state, action) => {
      state.Users = action.payload;
      state.groupLoading = false;
    },
    setProjectStatusListOrg: (state, action) => {
      state.projectStatusListOrg = action.payload;
      state.projectStatusLoading=false;

    },
    setProjectStatusStageListOrg: (state, action) => {
      state.projectStatusStageListOrg = action.payload;
      state.projectStatusStageLoading = false;
    },
    setOrgUserList: (state, action) => {
      state.orgUsers = action.payload;
      state.orgUserLoading = false;
    },
    setOrgCreateDefaultSuccess: (state, action) => {
      state.createdOrg = null;
      state.loading = false;
    },

    setOrgCreateDefaultSOSuccess: (state, action) => {
      state.createdOrg = null;

      state.loading = false;
    },

    switchOrg: (state, action) => {
      const userInfo = JSON.parse(sessionStorage.getItem("user"));

      Storage.write(`org${userInfo?.id}`, action.payload);
      state.loading = false;
    },

    setIsDesign: (state, action) => {
      state.isDesign = action.payload;
    },

    inviteNewUser(state) {
      // state.loading = true;
      return state;
    },
    setorgLoadingFalse: (state, action) => {
      state.loading = false;
    },
    setgroupLoadingFalse: (state, action) => {
      state.groupLoading = false;
    },
    setorgUserLoadingFalse: (state, action) => {
      state.orgUserLoading = false;
    },
    setProjectStatusUpadteLoading: (state, action) => {
      state.projectStatusUpdateLoading = action.payload;
    },
  },
});

export const {
  createNewOrg,
  designESRUpdate,
  editOrg,
  editGroup,
  setOrgList,
  getOrgList,
  getGroupList,
  createNewGroup,
  setGroup,
  inviteNewUser,
  setorgLoadingFalse,
  getGroupUserList,
  setGroupUserList,
  getOrgUserList,
  setOrgUserList,
  switchOrg,
  setOrgCreateSuccess,
  setDesignESRSuccess,
  setOrgUpdateSuccess,
  setOrgDefault,
  setOrgDefaultSO,
  setOrgCreateDefaultSuccess,
  setOrgCreateDefaultSOSuccess,
  setgroupLoadingFalse,
  setorgUserLoadingFalse,
  getProjectListOrg,
  setProjectListOrg,
  getLeadListOrg,
  setLeadListOrg,
  getUserListOrg,
  setUserlistOrg,
  getModeratorsListOrg,
  setModeratorsListOrg,
  getProjectStatusListOrg,
  setProjectStatusListOrg,
  getProjectStatusStageListOrg,
  setProjectStatusStageListOrg,
  createProjectStatusStageOrg,
  setProjectStatusStageCreateSuccess,
  createProjectStatusOrg,
  setProjectStatusCreateSuccess,
  getOrganisationDetails,
  setOrganisationDetails,
  getPendingUserListOrg,
  setPendingUserListOrg,
  getMainProjectStatusListOrg,
  setMainProjectStatusListOrg,
  setIsDesign,
  updateProjectStatusOrder,
  updateProjectStatusStageOrderOrg,
  setProjectStatusUpadteLoading
} = orgSlice.actions;

export default orgSlice.reducer;
