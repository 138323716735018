import { call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import { createItem, getItem } from "../../services/service";
import { setDialog } from "../slices/dialogSlice";
import { getUsersInGroup, removeUsersFromGroup, setUsersInGroup } from "../slices/groupSlice";
import { setCreateSuccess } from "../slices/formSlice";

function* onLoadUsersInGroupAsync(payload) {
  try {
   
    const { url, params } = payload.payload;
    const response = yield call(getItem, { url, params });
    if (response.status == 200) {
      yield put(setUsersInGroup([...response.data]));
    }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    }
  }
}
function* onRemoveUsersFromGroupAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(
      setCreateSuccess({
        message:"removed user from group",
        createSuccess: response?.status === 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      // yield put(
      //   setCreateFailed({
      //     ...error?.errors?.data,
      //     createSuccess: false,
      //     statusCode: error.errors.status,
      //   })
      // );
    }
    
  }
}

function* onRemoveUsersFromGroup() {
  yield takeLatest(removeUsersFromGroup.type, onRemoveUsersFromGroupAsync);
}

export function* watchUsersInGroup() {
  yield takeEvery(getUsersInGroup.type, onLoadUsersInGroupAsync);
}

export const groupSagas = [
  fork(watchUsersInGroup),
  fork(onRemoveUsersFromGroup)

];
