const CardTitle = ({ title, children, type, style }) => {
  return (
    <div
      style={style ?? {}}
      className={
        type === "project_view"
          ? "dashboard_card_title_wrapper_project_view"
          : "dashboard_card_title_wrapper"
      }
    >
      <p
        className={
          type === "project_view"
            ? "dashboard_card_title_no_bg"
            : "dashboard_card_title "
        }
      >
        {title}
      </p>
      {children}
    </div>
  );
};

export default CardTitle;
