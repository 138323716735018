import { createSlice } from "@reduxjs/toolkit";

const commentSlice = createSlice({
  name: "comment",
  initialState: {
    commentData: null,

    loading: true,
  },
  reducers: {
    createNewComment(state) {
      state.loading = true;
      return state;
    },

    getCommentList(state) {
      // state.loading = true;
      return state;
    },

    setCommentList: (state, action) => {
      state.commentData = {
        ...action.payload,
        // commentsjson: [
        //   ...state.commentData.commentsjson,
        //   ...action.payload.commentsjson,
        // ],
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      };
      state.loading = false;

      // commentsjson: [
      //   ...state.commentData.commentsjson,
      //   ...action.payload.commentsjson,
      // ],
    },
    setCommentLoadingFalse: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  createNewComment,
  getCommentList,
  setCommentList,
  setCommentLoadingFalse,
} = commentSlice.actions;

export default commentSlice.reducer;
