import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTitle } from "../redux/slices/titleSlice";

const Report = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("Report"));
  }, []);

  return <div className="project">Report</div>;
};

export default Report;
