import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import CardTitle from "../../../dashboard/CardTitle";
import LabelValue from "../LabelValue";
import HoaDetailsForm from "./HoaDetailsForm";
import { useState } from "react";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";
import { useSelector } from "react-redux";
import Loading from "../../../loading/Loading";

const HoaDetails = () => {
  const [isOpen, setIsOpen] = useState(false);

  const hoaDetails = useSelector((state) => state.additionalInfo.hoaDetails);
  const loading = useSelector((state) => state.additionalInfo.loading);

  const editHoaDetails = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="card_container">
      <CardTitle title="HOA Details" type="project_view">
        <IconButton className="marginL10" size="small" onClick={editHoaDetails}>
          <Edit />
        </IconButton>
      </CardTitle>
      <div className="card_content">
        <div>
          <LabelValue
            className="m_0"
            label="Contact Name"
            value={hoaDetails?.contactName}
            buttonLabel="Add Contact Name"
            onClick={editHoaDetails}
          />
          <LabelValue
            label="Phone"
            value={hoaDetails?.phone}
            buttonLabel="Add Phone"
            onClick={editHoaDetails}
          />
          <LabelValue
            label="Email"
            value={hoaDetails?.email}
            buttonLabel="Add Email"
            onClick={editHoaDetails}
          />
          <LabelValue
            label="HOA Name"
            value={hoaDetails?.hoaName}
            buttonLabel="Add HOA Name"
            onClick={editHoaDetails}
          />
        </div>
        <BasicModal
          isOpen={isOpen}
          onClose={handleClose}
          boxStyle={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            maxHeight: "500px",
            display: "flex",
            flexDirection: "column",
          }}
          sx={modalStyle}
        >
          <HoaDetailsForm
            // id={id}
            // projectData={projectData}
            hoaDetails={hoaDetails}
            onClose={handleClose}
          />
        </BasicModal>
      </div>
    </div>
  );
};

export default HoaDetails;
