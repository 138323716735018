import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { orange } from "@mui/material/colors";

const localizer = dayjsLocalizer(dayjs);

const EventComponent = ({ event }) => {
  let icon;
  switch (event.isTask) {
    case "false":
      icon = <EventAvailableIcon color="white" />;
      break;
    case "true":
      icon = <ChecklistIcon sx={{ color: orange[800] }} />;
      break;
  }

  return (
    <div className="d_flex align_items_center justify_content_between">
      {event.title} {icon}
    </div>
  );
};

const CustomBigCalendar = ({
  events,
  onSelectEvent,
  onSelectSlot,
  eventStyleGetter,
}) => (
  <div>
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      onSelectEvent={onSelectEvent}
      onSelectSlot={onSelectSlot}
      selectable={true}
      eventPropGetter={eventStyleGetter}
      components={{
        event: EventComponent,
      }}
    />
  </div>
);

export default CustomBigCalendar;
