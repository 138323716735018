import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const CheckListItemCard = ({name,description}) => {
  return (
    <div className="light_bg_border marginT10">
    <div className="d_flex justify_content_between">
      <div>
        <div className="d_flex align_items_center">
          {/* <CheckCircleIcon
            style={{ color: "#189d3d" }}
            className="org_icon"
          /> */}
          <div>
            <h4 className="my_0">{name}</h4>
          </div>
        </div>
        <p>{description}</p>
      </div>
    </div>
  </div>
  )
}

export default CheckListItemCard