import PersonIcon from "@mui/icons-material/Person";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import IconText from "../common/IconText";

export default function CustomerContact({ title, name, email, phone }) {
  return (
    <div>
      <h4 className="my_0">{title}</h4>
      <IconText
        className="mb_0 marginT10 d_flex word_break"
        icon={<PersonIcon fontSize="small" className="marginR10" />}
        title={name}
      />
      <IconText
        className="mb_0 marginT10 d_flex word_break"
        icon={<MailIcon fontSize="small" className="marginR10" />}
        title={email}
      />
      <IconText
        className="mb_0 marginT10 d_flex word_break"
        icon={<PhoneIcon fontSize="small" className="marginR10" />}
        title={phone}
      />
    </div>
  );
}
