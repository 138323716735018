import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import CardTitle from "../../../components/dashboard/CardTitle";
import DataTable from "../../../components/table/DataTable";
import {
  addUsersToGroup,
  getUsersInGroup,
  removeUsersFromGroup,
} from "../../../redux/slices/groupSlice";
import { CardItem } from "../../../utils/CardItem";
import CustomBreadcrumbOrg from "./CustomBreadCrumbOrg";
import PlusIcon from "../../../assets/images/plus";
import BasicModal from "../../../components/modal/Modal";
import { modalStyle } from "../../../components/constants/ConstData";
import CustomAutoComplete from "../../../fields/autoComplete/CustomAutoComplete";
import { useForm } from "react-hook-form";
import CloseIcon from "../../../assets/images/close";
const columns = [
  {
    field: "id",
    headerName: "User Id",
    width: 80,
  },
  {
    field: "mame",
    headerName: "User Name",
    width: 150,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },

  {
    field: "email",
    headerName: "Email",
    width: 300,
    renderCell: (params) => <Typography>{params?.row?.email}</Typography>,
  },
  {
    field: "user_type",
    headerName: "User Type",
    width: 300,
    renderCell: (params) => {
      let userType = "User";
      if (params.row.isowner === "true") userType = "Owner";
      else if (params?.row?.isModerator == "true") userType = "Moderator";

      return <Typography>{userType}</Typography>;
    },
  },
];
const GroupsViewCard = ({ groupDetails, goBack, orgId }) => {
  const [rowsSelected, setRowsSelected] = useState([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [userSelected, setUserSelected] = useState(false);
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.org.userListOrg);
  console.log("users in org", userList);
  const groupUsers = useSelector((state) => state.group.usersInGroup);
  console.log("group users", groupUsers);
  const createData = useSelector((state) => state.form.createData);
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const breadcrumbs = [
    <Link key="1" onClick={goBack}>
      <b>{groupDetails.name}</b>
    </Link>,
    <Typography key="2" color="text.primary">
      Users
    </Typography>,
  ];
  const getUsers = () => {
    console.log("inside get users");
    dispatch(
      getUsersInGroup({
        url: `crm/user/groups/${groupDetails.id}/users`,
        params: {},
      })
    );
  };
  const removeUser = () => {
    let usersToRemove = rowsSelected.map((item, index) => {
      return {
        // id: groupUsers.filter((row) => row.id == item)[0].grpmapid,
        id: 0,
        map_userids: item,
        active_status: 0,
      };
    });
    dispatch(
      removeUsersFromGroup({
        url: `/crm/org/${orgId}/groups/${groupDetails.id}/users`,
        // params: {
        //   orgId,
        //   id: groupDetails.id,
        // },
        data: {
          usrdtl: usersToRemove,
        },
      })
    );
  };
  const checkRowSelection = (params) => {
    console.log("params", params);
    return true;
  };
  const addUserToGroup = (data) => {
    console.log("user selected", userSelected);
    console.log("group users", groupUsers);
    if (userSelected) {
      dispatch(
        addUsersToGroup({
          url: `/crm/org/${orgId}/groups/${groupDetails.id}/users`,
          // params: {
          //   orgId,
          //   id: groupDetails.id,
          // },
          data: {
            usrdtl: [
              {
                // id:groupDetails.id,
                id: 0,
                map_userids: userSelected.userId,
                active_status: 1,
              },
            ],
          },
        })
      );
      // setOpenAddUserModal(false);
      // reset();
    } else {
      setOpenAddUserModal(false);
    }
  };
  const getUserListOptions = () => {
    let result = userList.filter(
      (largeItem) =>
        !groupUsers.some((smallItem) => largeItem.userId === smallItem.id)
    );
    console.log("result", result);
    return result.map((item) => {
      return {
        ...item,
        id: item.userId,
      };
    });
  };
  useEffect(() => {
    getUsers();
    setOpenAddUserModal(false);
    reset();
  }, [snackbarData]);
  return (
    <div className="">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CardItem height="810px">
            <div className="card_container" style={{ padding: "20px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <CustomBreadcrumbOrg breadcrumbs={breadcrumbs} />
                </div>
                <div>
                  <Button
                    type="submit"
                    className="primary_button d_flex align_items_center"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "18px",
                      padding: "5px 20px",
                    }}
                    leftIcon={<PlusIcon color="#fff" />}
                    onClick={() => {
                      setOpenAddUserModal(true);
                    }}
                  >
                    Add User
                  </Button>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <DataTable
                  rows={[...groupUsers]}
                  columns={columns}
                  checkboxSelection={(params) => {
                    console.log("params", params);
                  }}
                  onRowSelectionModelChange={(row) => {
                    console.log("row", row);
                    setRowsSelected(row);
                  }}
                  //onRowClick={(row) => navigate(`/leads/${row.id}`)}
                  //loading={loading}
                  disableSelectionOnClick={(params) => {
                    console.log("params", params);
                    return params.row.disableSelection || false;
                  }}
                  isRowSelectable={(params) => params.row.isowner !== "true"}
                />
              </div>
              <div style={{ padding: "20px" }}>
                {rowsSelected.length > 0 && (
                  <>
                    <Button
                      className="primary_button marginT10"
                      onClick={removeUser}
                    >
                      Remove User
                    </Button>
                  </>
                )}
              </div>
            </div>
          </CardItem>
        </Grid>
      </Grid>
      <BasicModal
        isOpen={openAddUserModal}
        onClose={() => setOpenAddUserModal(false)}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "400px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title ">Add User</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={() => setOpenAddUserModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="modal_body">
            <div
              className="organisation"
              style={{ display: "block", padding: "0px" }}
            >
              <div className="">
                <form noValidate onSubmit={handleSubmit(addUserToGroup)}>
                  <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                    {/* <Grid item xs={12} sm={12} md={7} lg={7}>
                      <label>Team Member</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        {...register("email", {
                          required: true,
                          pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        })}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <label>User</label>
                      <CustomAutoComplete
                        name="groupId"
                        className="user_access_level"
                        open={openDropDown}
                        onOpen={() => {
                          setOpenDropDown(true);
                        }}
                        onClose={() => {
                          setOpenDropDown(false);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value.id
                        }
                        // loading={loading}
                        options={[...getUserListOptions()]}
                        onChange={(event, newValue) => {
                          console.log("new valeu", newValue);
                          setUserSelected(newValue);
                          //setGroupSelected(newValue.id);
                        }}
                        register={register}
                        renderOption={(props, option) => (
                          <Box style={{ fontSize: 13 }} {...props}>
                            {option.name}
                          </Box>
                        )}
                        //value={groupSelected}
                        getOptionLabel={(option) => {
                          if (option.name) {
                            return option?.name;
                          } else {
                            return "Please Select";
                          }
                        }}
                        // noOptionsText={
                        //   <div className="d_flex flex_column align_items_center justify_content_center">
                        //     <p>No Data Found</p>
                        //     <Button
                        //       className="primary_button_small"
                        //       onClick={(e) => {
                        //         e.preventDefault();
                        //       }}
                        //     >
                        //       Create New
                        //     </Button>
                        //   </div>
                        // }
                      />
                    </Grid>
                  </Grid>
                  <Button type="submit" className="primary_button marginT10">
                    Add User
                  </Button>
                </form>
              </div>
            </div>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </div>
  );
};

export default GroupsViewCard;
