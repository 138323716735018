import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/images/plus";
import Button from "../../../components/buttons/Button";
import { getMaterialList } from "../../../redux/slices/materialSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/loading/Loading";
import ConstructionIcon from "@mui/icons-material/Construction";

const MaterialList = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.material.loading);
  const materials = useSelector((state) => state.material.materials);

  useEffect(() => {
    dispatch(getMaterialList({ url: `/crm/org/${id}/bom/store`, params: {} }));
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center justify_content_between marginB10">
          <div className="d_flex align_items_center">
            <IconButton onClick={() => navigate("/org")}>
              <ArrowBackIcon />
            </IconButton>
            <h3>Materials</h3>
          </div>
          <Link
            style={{ textDecoration: "none" }}
            to={`/org/${id}/materials/manage/create`}
          >
            <Button
              type="submit"
              className="outline_button d_flex align_items_center"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                padding: "5px 20px",
              }}
              leftIcon={<PlusIcon color="#189d3d" />}
            >
              New Material
            </Button>
          </Link>
        </div>
        <div className="org_list_wrapper">
          {materials?.length > 0 ? (
            materials.map((item) => (
              <div key={item.id} className="light_bg_border">
                <div className="d_flex justify_content_between">
                  <div>
                    <div className="d_flex align_items_center">
                      <ConstructionIcon
                        style={{ color: "#189d3d" }}
                        className="org_icon"
                      />
                      <div>
                        <h4 className="my_0">{item.name}</h4>
                      </div>
                    </div>
                    <p className="font_12_dark_gray marginT10">{item.desc}</p>
                    <div className="marginT10">
                      {item?.baseuom && (
                        <p className="font_12_dark_gray">
                          <b>Unit:</b> {item?.baseuom}
                        </p>
                      )}
                      {item?.baseprice && (
                        <p className="font_12_dark_gray">
                          <b>Base Price:</b> {item?.baseprice}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text_center">No Materials found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialList;
