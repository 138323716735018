import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={14}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.color}
        d="M7.942 5.576 4.484 2.118 1.026 5.576-.032 4.517 4.484.001 9 4.517 7.942 5.576Z"
      />
    </g>
    <g clipPath="url(#b)">
      <path
        fill="#75787D"
        d="m1.058 8.424 3.458 3.458 3.458-3.458 1.058 1.059-4.516 4.516L0 9.483l1.058-1.059Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M9 0H0v6h9z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M0 14h9V8H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
