import { useDispatch, useSelector } from "react-redux";
import Button from "../../../buttons/Button";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import {
  getProjectStageList,
  updateProjectStatus,
} from "../../../../redux/slices/projectSlice";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import CustomDialog from "../../../dialog/CustomDialog";
import { useEffect, useState } from "react";
import { red } from "@mui/material/colors";
import { Delete } from "@mui/icons-material";
import Status from "../../../status/Status";
import Loading from "../../../loading/Loading";

const ProjectMultipleStatus = ({ handleMultiple, loadProjectStatusList }) => {
  const projectData = useSelector((state) => state.project.projectData);
  const statusLoading = useSelector((state) => state.project.statusLoading);
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );
  const projectStageList = useSelector(
    (state) => state.project.projectStageList
  );
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElStage, setAnchorElStage] = useState(null);
  const [statusSelectedId, setStatusSelectedId] = useState();
  const [stageStatus, setStageStatus] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [stId, setStId] = useState();
  const stageLoading = useSelector((state) => state.project.stageLoading);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { id } = useParams();
  const loading = useSelector((state) => state.project.loading);

  const dispatch = useDispatch();

  const handleStatus = (event, statusId) => {
    event.preventDefault();
    setAnchorElStatus(event.currentTarget);
    setStatusSelectedId(statusId);
  };

  const handleStage = (event, status) => {
    event.preventDefault();
    setAnchorElStage(event.currentTarget);
    setStageStatus(status);

    dispatch(
      getProjectStageList({
        url: `crm/org/${orgId}/proj/status/${status?.statusId}/stages`,
        params: {},
      })
    );
  };

  const deleteStatus = (stsId) => {
    setDialogOpen(true);
    setStId(stsId);
  };

  const handleCloseStatusMenu = () => {
    setAnchorElStatus(null);
    setAnchorElStage(null);
  };

  const closeStatus = () => {
    dispatch(
      updateProjectStatus({
        url: `crm/org/${orgId}/proj/${id}/status/${stId}/close`,
        data: {},
        params: {},
      })
    );
    setDialogOpen(false);
  };

  const cancelClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (anchorElStatus) {
      loadProjectStatusList();
    }
  }, [anchorElStatus]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
      className=""
    >
      <Button
        onClick={handleMultiple}
        className="icon_button marginL10 d_flex align_items_center primary_bg text_white"
      >
        <AddIcon className="btn_icon" />
        Add Multiple Status
      </Button>
      {projectData?.status && (
        <div className="marginL20">
          <p>Status</p>
          {projectData?.status?.map((st, index) => (
            <div
              key={index}
              style={{
                border: "1px dashed rgb(24, 157, 61) ",
                padding: "10px",
              }}
              className="d_flex  align_items_center"
            >
              <div
                style={{
                  cursor: "pointer",
                  border: "1px dashed rgb(24, 157, 61) ",
                  borderRadius: "20px",
                  padding: "5px 10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "50%",
                }}
                onClick={(event) => handleStatus(event, st.statusId)}
              >
                <p style={{ fontSize: "14px", margin: 0 }}>{st?.statusName}</p>
                <ArrowDropDownIcon />
              </div>
              {!statusLoading && (
                <Menu
                  sx={{ mt: "30px" }}
                  style={{ height: "400px" }}
                  id="menu-appbar"
                  anchorEl={anchorElStatus}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElStatus)}
                  onClose={handleCloseStatusMenu}
                >
                  {projectStatusList?.map((status, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        // setStatus(status);
                        // setValue("statusId", status?.id);
                        const statusData = [
                          {
                            prevStatusId: statusSelectedId,
                            status: {
                              id: status?.id,
                              name: status?.name,
                              uid: status?.uid,
                              userName: status?.userName,
                            },
                          },
                        ];
                        dispatch(
                          updateProjectStatus({
                            url: `crm/org/${orgId}/proj/${id}/status`,
                            data: statusData,
                            params: {},
                          })
                        );
                        handleCloseStatusMenu();
                      }}
                      disabled={projectData?.status?.find(
                        (dis) => dis?.statusId === status?.id
                      )}
                    >
                      <Typography textAlign="center">
                        {status?.displayName}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              )}
              {st?.stageName && (
                <div className="marginL20 ">
                  <div
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={(event) => handleStage(event, st)}
                  >
                    <Status
                      status={st?.stageName}
                      statusType="success"
                      type="dropdown"
                    />
                  </div>
                  {!stageLoading && (
                    <Menu
                      sx={{ mt: "30px" }}
                      style={{ height: "400px" }}
                      id="menu-appbar"
                      anchorEl={anchorElStage}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElStage)}
                      onClose={handleCloseStatusMenu}
                    >
                      {projectStageList?.map((stage, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            // setStatus(stage);
                            // setValue("statusId", status?.id);
                            const stageData = {
                              prevStatusId: stageStatus.statusId,
                              status: {
                                id: stageStatus.statusId,
                                name: stageStatus.statusName,
                              },
                              stage: {
                                id: stage?.id,
                                name: stage?.name,
                              },
                            };

                            dispatch(
                              updateProjectStatus({
                                url: `crm/org/${orgId}/proj/${id}/stage`,
                                data: stageData,
                                params: {},
                              })
                            );
                            handleCloseStatusMenu();
                          }}
                        >
                          <Typography textAlign="center">
                            {stage?.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </div>
              )}
              {projectData?.status?.length > 1 && (
                <IconButton onClick={() => deleteStatus(st.statusId)}>
                  <Delete sx={{ color: red[500] }} />
                </IconButton>
              )}
            </div>
          ))}
        </div>
      )}
      <CustomDialog
        open={dialogOpen}
        title="Delete Status"
        message="Are you sure, you want delete this status ?"
        okText="Ok, delete"
        cancelText="Cancel"
        onOk={closeStatus}
        onCancel={cancelClose}
      />
    </div>
  );
};

export default ProjectMultipleStatus;
