import React from "react";
import Dropzone from "./dropzone/Dropzone";

function App({
  label,
  onChange,
  onDelete,
  // onIsPurchseOrder,
  showCheckbox,
  // progress,
}) {
  // State, browser FileReader and iterating

  return (
    <div className="drag_drop_fileupload">
      <p className="title">{label}</p>
      <div className="content">
        <Dropzone
          onChange={onChange}
          onDelete={onDelete}
          // onIsPurchseOrder={onIsPurchseOrder}
          showCheckbox={showCheckbox}
          // progress={progress}
        />
      </div>
    </div>
  );
}
export default App;
