import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import ChevronDown from "../../assets/images/chevrondown.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "../../assets/images/close";
import { useState } from "react";
import { switchOrg } from "../../redux/slices/orgSlice";

const MobileMore = ({ onClose }) => {
  const orgLoading = useSelector((state) => state.org.loading);
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const [showOrgDropdown, setShowOrgDropdown] = useState(null);
  const currentOrg = useSelector((state) => state.org.currentOrg);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <div className="modal_header">
        <Box className="header_org_dropdown" marginRight={"16px"}>
          {!orgLoading ? (
            orgInfo?.length > 0 ? (
              <>
                <Typography
                  className="header_org_name"
                  sx={{
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  paragraph={true}
                  onClick={(event) => setShowOrgDropdown(event.currentTarget)}
                >
                  {currentOrg?.name} <img src={ChevronDown} alt="" />
                </Typography>
                <Menu
                  sx={{ mt: "45px", width: { sm: "200px", md: "275px" } }}
                  id="menu-appbar"
                  anchorEl={showOrgDropdown}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(showOrgDropdown)}
                  onClose={() => setShowOrgDropdown(null)}
                >
                  {orgInfo.map((org, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        // setCurrentOrg(org);
                        dispatch(switchOrg(org));
                        setShowOrgDropdown(null);
                        navigate("/dashboard");
                      }}
                    >
                      <Typography title={org?.name} textAlign="center">
                        {org?.name?.length > 27
                          ? org?.name.substring(0, 27) + "..."
                          : org?.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
                to="/org/create"
              >
                <div className="d_flex align_items_center">
                  <AddCircleIcon
                    sx={{ color: "#189d3d", marginRight: "5px" }}
                  />
                  New Organization
                </div>
              </Link>
            )
          ) : (
            <>Loading...</>
          )}
        </Box>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default MobileMore;
