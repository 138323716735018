import { Box, Grid, IconButton } from "@mui/material";
import CloseIcon from "../../../../assets/images/close";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormState,
} from "react-hook-form";
import AutoCompleteAsync from "../../../../fields/autoComplete/AutoCompleteAsync";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewBom,
  editBom,
  getMaterialList,
} from "../../../../redux/slices/materialSlice";
import Storage from "../../../../utils/Storage";
import TextInput from "../../../../fields/text/TextInput";
import Button from "../../../buttons/Button";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddCircle } from "@mui/icons-material";
import MaterialForm from "../../../../pages/organisation/materials/MaterialForm";
import BasicModal from "../../../modal/Modal";
import { modalStyle } from "../../../constants/ConstData";

const schema = yup.object().shape({
  itemdetails: yup
    .array()
    .of(
      yup.object().shape({
        itemid: yup.number().required("Item ID is required"),
        itemuom: yup.string().required("Item UOM is required"),
        itemprice: yup.number().min(1, "Item price must be a positive number"),
        itemqty: yup.number().min(1, "Item quantity must be greater than 0"),
      })
    )
    .test(
      "uniqueItemid",
      "Same material cannot be selected again",
      (itemdetails) => {
        const itemIds = itemdetails.map((item) => item.itemid);
        return itemIds.length === new Set(itemIds).size;
      }
    ),
});

const BOMForm = ({ onClose, view, bomId, existingMaterials, type }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [openMaterial, setOpenMaterial] = useState([]);
  const [materialSelected, setMaterialSelected] = useState([]);
  const materialLoading = useSelector((state) => state.material.loading);
  const materialList = useSelector((state) => state.material.materials);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      itemdetails: [
        {
          itemid: null,
          itemuom: "",
          itemprice: 0,
          itemqty: 0,
        },
      ],
    },
  });
  const { dirtyFields } = useFormState({
    control,
  });

  const { fields, append, prepend, remove } = useFieldArray({
    name: "itemdetails",
    control,
  });

  const onSubmit = (data) => {
    console.log("errors=--------->", errors);

    console.log("BOMData=--------->", data);

    // data.itemid = data.material.id;
    // delete data.material;
    const newData = {
      itemdetails: data.itemdetails,
    };

    if (bomId && view === "edit") {
      newData.itemdetails.forEach((obj) => {
        obj["id"] = 0;
      });
      console.log("newData=--------->", newData);
      const baseUrl = `/crm/org/${orgId}/proj/${id}/bom`;
      const url =
        type === "adder" ? `${baseUrl}/adder/${bomId}` : `${baseUrl}/${bomId}`;
      dispatch(
        editBom({
          url: url,
          data: newData,
          params: {},
        })
      );
    } else {
      const baseUrl = `/crm/org/${orgId}/proj/${id}/bom`;
      const url = type === "adder" ? `${baseUrl}/adder` : baseUrl;
      dispatch(
        createNewBom({
          url: url,
          data: newData,
          params: {},
        })
      );
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleCloseMaterialForm = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (
      (openMaterial && materialList.length < 1) ||
      snackbarData?.createSuccess
    ) {
      dispatch(
        getMaterialList({ url: `/crm/org/${orgId}/bom/store`, params: {} })
      );
    }
  }, [openMaterial]);

  // useEffect(() => {
  //   if (editItem) {
  //     setValue("itemprice", editItem?.materials[0]?.price);
  //     setValue("itemuom", editItem?.materials[0]?.uom);
  //     setValue("itemqty", editItem?.materials[0]?.qty);
  //     setValue("itemid", editItem?.materials[0]?.maaterialid);
  //   }
  // }, [editItem]);

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">Add New</p>
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <form className="bom_form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{ maxHeight: "400px", overflowY: "scroll" }}
          className="modal_body"
        >
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name={`itemdetails.${index}.itemid`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <AutoCompleteAsync
                        {...field}
                        className="input_field_style"
                        name={`itemdetails.${index}.itemid`}
                        label="Material"
                        open={openMaterial[index]}
                        onOpen={() => {
                          const newOpenMaterial = openMaterial.splice(
                            index,
                            0,
                            true
                          );
                          setOpenMaterial(newOpenMaterial);
                        }}
                        onClose={() => {
                          const newOpenMaterial = openMaterial.splice(
                            index,
                            0,
                            false
                          );
                          setOpenMaterial(newOpenMaterial);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value.id
                        }
                        loading={materialLoading}
                        options={materialList}
                        onChange={(event, newValue, reason) => {
                          if (reason === "clear") {
                            console.log("newValue=---------->", newValue);
                            const newMaterial = materialSelected.splice(
                              index,
                              0,
                              null
                            );
                            setMaterialSelected(newMaterial);
                          } else {
                            const containsId = existingMaterials?.some(
                              (item) => item.materialid === newValue.id
                            );
                            if (containsId) {
                              setError(`itemdetails.${index}.itemid`, {
                                type: "manual",
                                message: "Material already added",
                              });
                            } else {
                              clearErrors(`itemdetails.${index}.itemid`);

                              const newMaterial = materialSelected.splice(
                                index,
                                0,
                                newValue.id
                              );
                              setMaterialSelected(newMaterial);
                              setValue(
                                `itemdetails.${index}.itemid`,
                                newValue.id
                              );
                              setValue(
                                `itemdetails.${index}.itemprice`,
                                newValue?.baseprice
                              );
                              setValue(
                                `itemdetails.${index}.itemuom`,
                                newValue?.baseuom
                              );
                            }
                          }
                        }}
                        renderOption={(props, option, { index }) => {
                          const isLastOption =
                            index === materialList.length - 1;
                          return (
                            <>
                              <Box style={{ fontSize: 13 }} {...props}>
                                {option.name}
                              </Box>
                              {isLastOption && (
                                <Button
                                  className="w_100 p_0  d_flex align_items_center outline_button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsOpen(true);
                                  }}
                                  leftIcon={<AddCircle />}
                                >
                                  Add New Material
                                </Button>
                              )}
                            </>
                          );
                        }}
                        value={materialSelected[index]}
                        getOptionLabel={(option) => {
                          if (option?.name) {
                            return option?.name;
                          } else {
                            return "Please Select";
                          }
                        }}
                        fieldSize="small"
                        getOptionDisabled={(option) =>
                          materialSelected.includes(option.id)
                        }
                      />
                    )}
                  />
                  {errors && errors?.itemdetails?.[index]?.itemid?.message && (
                    <p className="error_message">
                      {errors?.itemdetails[index]?.itemid?.message}
                    </p>
                  )}
                </Grid>
                {materialSelected && (
                  <>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <Controller
                        name={`itemdetails.${index}.itemprice`}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <TextInput
                            {...field}
                            variant="mui"
                            name="itemprice"
                            label="Price"
                            inputClass="input_field_style"
                            fieldSize="small"
                          />
                        )}
                      />
                      {errors &&
                        errors?.itemdetails?.[index]?.itemprice?.message && (
                          <p className="error_message">
                            {errors?.itemdetails[index]?.itemprice?.message}
                          </p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <Controller
                        name={`itemdetails.${index}.itemuom`}
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <TextInput
                            {...field}
                            variant="mui"
                            name="itemuom"
                            label="Unit"
                            inputClass="input_field_style"
                            // placeholder="Unit"
                            // register={{
                            //   ...register(`itemdetails.${index}.itemuom`, {
                            //     required: true,
                            //   }),
                            // }}
                            fieldSize="small"
                          />
                        )}
                      />
                      {errors &&
                        errors?.itemdetails?.[index]?.itemuom?.message && (
                          <p className="error_message">
                            {errors.itemdetails[index].itemuom?.message}
                          </p>
                        )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Controller
                    name={`itemdetails.${index}.itemqty`}
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        variant="mui"
                        name="itemqty"
                        label="Quantity"
                        inputClass="input_field_style"
                        // placeholder="Quantity"
                        // register={{
                        //   ...register(`itemdetails.${index}.itemqty`, {
                        //     required: true,
                        //   }),
                        // }}
                        fieldSize="small"
                      />
                    )}
                  />
                  {errors && errors?.itemdetails?.[index]?.itemqty?.message && (
                    <p className="error_message">
                      {errors.itemdetails[index].itemqty?.message}
                    </p>
                  )}
                </Grid>
              </Grid>
              {errors.itemdetails &&
                typeof errors.itemdetails.message === "string" && (
                  <p className="error_message">{errors.itemdetails.message}</p>
                )}
              <IconButton
                sx={{ backgroundColor: "transparent !important" }}
                // className={classes.customHoverFocus}
                disabled={fields.length === 1 ? true : false}
                aria-label="delete"
                onClick={() => remove(index)}
              >
                <DeleteIcon />
              </IconButton>
              <hr />
            </React.Fragment>
          ))}
          <div className="d_flex justify_content_end">
            <IconButton
              aria-label="adding"
              onClick={(e) => {
                e.preventDefault();
                append({
                  itemid: null,
                  itemuom: "",
                  itemprice: 0,
                  itemqty: 0,
                });
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal_footer">
          <Button type="submit" className="primary_button">
            Save
          </Button>
        </div>
      </form>
      <BasicModal
        isOpen={isOpen}
        onClose={handleCloseMaterialForm}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <MaterialForm
          modalView={true}
          onClose={handleCloseMaterialForm}
          orgId={orgId}
        />
      </BasicModal>
    </>
  );
};

export default BOMForm;
