export const COLUMN_NAMES_LIST = [
  { title: "Documents Signed", name: "document_signed", count: 3 },
  { title: "Projects Approved", name: "projects_approved", count: 2 },
  { title: "Site Survey", name: "site_survey", count: 0 },
  { title: "Electrical Review", name: "electrical_review", count: 2 },
  { title: "Design", name: "design", count: 0 },
  { title: "Utility Submitted", name: "utility_submitted", count: 0 },
  { title: "Utility Approved", name: "utility_approved", count: 0 },
  { title: "Permit Submitted", name: "permit_submitted", count: 0 },
  { title: "Permit Approved", name: "permit_approved", count: 0 },
  { title: "Ready To Install", name: "ready_to_install", count: 0 },
  { title: "Scheduled", name: "scheduled", count: 0 },
  { title: "Installation", name: "installation", count: 0 },
  { title: "Inspection", name: "inspection", count: 0 },
  { title: "PTO", name: "pto", count: 0 },
];
