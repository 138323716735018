import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../redux/slices/titleSlice";
import "./style.scss";
import { Grid } from "@mui/material";
import ToolBar from "../../components/toolbar/ToolBar";
import ProgressCard from "../../components/dashboard/ProgressCard";
import CustomerIcon from "../../assets/images/customers.svg";
import RevenueIcon from "../../assets/images/revenue.svg";
import ConversioIcon from "../../assets/images/conversion.svg";
import TotalDealsIcon from "../../assets/images/totaldeals.svg";
import InfoCard from "../../components/dashboard/InfoCard";
import DealersCard from "../../components/dashboard/DealersCard";
import DealsCard from "../../components/dashboard/DealsCard";
import RevenueAnalytics from "../../components/dashboard/RevenueAnalytics";
import ProjectStatistics from "../../components/dashboard/ProjectStatistics";
import ActivityCard from "../../components/dashboard/ActivityCard";
import Loading from "../../components/loading/Loading";
import Button from "../../components/buttons/Button";
import FilterIcon from "../../assets/images/filter";
import ExportIcon from "../../assets/images/export";
import { CardItem } from "../../utils/CardItem";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../components/dialog/CustomDialog";
import Storage from "../../utils/Storage";

const Dashboard = () => {
  const dispatch = useDispatch();

  const data = [{ name: "progress", value: 0 }];
  const userDetails = useSelector((state) => state.auth.userDetails);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const orgLoading = useSelector((state) => state.org.loading);
  const mobileVerify = Storage.read("mobileVerify");

  const lineData = [
    {
      name: "1",
      pv: 2400,
    },
    {
      name: "2",
      pv: 1398,
    },
    {
      name: "3",
      pv: 9800,
    },
    {
      name: "4",
      pv: 3908,
    },
  ];

  const handleCancel = () => {
    setDialogOpen(false);
    Storage.write("mobileVerify", false);
  };

  useEffect(() => {
    dispatch(setTitle("Dashboard"));
  }, []);

  useEffect(() => {
    if (!orgLoading && orgInfo && orgInfo.length === 0) {
      navigate("/org/create");
    }
  }, [orgInfo]);

  useEffect(() => {
    if (!mobileVerify && !userDetails?.phoneNumber) {
      Storage.write("mobileVerify", true);
    }
  }, [userInfo, userDetails]);

  useEffect(() => {
    if (mobileVerify) {
      setDialogOpen(true);
    }
  }, [mobileVerify]);

  return (
    <div className="dashboard">
      <ToolBar
        title={`Welcome ${userDetails.displayName} !`}
        subTitle="Track your sales activity, leads and deals here."
      >
        <div className="d_flex align_items_center">
          <Button
            type="submit"
            className="primary_button d_flex align_items_center marginR10"
            leftIcon={<FilterIcon color="#fff" />}
          >
            Filters
          </Button>
          <Button
            type="submit"
            className="primary_button d_flex align_items_center outline_button"
            leftIcon={<ExportIcon color="#189D3D" />}
          >
            Export
          </Button>
        </div>
      </ToolBar>
      <Grid sx={{ marginTop: "16px" }} container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <CardItem height="200px">
            <ProgressCard data={data} />
          </CardItem>
          <CardItem height="420px">
            <DealersCard />
          </CardItem>
          <CardItem height="420px">
            <DealsCard />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CardItem height="200px">
                <InfoCard
                  id="info_one"
                  icon={CustomerIcon}
                  title="Total Customers"
                  count="_ _ _"
                  data={lineData}
                  percent="_%"
                  percentColor="#189d3d"
                  gradient={{ color1: "#422D70", color2: "#845ADF" }}
                />
              </CardItem>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CardItem height="200px">
                <InfoCard
                  id="info_two"
                  icon={RevenueIcon}
                  title="Total Revenue"
                  count="$_ _ _"
                  data={lineData}
                  percent="_%"
                  percentColor="#23B7E5"
                  gradient={{ color1: "#125C73", color2: "#23B7E5" }}
                />
              </CardItem>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CardItem height="200px">
                <InfoCard
                  id="info_three"
                  icon={ConversioIcon}
                  title="Conversion Ratio"
                  count="_ _ _%"
                  data={lineData}
                  percent="_%"
                  percentColor="#26BF94"
                  gradient={{ color1: "#13604A", color2: "#26BF94" }}
                />
              </CardItem>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CardItem height="200px">
                <InfoCard
                  id="info_four"
                  icon={TotalDealsIcon}
                  title="Total Deals"
                  count="_ _ _"
                  data={lineData}
                  percent="_%"
                  percentColor="#F5B849"
                  gradient={{ color1: "#7B5C25", color2: "#F5B849" }}
                />
              </CardItem>
            </Grid>
          </Grid>
          <CardItem height="640px">
            <RevenueAnalytics />
          </CardItem>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={6} lg={4}> 
        
        </Grid> */}

        <Grid item xs={12} sm={12} md={12} lg={9}>
          <CardItem height="478px" showscrollbar="hidden">
            <ProjectStatistics />
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          <CardItem height="478px" showscrollbar="auto">
            <ActivityCard showTitle={true} />
          </CardItem>
        </Grid>
      </Grid>
      <CustomDialog
        open={dialogOpen}
        title="Add Mobile"
        message="You may not get your mobile notification until you add and verify your mobile number"
        okText="Add Mobile Number"
        cancelText="I will do later"
        onOk={() => navigate("/user/profile")}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default Dashboard;
