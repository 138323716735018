import { Breadcrumbs } from "@mui/material";

const CustomBreadcrumbOrg = ({ breadcrumbs }) => {
  return (
      
    <div className="">
      <Breadcrumbs
        sx={{
          color: "black",
          fontFamily: "Inter",
          fontSize: "16px",
          fontWeight: "400",
        }}
        separator=">>"
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadcrumbOrg;
