import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import titleReducer from "./slices/titleSlice";
import searchReducer from "./slices/searchSlice";
import authReducer from "./slices/authSlice";
import modalReducer from "./slices/modalSlice";
import projectReducer from "./slices/projectSlice";
import orgReducer from "./slices/orgSlice";
import leadReducer from "./slices/leadSlice";
import formReducer from "./slices/formSlice";
import appointmentReducer from "./slices/appointmentSlice";
import commentReducer from "./slices/commentSlice";
import dialogReducer from "./slices/dialogSlice";
import feedbackReducer from "./slices/feedbackSlice";
import fileUploadReducer from "./slices/fileUploadSlice";
import materialReducer from "./slices/materialSlice";
import profileReducer from "./slices/profileSlice";
import userReducer from "./slices/userSlice";
import groupReducer from "./slices/groupSlice";
import additionalInfoReducer from "./slices/additionalInfoSlice";

import rootSaga from "./sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    title: titleReducer,
    search: searchReducer,
    auth: authReducer,
    modal: modalReducer,
    project: projectReducer,
    org: orgReducer,
    lead: leadReducer,
    form: formReducer,
    appointment: appointmentReducer,
    upload: fileUploadReducer,
    comment: commentReducer,
    dialog: dialogReducer,
    feedback: feedbackReducer,
    material: materialReducer,
    profile: profileReducer,
    user: userReducer,
    group: groupReducer,
    additionalInfo: additionalInfoReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
