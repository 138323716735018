import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreateSnackbar,
  setCreateSuccess,
} from "../../redux/slices/formSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
  const [open, setOpen] = React.useState(false);
  const createData = useSelector((state) => state.form.createData);
  const snackbarData = useSelector((state) => state.form.snackbarData);

  const dispatch = useDispatch();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const severity = {
    201: "success",
    600: "warning",
    404: "error",
  };

  React.useEffect(() => {
    if (createData?.message || snackbarData?.message) {
      setOpen(true);
      // dispatch(setCreateSuccess(null));
    }
  }, [createData, snackbarData]);

  React.useEffect(() => {
    if (!open) {
      dispatch(setCreateSuccess(null));
      dispatch(setCreateSnackbar(null));
    }
  }, [open]);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={
            severity[createData?.statusCode || snackbarData?.statusCode]
          }
          sx={{ width: "100%" }}
        >
          {createData?.message || snackbarData?.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
