import React, { useEffect } from "react";
import Talk from "talkjs";
import useQuery from "../../utils/usequery";

const CustomerChat = () => {
  const query = useQuery();
  const customerName = query.get("customerName");
  // const customerEmail = query.get("customerEmail");
  const customerId = query.get("customerId");
  const conversationId = query.get("conversationId");

  useEffect(() => {
    // Initialize TalkJS
    // const talkjsConfig = {
    //   appId: process.env.REACT_APP_CHAT_APP_ID,
    // };
    Talk.ready.then(() => {
      const me = new Talk.User({
        id: customerId, // Unique identifier for the user
        name: customerName,
        // email: customerEmail, // Optional: provide email for better identification
        // photoUrl: "https://example.com/avatar.png", // Optional: provide user avatar
        role: "customer",
      });

      if (!window.talkSession) {
        window.talkSession = new Talk.Session({
          appId: process.env.REACT_APP_CHAT_APP_ID,
          me: me,
        });
      }

      // Create or fetch conversation based on your logic
      const conversation =
        window.talkSession.getOrCreateConversation(conversationId);

      const chatbox = window.talkSession.createChatbox(conversation);

      //   chatboxRef.current = chatbox;

      // Set up TalkJS chatbox in a DOM element

      chatbox.mount(document.getElementById("talkjs-container"));
    });
  }, []);

  return <div id="talkjs-container" style={{ height: "100%" }} />;
};

export default CustomerChat;
