import { Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GroupForm from "./GroupForm";
import { useNavigate } from "react-router-dom";

const CreateGroup = () => {
  const navigate = useNavigate();

  return (
    <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <h3>New Group</h3>
        </div>
        <GroupForm />
      </div>
    </div>
  );
};

export default CreateGroup;
