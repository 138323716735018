import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import ResponsiveAppBar from "../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/loading/Loading";
import SimpleBottomNavigation from "../components/sidebar/SimpleBottomNavigation";
import BasicModal from "../components/modal/Modal";
import { modalStyle } from "../components/constants/ConstData";
import { setMinimize, setModal } from "../redux/slices/modalSlice";
import FileUploadForm from "../components/project/view/fileupload/FileUploadForm";
import { Padding } from "@mui/icons-material";
import { setCreateSnackbar } from "../redux/slices/formSlice";
import CircularProgress from '@mui/material/CircularProgress';
const Layout = () => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const loading = useSelector((state) => state.auth.loading);
  let location = useLocation();
  const isOpen = useSelector((state) => state.modal.open);
  const uploadDetails = useSelector((state) => state.modal.uploadDetails);
  const modalMinmize = useSelector((state) => state.modal.modalMinmize);
  const createData = useSelector((state) => state.form.createData);
  const openDownloadModal = useSelector((state) => state.modal.openDownloadModal);


  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setModal({ open: false, uploadDetails: null }));
    dispatch(setMinimize(false));
  };

  React.useEffect(() => {
    if (createData?.createSuccess) {
      handleClose();
    }
  }, [createData]);

  return (
    <>
      <div className="d_flex h_100">
        <Sidebar />
        <div className="content_wrapper">
          <ResponsiveAppBar />
          <Outlet />
          <SimpleBottomNavigation />
          <BasicModal
            boxStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              height: "500px",
              display: "flex",
              flexDirection: "column",
            }}
            isOpen={isOpen}
            onClose={handleClose}
            sx={
              modalMinmize
                ? {
                    // width: "20%",
                    height: "50px",
                    ".MuiBox-root": {
                      transform: "translate(300px, 650px)",
                      height: "50px",
                      width: "20%",
                    },
                    ".modal_header": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 10px",
                    },

                    ".modal_body": {
                      display: "none",
                    },
                    ".project_form": {
                      display: "none",
                    },
                    ".modal_footer": {
                      display: "none",
                    },
                  }
                : modalStyle
            }
            hideBackdrop={true}
          >
            <FileUploadForm
              onClose={handleClose}
              title={uploadDetails?.title}
              type={uploadDetails?.type}
              typeId={uploadDetails?.typeId}
              folderId={uploadDetails?.folderId}
            />
          </BasicModal>
          <BasicModal
            boxStyle={{
              position: "absolute",
              top: "50%",
            //  right: "10%",
              left:"50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              height: "500px",
              display: "flex",
              flexDirection: "column",
            }}
            isOpen={openDownloadModal}
            onClose={handleClose}
            sx={
              {
                    // width: "20%",
                    height: "50px",
                    ".MuiBox-root": {
                      transform: "translate(300px, 650px)",
                      height: "50px",
                      width: "20%",
                    },
                    ".modal_header": {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 10px",
                    },

                    ".modal_body": {
                      display: "none",
                    },
                    ".project_form": {
                      display: "none",
                    },
                    ".modal_footer": {
                      display: "none",
                    },
                  }
                
            }
            hideBackdrop={true}
          >
            <div style={{padding:"5px"}}>
              <div className="d_flex justify_content_between">
                <div ><b>Download in progress..Do not refresh..</b></div>
                <div><CircularProgress/></div>
              </div>
            </div>
          </BasicModal>
        </div>
      </div>
    </>
  );
};

export default Layout;
