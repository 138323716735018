import ProjectImage from "../../../assets/images/project.jpeg";

export const tasks = [
  {
    id: 1,
    name: "Project 1",
    date: "July 5, 2023",
    imageUrl: ProjectImage,
    column: "document_signed",
    statusType: "success",
    status: "accepted",
  },
  {
    id: 2,
    name: "Project 1",
    date: "July 5, 2023",
    imageUrl: ProjectImage,
    column: "document_signed",
    statusType: "error",
    status: "rejected",
  },
  {
    id: 3,
    name: "Project 1",
    date: "July 5, 2023",
    imageUrl: ProjectImage,
    column: "document_signed",
    statusType: "success",
    status: "active",
  },
  {
    id: 4,
    name: "Project 1",
    date: "July 5, 2023",
    imageUrl: ProjectImage,
    column: "electrical_review",
    statusType: "success",
    status: "accepted",
  },
  {
    id: 5,
    name: "Project 1",
    date: "July 5, 2023",
    imageUrl: ProjectImage,
    column: "projects_approved",
    statusType: "warning",
    status: "pending",
  },
  {
    id: 6,
    name: "Project 1",
    date: "July 5, 2023",
    imageUrl: ProjectImage,
    column: "projects_approved",
    statusType: "error",
    status: "rejected",
  },
  {
    id: 7,
    name: "Project 1",
    date: "July 5, 2023",
    imageUrl: ProjectImage,
    column: "electrical_review",
    statusType: "success",
    status: "accepted",
  },
];
