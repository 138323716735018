import DeveloperBoardOffIcon from "@mui/icons-material/DeveloperBoardOff";

const EmptyList = ({ message, icon, className }) => {
  return (
    <div
      className={
        className ??
        "d_flex flex_column align_items_center justify_content_center w_100"
      }
    >
      {icon}
      <p>{message}</p>
    </div>
  );
};

export default EmptyList;
