import { deleteData, getData, postData, putData } from "../api";

const createItem = async ({ url, data, params }) => {
  return await postData({
    url,
    data,
    params,
  });
};

const editItem = async ({ url, data, params }) => {
  return await putData({
    url,
    data,
    params,
  });
};

const getItem = async ({ url, params }) => {
  return await getData({
    url: url,
    params,
    headers: {},
  });
};

const getPropertyImage = async ({ url, params }) => {
  return await getData({
    url: url,
    params,
    headers: {
      "X-RapidAPI-Key": process.env.REACT_APP_X_RAPIDAPI_KEY,
      "X-RapidAPI-Host": process.env.REACT_APP_X_RAPIDAPI_HOST,
    },
  });
};

const uploadItem = async ({ url, data, params, onUploadProgress }) => {
  return await postData({
    url,
    timeout: 0,
    data,
    params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      onUploadProgress(progress);
    },
  });
};

const deleteItem = async ({ url, data, params }) => {
  return await deleteData({
    url,
    data,
    params,
  });
};

export {
  createItem,
  editItem,
  getItem,
  getPropertyImage,
  uploadItem,
  deleteItem,
};
