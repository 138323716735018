import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import EmailChipInput from "./EmailChipInput";
import { getOrgUserList } from "../../redux/slices/orgSlice";
import { Avatar, IconButton, Typography } from "@mui/material";
import { stringAvatar } from "../../utils/util";
import { useEffect } from "react";
import Storage from "../../utils/Storage";

const CustomEmailChip = ({ onChange, value }) => {
  const orgUserLoading = useSelector((state) => state.org.orgUserLoading);
  const orgUsers = useSelector((state) => state.org.orgUsers);
  const userDetails = JSON.parse(sessionStorage.getItem("user"));
  const [inviteeList, setInviteeList] = React.useState([
    {
      name: userDetails?.name,
      email: userDetails?.email,
      mobile: userDetails?.mobile,
      userId: userDetails?.id,
    },
  ]);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const [userList, setUserList] = React.useState(orgUsers);
  const [userSelected, setUserSelected] = React.useState();

  const dispatch = useDispatch();

  // const [items, setItems] = React.useState([userDetails?.email]);
  const [emailValue, setEmailValue] = React.useState("");
  const [error, setError] = React.useState(null);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      var newValue = emailValue?.trim();

      if (newValue && isValid(newValue)) {
        // setItems([...items, emailValue]);
        setInviteeList([
          ...inviteeList,
          { name: "", email: emailValue, mobile: "", userId: null },
        ]);

        setEmailValue("");
      }
    }
  };

  const handleChange = (evt) => {
    setEmailValue(evt.target.value);
    setError(null);
    if (evt.target.value !== "") {
      const filteredData = userList?.filter((element) => {
        if (element.email !== userDetails?.email)
          return element.email
            .toLowerCase()
            .includes(evt.target.value.toLowerCase());
      });
      setUserSelected(filteredData);
    } else {
      setUserSelected([]);
    }
  };

  const onDelete = (item) => {
    // setItems(items.filter((i) => i !== item));
    const deleted = orgUsers.filter((el) => el.email === item);
    setUserList([...userList, deleted[0]]);
    setInviteeList(inviteeList.filter((li) => li.email !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails
        .filter((email) => !isInList(email))
        .map((item) => {
          return { name: "", email: item, mobile: "", userId: null };
        });
      // setItems([...items, ...toBeAdded]);
      setInviteeList([...inviteeList, toBeAdded]);
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    const items = inviteeList.map((item) => item.email);
    return items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const handleFocus = (evt) => {
    setAnchorElUser(evt.currentTarget);
  };

  const handleBlur = () => {
    setUserSelected([]);
    setAnchorElUser(false);
  };

  React.useEffect(() => {
    if (!orgUsers && anchorElUser) {
      dispatch(
        getOrgUserList({
          url: `crm/user/organization/${orgId}/users`,
          params: {},
        })
      );
    }
  }, [anchorElUser]);

  React.useEffect(() => {
    if (orgUsers) setUserList(orgUsers);
  }, [orgUsers]);

  React.useEffect(() => {
    onChange(inviteeList);
  }, [inviteeList]);

  // useEffect(() => {
  //   if (userDetails) {
  //     setInviteeList([
  //       ...inviteeList,
  //       {
  //         name: userDetails?.name,
  //         email: userDetails?.email,
  //         mobile: userDetails?.mobile,
  //         userId: userDetails?.id,
  //       },
  //     ]);
  //   }
  // }, []);
  useEffect(() => {
    if (value?.length > 0) {
      setInviteeList(value);
    }
  }, [value]);

  return (
    <div className="marginT10">
      <EmailChipInput
        placeholder="Add Guests"
        value={emailValue}
        error={error}
        onKeyDown={handleKeyDown}
        //   onSelect={handleGuest}
        onChange={handleChange}
        onPaste={handlePaste}
        //   onBlur={handleBlur}
        onFocus={handleFocus}
      />

      {!orgUserLoading && (
        <ul className="invitee_list">
          {userSelected?.map((user, index) => (
            <li
              key={user.userId}
              className="invitee_listitem"
              onClick={() => {
                setInviteeList([...inviteeList, user]);
                const filtered = userList.filter(
                  (item) => item.userId !== user.userId
                );
                setUserList(filtered);
                setEmailValue("");
                handleBlur();
              }}
            >
              <IconButton sx={{ p: 0, marginRight: "8px" }}>
                <Avatar
                  style={{ width: "30px", height: "30px" }}
                  {...stringAvatar(user?.name)}
                />
              </IconButton>
              <div className="">
                <Typography>{user?.name}</Typography>
                <Typography variant="caption">{user?.email}</Typography>
              </div>
            </li>
          ))}
        </ul>
      )}

      {inviteeList.length > 1 &&
        inviteeList.map((item, index) => (
          <div className="tag-item" key={index}>
            {item.email}
            {item.email !== userDetails?.email && (
              <button
                type="button"
                className="button"
                onClick={() => onDelete(item.email)}
              >
                &times;
              </button>
            )}
          </div>
        ))}
    </div>
  );
};

export default CustomEmailChip;
