import { Breadcrumbs } from "@mui/material";

const CustomBreadcrumb = ({ breadcrumbs }) => {
  return (
      
    <div className="marginB10">
      <Breadcrumbs
        sx={{
          color: "#B0B0B0",
          fontFamily: "Inter",
          fontSize: "16px",
          fontWeight: "400",
        }}
        separator=">>"
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </div>
  );
};

export default CustomBreadcrumb;
