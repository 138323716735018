import * as React from "react";
import { Delete, Edit } from "@mui/icons-material";
import CloseIcon from "../../assets/images/close";
import { IconButton, Typography } from "@mui/material";
import DateView from "../../components/date/DateView";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import NotesIcon from "@mui/icons-material/Notes";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PlaceIcon from "@mui/icons-material/Place";
import BasicModal from "../../components/modal/Modal";
import AppointmentForm from "../../components/project/view/appointment/AppointmentForm";
import { modalStyle } from "../../components/constants/ConstData";
import { useState } from "react";
import {
  cancelAppointment,
  editAppointment,
} from "../../redux/slices/appointmentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Storage from "../../utils/Storage";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { orange, yellow } from "@mui/material/colors";
import Button from "../../components/buttons/Button";
import dayjs from "dayjs";

const EventView = ({ eventItem, onClose, type, view, code }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [formValue, setFormValue] = useState({});
  const dispatch = useDispatch();
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const appointmentList = useSelector(
    (state) => state.appointment.appointmentList
  );
  const [completeTask, setCompleteTask] = useState(false);

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const { id } = useParams();

  const updateFormValue = () => {
    setFormValue({
      startDate: eventItem?.appointmentData?.start,
      startTime: eventItem?.appointmentData?.start,
      endTime: eventItem?.appointmentData?.end,
      endDate: eventItem?.appointmentData?.end,
      subject: eventItem?.appointmentData?.title,
      allDay: eventItem?.appointmentData?.allDay === "true" ? true : false,
      inviteeList: eventItem?.appointmentData?.invitees,
      description: eventItem?.appointmentData?.description,
      location: eventItem?.appointmentData?.location,
      typeId: eventItem?.appointmentData?.projectId,
      appointmentId: eventItem?.appointmentData?.appointmentId,
      organizerEmail: eventItem?.appointmentData?.organizerEmail,
      organizerName: eventItem?.appointmentData?.organizerName,
      status: eventItem?.status,
      isOnline: eventItem?.appointmentData?.isOnline,
      isTask: eventItem?.appointmentData?.isTask,
    });
  };

  const handleEdit = () => {
    setIsOpen(true);
    console.log("EventItem=--------->", eventItem);
    updateFormValue();
  };

  const completedTask = () => {
    updateFormValue();
    setCompleteTask(!completeTask);
  };

  React.useEffect(() => {
    if (completeTask) {
      console.log("FormValue=------->", formValue);
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

      const newData = {};
      newData.subject = formValue.subject;
      newData.organizerName = formValue.organizerName;
      newData.organizerEmail = formValue?.organizerEmail;
      newData.description = formValue.description;
      newData.startDate = formValue?.allDay
        ? formValue.startDate
        : formValue.startTime;
      newData.endDate = formValue?.allDay
        ? formValue.endDate
        : formValue.endTime;
      newData.fullDay = formValue?.allDay;
      newData.invitees =
        formValue?.inviteeList?.length > 1 ? formValue?.inviteeList : [];
      newData.statusId = 9;
      newData.location = formValue?.isOnline ? "" : formValue?.description;
      newData.timeZone = timeZone;
      newData.stStatusid = formValue?.status?.statusId;
      newData.isOnline = formValue?.isOnline;
      newData.isTask = 1;

      newData.closedDate = new Date();
      const testData = { ...newData, ics: newData };
      dispatch(
        editAppointment({
          url: `crm/org/${orgId}/${type}/${id}/appointment/${formValue?.appointmentId}`,
          data: testData,
          params: {},
        })
      );
    }
  }, [completeTask]);

  const appointmentCancel = () => {
    const newData = {};

    newData.subject = eventItem?.appointmentData?.title;
    newData.organizerEmail = eventItem?.appointmentData?.organizerEmail;
    newData.organizerName = eventItem?.appointmentData?.organizerName;
    newData.description = eventItem?.appointmentData?.description;
    newData.startDate = eventItem?.appointmentData?.start;
    newData.endDate = eventItem?.appointmentData?.end;
    newData.fullDay = eventItem?.appointmentData?.allDay;
    newData.invitees = eventItem?.appointmentData?.invitees;
    newData.statusId = 10;
    newData.location = eventItem?.appointmentData?.location;

    const testData = { ...newData, ics: newData };

    console.log("eventItem?.appointmentId=-------->", eventItem?.appointmentId);
    console.log("eventItem=-------->", eventItem);

    dispatch(
      cancelAppointment({
        url: `crm/org/${orgId}/${type}/${id}/appointment/${eventItem?.appointmentData?.appointmentId}/cancel`,
        data: testData,
        params: {},
      })
    );
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="modal_header">
        <p className="modal_title">Appointment</p>
        <div className="modal_header_button_wrapper">
          {code === 1 ||
            code === 2 ||
            (view !== "calendar" && (
              <>
                <IconButton onClick={handleEdit}>
                  <Edit />
                </IconButton>
                <IconButton onClick={appointmentCancel}>
                  <Delete />
                </IconButton>
              </>
            ))}

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className="modal_body">
        <div className="d_flex w_100">
          <ViewDayIcon color="success" />
          <div className="marginL10">
            <div className="d_flex align_items_center">
              <h3 className="m_0 marginR10">
                {eventItem?.appointmentData?.title}
              </h3>

              {eventItem?.appointmentData?.isTask === 1 ? (
                <ChecklistIcon sx={{ color: orange[500] }} />
              ) : (
                <EventAvailableIcon color="primary" />
              )}
            </div>
            <p className="m_0">
              <DateView
                date={eventItem?.appointmentData?.start}
                format={
                  eventItem?.appointmentData?.allDay === "true"
                    ? "MMMM D, YYYY"
                    : "dddd, MMMM D, YYYY h:mm A"
                }
              />
              <b className="marginR10 marginL10">-</b>

              <DateView
                date={eventItem?.appointmentData?.end}
                format={
                  eventItem?.appointmentData?.allDay === "true"
                    ? "MMMM D, YYYY"
                    : "h:mm A"
                }
              />
            </p>
            {code === 0 &&
              eventItem?.appointmentData?.isTask === 1 &&
              !dayjs().isBefore(dayjs(eventItem?.appointmentData?.end)) && (
                <Typography className="" color="red">
                  due
                </Typography>
              )}
          </div>
        </div>

        {eventItem?.appointmentData?.invitees?.length > 0 && (
          <div className="d_flex">
            <PeopleAltIcon color="success" />
            <div className="marginL10 marginB10">
              <h5 className="m_0">
                {eventItem?.appointmentData?.invitees?.length} Guests
              </h5>
              {eventItem?.appointmentData?.invitees?.map((guest, index) => (
                <div key={index} className="d_flex marginT10">
                  <AccountCircleIcon color="disabled" />

                  <p className="marginL10 m_0">
                    <small>{guest.email}</small>
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}
        {eventItem?.appointmentData?.description && (
          <div className="d_flex marginT10 marginB10">
            <NotesIcon color="success" />
            <p className="marginL10 m_0">
              {eventItem?.appointmentData?.description}
            </p>
          </div>
        )}
        {eventItem?.appointmentData?.organizer && (
          <div className="d_flex marginT10">
            <PermContactCalendarIcon color="success" />
            <p className="marginL10 m_0">
              {eventItem?.appointmentData?.organizer.name ??
                eventItem?.appointmentData?.organizer.email}
            </p>
          </div>
        )}
        {eventItem?.appointmentData?.location && (
          <div className="d_flex marginT10">
            <PlaceIcon color="success" />
            <p className="marginL10 m_0 label_text">
              {eventItem?.appointmentData?.location}
            </p>
          </div>
        )}
        {code === 0 && eventItem?.appointmentData?.isTask === 1 && (
          <Button onClick={completedTask} className="outline_button marginT10">
            Mark as completed
          </Button>
        )}
      </div>
      <BasicModal
        isOpen={isOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <AppointmentForm
          value={formValue}
          type={type}
          onClose={handleClose}
          view="edit"
        />
      </BasicModal>
    </>
  );
};

export default EventView;
