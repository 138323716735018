import { useState, useRef } from "react";
import {
  KnockProvider,
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
} from "@knocklabs/react";

// Required CSS import, unless you're overriding the styling
import "@knocklabs/react/dist/index.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const KnockNotification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const notifButtonRef = useRef(null);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const navigate = useNavigate();

  return (
    <KnockProvider
      apiKey={"pk_test_p89jCMNXvV7NNpyzKgdtY6yWlTi33hQEN5AM99JlOsU"}
      userId={userDetails?.uid}
      // In production, you must pass a signed userToken
      // and enable enhanced security mode in your Knock dashboard
      // userToken={currentUser.knockUserToken}
    >
      <KnockFeedProvider feedId={"449fd0df-2fb1-443d-91d0-2c79c8d8d0c4"}>
        <>
          <NotificationIconButton
            ref={notifButtonRef}
            onClick={(e) => setIsVisible(!isVisible)}
          />
          <NotificationFeedPopover
            buttonRef={notifButtonRef}
            isVisible={isVisible}
            onClose={() => setIsVisible(false)}
            onNotificationClick={(item) => {
              if (item?.data?.projectId) {
                console.log("DATA=------>", item?.data);

                navigate(`/projects/${item?.data?.projectId}`);
                setIsVisible(false);
              }
            }}
          />
        </>
      </KnockFeedProvider>
    </KnockProvider>
  );
};

export default KnockNotification;
