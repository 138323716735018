import { useEffect, useState } from "react";
import AutoCompleteAsync from "./AutoCompleteAsync";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupList,
  getGroupUserList,
  setGroupUserList,
} from "../../redux/slices/orgSlice";
import { Box } from "@mui/material";
import Storage from "../../utils/Storage";

const AutoCompleteGroupNew = ({
  name,
  type,
  label,
  // register,
  //   onChange,
  onGroupChange,
  onUserChange,
  variant,
  value,

  view,
  userLabel,
  groupWidth,
  userWidth,
}) => {
  const [openGroup, setOpenGroup] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  const [groupSelected, setGroupSelected] = useState(
    value ? { name: value?.groupName, id: value?.groupId } : null
  );
  const [userSelected, setUserSelected] = useState(
    value ? { name: value?.name, id: value?.id, uid: value?.uid } : null
  );
  const dispatch = useDispatch();
  const group = useSelector((state) => state.org.group);
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const loading = useSelector((state) => state.org.groupLoading);

  const Users = useSelector((state) => state.org.Users);

  useEffect(() => {
    if (openGroup) {
      dispatch(
        getGroupList({
          url: `crm/user/groups/organization/${orgId}`,
          params: {},
        })
      );
    }
  }, [openGroup]);

  const loadUser = (id) => {
    dispatch(
      getGroupUserList({
        url: `crm/user/groups/${id}/users`,
        params: {},
      })
    );
  };

  useEffect(() => {
    dispatch(setGroupUserList([]));
    if (openUser && groupSelected?.id) {
      loadUser(groupSelected?.id);
    }
  }, [groupSelected?.id, openUser]);

  //   useEffect(() => {
  //     onChange(
  //       {
  //         userId: userSelected?.id,
  //         name: userSelected?.name,
  //         uid: userSelected?.uid,
  //         groupId: groupSelected?.id,
  //         groupName: groupSelected?.name,
  //       },
  //       type
  //     );
  //   }, [groupSelected, userSelected]);

  //   useEffect(() => {
  //     if (value) {
  //       if (view === "leadview") {
  //         onChange(
  //           {
  //             userId: value?.userId,
  //             name: value?.name,
  //             uid: value?.uid,
  //             groupId: value?.groupId,
  //             groupName: value?.groupName,
  //           },
  //           type
  //         );
  //       } else {
  //         setGroupSelected({ name: value?.groupName, id: value?.groupId });
  //         setUserSelected({
  //           name: value?.name,
  //           id: value?.userId,
  //           uid: value?.uid,
  //         });
  //       }
  //     }
  //   }, [value]);

  return (
    <>
      <label className="text_medium">{label}</label>
      <AutoCompleteAsync
        sx={{
          width: groupWidth ?? "100%",
          marginBottom: "10px",
          marginTop: "10px",
          marginRight: "20px",
        }}
        name={name}
        label="Group"
        open={openGroup}
        onOpen={() => {
          setOpenGroup(true);
        }}
        onClose={() => {
          setOpenGroup(false);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        loading={loading}
        options={group}
        onChange={onGroupChange}
        // onChange={(event, newValue, reason) => {
        //   if (reason === "clear") {
        //     setGroupSelected(null);
        //   }
        //   setGroupSelected(newValue);
        //   setUserSelected(null);
        // }}
        // register={register}
        renderOption={(props, option) => (
          <Box style={{ fontSize: 13 }} {...props}>
            {option.name}
          </Box>
        )}
        value={groupSelected}
        getOptionLabel={(option) => {
          if (option.name) {
            return option?.name;
          } else {
            return "Please Select";
          }
        }}
        variant={variant}
      />
      {groupSelected && groupSelected?.id !== null && (
        <AutoCompleteAsync
          sx={{
            width: userWidth ?? "100%",
            marginBottom: "10px",
            marginTop: "10px",
          }}
          name={`user${name}`}
          label={userLabel ?? "User"}
          open={openUser}
          onOpen={() => {
            setOpenUser(true);
          }}
          onClose={() => {
            setOpenUser(false);
          }}
          isOptionEqualToValue={(option, value) => option?.id === value.id}
          loading={loading}
          options={Users}
          onChange={onUserChange}
          //   onChange={(event, newValue, reason) => {
          //     if (reason === "clear") {
          //       setUserSelected(null);
          //     }
          //     setUserSelected(newValue);
          //   }}
          // register={register}
          renderOption={(props, option) => (
            <Box style={{ fontSize: 13 }} {...props}>
              {option.name}
            </Box>
          )}
          value={userSelected}
          getOptionLabel={(option) => {
            if (option && option?.name !== null) {
              return option?.name;
            } else {
              return "Please Select";
            }
          }}
          variant={variant}
        />
      )}
    </>
  );
};

export default AutoCompleteGroupNew;
