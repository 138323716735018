import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../../components/buttons/Button";
import CardTitle from "../../../components/dashboard/CardTitle";
import DataTable from "../../../components/table/DataTable";
import { getUsersInGroup, removeUsersFromGroup } from "../../../redux/slices/groupSlice";
import { CardItem } from "../../../utils/CardItem";
import CustomBreadcrumbOrg from "./CustomBreadCrumbOrg";
const columns = [
    {
      field: "id",
      headerName: "User Id",
      width: 80,
    },
    {
      field: "mame",
      headerName: "User Name",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.name}</Typography>;
      },
    },
  
    {
      field: "email",
      headerName: "Email",
      width: 300,
      renderCell: (params) => <Typography>{params?.row?.email}</Typography>,
    },
    {
      field: "user_type",
      headerName: "User Type",
      width: 300,
      renderCell: (params) => {
        let userType='User';
        if(params.row.isowner==='true')userType='Owner'
        else if(params?.row?.isModerator=='true')userType='Moderator'
  
        return (
        <Typography>{userType}</Typography>
  
        )
      },
    },
  ];
const GroupsViewCard = ({ groupDetails, goBack,orgId }) => {
    const [rowsSelected, setRowsSelected] = useState([]);
  const dispatch = useDispatch();
  const groupUsers = useSelector((state) => state.group.usersInGroup);
  const createData = useSelector((state) => state.form.createData);
  const breadcrumbs = [
    <Link key="1" onClick={goBack}>
      <b>{groupDetails.name}</b>
    </Link>,
    <Typography key="2" color="text.primary">
      Users
    </Typography>,
  ];
  const getUsers = () => {
    console.log("inside get users");
    dispatch(
      getUsersInGroup({
        url: `crm/user/groups/${groupDetails.id}/users`,
        params: {},
      })
    );
  };
  const removeUser=()=>{
    let usersToRemove=rowsSelected.map((item,index)=>{
      return {
        id:groupUsers.filter(row=>row.id==item)[0].grpmapid,
        map_userids:item,
        active_status:0
      }
    });
    dispatch(removeUsersFromGroup(
      {
        url:`/crm/org/${orgId}/groups/${groupDetails.id}/users`,
        params:{
          orgId,
          id:groupDetails.id
        },
        data:{
          usrdtl:usersToRemove
        }
      }
    ))
  }
  const checkRowSelection=(params)=>{
    console.log('params',params);
    return true;
  }
  useEffect(() => {
    getUsers();
  }, [createData]);
  return (
    <div className="">
     
      <Grid  container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CardItem height="810px">
            <div className="card_container" style={{ padding: "20px" }}>
            <CustomBreadcrumbOrg breadcrumbs={breadcrumbs} />
              <div style={{ marginTop: "20px" }}>
                <DataTable
                  rows={[...groupUsers]}
                  columns={columns}
                  checkboxSelection={(params)=>{
                    console.log('params',params);
                  }}
                  onRowSelectionModelChange={(row) => {
                    console.log("row", row);
                     setRowsSelected(row);
                  }}
                  //onRowClick={(row) => navigate(`/leads/${row.id}`)}
                  //loading={loading}
                  disableSelectionOnClick={(params) => {
                    console.log("params", params);
                    return params.row.disableSelection || false;
                  }}
                  isRowSelectable={(params) => params.row.isowner!=='true'}
                />
              </div>
              <div style={{ padding: "20px" }}>
                {rowsSelected.length > 0 && (
                  <>
                    <Button
                      className="primary_button marginT10"
                       onClick={removeUser}
                    >
                      Remove User
                    </Button>
                  </>
                )}
              </div>
            </div>
          </CardItem>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={8} lg={4}>
        <CardItem height="410px">
          <div className="card_container">
            <CardTitle title="Group" type="project_view">
            </CardTitle>
            <div className="card_content">
              <IconText title={`Name         :${name}`} />
              <IconText title={`Description :${desc}`} />
            </div>
          </div>
          <div></div>
        </CardItem>
      </Grid> */}
      </Grid>
      {/* <BasicModal
      isOpen={openEditGroupModal}
      onClose={() => setOpenEditGroupModal(false)}
      boxStyle={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "42%",
        bgcolor: "background.paper",
        boxShadow: 24,
        height: "600px",
        display: "flex",
        flexDirection: "column",
      }}
      sx={modalStyle}
    >
      <>
        <div className="modal_header">
          <p className="modal_title ">Edit Group</p>
          <div className="modal_header_button_wrapper">
            <IconButton onClick={() => setOpenEditGroupModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="modal_body">
          <div
            className="organisation"
            style={{ display: "block", padding: "0px" }}
          >
            <div className="">
              <form noValidate onSubmit={handleSubmit(editGroup)}>
                <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label className="text_medium">Group Name</label>
                    <input
                      type="text"
                      name="name"
                      value={groupName}
                      onChange={(e)=>{
                        setGroupName(e.target.value)
                      }}

                      {...register("name", {
                        required: true,
                      })}
                    />
                    <p className="error_message">{errors.name?.message}</p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label className="text_medium">Description</label>desc
                    <textarea
                      type="text"
                      name="description"
                      rows={8}
                      value={groupDescription}
                      onChange={(e)=>{
                        console.log('')
                        setGroupDescription(e.target.value)
                      }}
                 
                      {...register("description", {})}
                    />
                    <p className="error_message">
                      {errors.description?.message}
                    </p>
                  </Grid>
                </Grid>
                <Button type="submit" className="primary_button marginT10">
                  Save
                </Button>
              </form>
            </div>
          </div>
        </div>

      </>
    </BasicModal> */}
    </div>
  );
};

export default GroupsViewCard;
