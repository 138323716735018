import { takeLatest, put, fork, call } from "redux-saga/effects";
import { createItem } from "../../services/service";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";

import { setDialog } from "../slices/dialogSlice";
import {
  createNewFeedback,
  setFeedbackLoadingFalse,
} from "../slices/feedbackSlice";

function* onCreateFeedbackAsync({ payload }) {
  try {
    const { url, data, params } = payload;
    const response = yield call(createItem, { url, data, params });
    yield put(setFeedbackLoadingFalse());

    yield put(
      setCreateSuccess({
        ...response?.data,
        createSuccess: (response?.status === 200) | 201 ? true : false,
        statusCode: response?.status,
      })
    );
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }
    yield put(setFeedbackLoadingFalse());
  }
}

function* onCreateFeedback() {
  yield takeLatest(createNewFeedback.type, onCreateFeedbackAsync);
}

export const feedbackSagas = [fork(onCreateFeedback)];
