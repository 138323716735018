import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const detailList = [
  { name: "project_intake", title: "Project Intake" },
  { name: "financing", title: "Financing" },
  { name: "engineering", title: "Engineering" },
  { name: "inter_connection", title: "Interconnection" },
  { name: "procurement", title: "Procurement" },
  { name: "permitting", title: "Permitting" },
  { name: "installation", title: "Installation" },
  { name: "inspection", title: "Inspection" },
];

export default function AccordionItem() {
  return (
    <div>
      {detailList.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={item.name}
          >
            <Typography>{item.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
