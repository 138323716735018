import { Link } from "react-router-dom";
import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";

const ProgressCard = ({ data }) => {
  const circleSize = 100;
  return (
    <div className="back_image">
      <div
        style={{ paddingLeft: "20px", paddingRight: "20px" }}
        className="d_flex align_items_center"
      >
        <div>
          <h6 className="progress_title">Your projects are in progress</h6>
          <p className="progress_content">
            Your project has been completed <span>{data[0].value}%</span> of the
            given target, you can also check your status.
          </p>
          <Link className="progress_link">Click here</Link>
        </div>
        <div className="custom_radial_progress">
          <RadialBarChart
            width={100}
            height={100}
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={63}
            barSize={7}
            data={data}
            startAngle={90}
            endAngle={-270}
          >
            <PolarAngleAxis
              type="number"
              domain={[0, 100]}
              angleAxisId={0}
              tick={false}
            />
            <RadialBar
              clockWise
              dataKey="value"
              cornerRadius={circleSize / 2}
              background={{ fill: "#000", opacity: 0.35 }}
              fill="#fff"
            />
            <text
              x={circleSize / 2}
              y={circleSize / 2}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              fill="#fff"
              fontSize="14px"
              fontWeight="700"
              fontFamily="Inter"
            >
              {data[0].value}%
            </text>
          </RadialBarChart>
        </div>
      </div>
    </div>
  );
};

export default ProgressCard;
