import {
  createProjectStatusGroup,
  createStatusGroupUser,
  getProjectStatusList,
} from "../../../redux/slices/projectSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MovingIcon from "@mui/icons-material/Moving";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import AutoCompleteGroup from "../../../fields/autoComplete/AutoCompleteGroup";
import AutoCompleteGroupNew from "../../../fields/autoComplete/AutoCompleteGroupNew";

const StatusList = () => {
  const { id } = useParams();
  const projectStatusList = useSelector(
    (state) => state.project.projectStatusList
  );

  const [groupSelected, setGroupSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const createData = useSelector((state) => state.form.createData);

  const statusLoading = useSelector((state) => state.project.statusLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadStatusList = () => {
    dispatch(
      getProjectStatusList({ url: `crm/org/${id}/proj/status`, params: {} })
    );
  };

  //   const handleGroup = (value, type, status) => {
  //     if (type === "group" && value?.groupId) {
  //       console.log("groupId>>>-------------->", value?.groupId);
  //       // setGroup(value);
  //       const groupData = [
  //         {
  //           status: {
  //             id: status?.id,
  //             name: status?.name,
  //           },
  //           groups: {
  //             id: value?.groupId,
  //             name: value?.groupName,
  //           },
  //         },
  //       ];

  //       dispatch(
  //         createProjectStatusGroup({
  //           url: `/crm/org/${id}/groups/map/status`,
  //           data: groupData,
  //           params: {},
  //         })
  //       );
  //     }
  //   };

  useEffect(() => {
    loadStatusList();
  }, []);

  useEffect(() => {
    if (createData?.createSuccess) {
      loadStatusList();
    }
  }, [createData]);

  if (statusLoading) {
    return <Loading />;
  }
  return (
    <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center justify_content_between marginB10">
          <div className="d_flex align_items_center">
            <IconButton onClick={() => navigate("/org")}>
              <ArrowBackIcon />
            </IconButton>
            <h3>Status List</h3>
          </div>
          {/* <Link
            style={{ textDecoration: "none" }}
            to={`/org/${id}/group/create`}
          >
            <Button
              type="submit"
              className="outline_button d_flex align_items_center"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                padding: "5px 20px",
              }}
              leftIcon={<PlusIcon color="#189d3d" />}
            >
              New Group
            </Button>
          </Link> */}
        </div>
        <div className="org_list_wrapper">
          {projectStatusList.length > 0 ? (
            !statusLoading &&
            projectStatusList?.map((status) => (
              <div key={status.id} className="light_bg_border">
                <div className="d_flex justify_content_between w_100">
                  <div className="w_100">
                    <div className="d_flex align_items_center">
                      <MovingIcon
                        style={{ color: "#189d3d" }}
                        className="org_icon"
                      />
                      <div>
                        <h4 className="my_0">{status?.displayName}</h4>
                      </div>
                    </div>
                    <div className="d_flex align_items_center  w_100">
                      <AutoCompleteGroupNew
                        view="status"
                        name="group"
                        type="group"
                        // label="Group"
                        userLabel="Add Default Assignee"
                        onGroupChange={(event, newValue, reason) => {
                          if (reason === "clear") {
                            setGroupSelected(null);
                          }
                          const groupData = [
                            {
                              status: {
                                id: status?.id,
                                name: status?.name,
                              },
                              groups: {
                                id: newValue?.id,
                                name: newValue?.name,
                              },
                            },
                          ];

                          dispatch(
                            createProjectStatusGroup({
                              url: `/crm/org/${id}/groups/map/status`,
                              data: groupData,
                              params: {},
                            })
                          );
                        }}
                        onUserChange={(event, newValue, reason) => {
                          if (reason === "clear") {
                            setUserSelected(null);
                          }
                          //   setUserSelected(newValue);
                          const userData = [
                            {
                              group: {
                                id: status?.groupId,
                                name: status?.groupName,
                              },
                              user: {
                                id: newValue?.id,
                                name: newValue?.name,
                              },
                            },
                          ];
                          dispatch(
                            createStatusGroupUser({
                              url: `/crm/org/${id}/groups/map/user`,
                              data: userData,
                              params: {},
                            })
                          );
                        }}
                        // onChange={(value, type) =>
                        //   handleGroup(value, type, status)
                        // }
                        value={
                          status?.groupId && !status?.userId
                            ? {
                                userId: null,
                                name: null,
                                // uid: userSelected?.uid,
                                groupId: status?.groupId ?? null,
                                groupName: status?.groupName ?? null,
                              }
                            : {
                                userId: status?.userId ?? null,
                                name: status?.userName ?? null,
                                // uid: userSelected?.uid,
                                groupId: status?.groupId ?? null,
                                groupName: status?.groupName ?? null,
                              }
                        }
                        groupWidth="40%"
                        userWidth="40%"
                      />
                    </div>
                    {/* <p>{grp.description}</p> */}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text_center">No groups found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatusList;
