import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.color ?? "#000"}
        d="M8.527 12.005c-.235-.75-.23-2.199-.094-2.199.394 0 .356 1.73.094 2.199Zm-.08 2.212c-.361.947-.811 2.03-1.331 2.94.857-.329 1.828-.807 2.948-1.027-.595-.45-1.167-1.097-1.617-1.913Zm-4.411 5.85c0 .038.619-.253 1.636-1.884-.314.295-1.364 1.148-1.636 1.884ZM11.625 7.5H18v15.375c0 .623-.502 1.125-1.125 1.125H1.125A1.122 1.122 0 0 1 0 22.875V1.125C0 .502.502 0 1.125 0H10.5v6.375c0 .619.506 1.125 1.125 1.125Zm-.375 8.053c-.938-.572-1.56-1.36-2.002-2.522.211-.867.544-2.184.291-3.01-.22-1.377-1.987-1.241-2.24-.318-.235.858-.02 2.067.38 3.61-.545 1.293-1.346 3.028-1.913 4.021-.005 0-.005.005-.01.005-1.27.652-3.45 2.086-2.554 3.188.262.323.75.468 1.007.468.84 0 1.674-.843 2.864-2.897 1.21-.398 2.536-.895 3.704-1.087 1.017.553 2.207.914 3 .914 1.368 0 1.462-1.5.923-2.034-.652-.638-2.545-.455-3.45-.338Zm6.422-10.631L13.078.328A1.124 1.124 0 0 0 12.281 0H12v6h6v-.286c0-.295-.117-.581-.328-.792Zm-3.474 11.967c.193-.127-.117-.558-2.006-.422 1.74.74 2.006.422 2.006.422Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
