import "./style.scss";

const ToolBar = ({ title, subTitle, children }) => {
  return (
    <div className="content_toolbar">
      <div>
        <p className="welcome_text">{title}</p>
        {subTitle && <p className="welcome_sub_text">{subTitle}</p>}
      </div>
      {children}
    </div>
  );
};

export default ToolBar;
