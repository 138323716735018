import { useState } from "react";
import Select from "../select/Select";
import CardTitle from "./CardTitle";

const DealsCard = () => {
  const [selected, setSelected] = useState("all");

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const dealsList = [
    {
      title: "Successful Deals",
      percentage: "15%",
      color: "189D3D",
      total: "987",
    },
    {
      title: "Pending Deals",
      percentage: "30%",
      color: "49B6F5",
      total: "1,073",
    },
    {
      title: "Rejected Deals",
      percentage: "40%",
      color: "F5B849",
      total: "1,674",
    },
    {
      title: "Upcoming Deals",
      percentage: "15%",
      color: "26BF94",
      total: "921",
    },
  ];

  return (
    <div className="card_container">
      <CardTitle title="Top Deals">
        <Select
          className="dashboard_filter"
          options={[
            { title: "View All", value: "all" },
            { title: "Today", value: "today" },
            { title: "Yesterday", value: "yesterday" },
            { title: "This month", value: "this_month" },
          ]}
          border={false}
          onChange={handleChange}
          selected={selected}
        />
      </CardTitle>

      <div className="card_content">
        {/* <div className="d_flex align_items_center">
          <h6 className="deal_amout">4,289</h6>
          <p className="deal_badge marginL10">
            <small>1.02</small>
          </p>
          <p className="marginL10">
            <small>compared to last week</small>
          </p>
        </div>
        <div className="progress">
          {dealsList.map((item, index) => (
            <div
              key={index}
              style={{
                width: item.percentage,
                backgroundColor: `#${item.color}`,
              }}
              className="progress_bar"
            ></div>
          ))}
        </div>
        <div className="deals_status w_100">
          <ul className="w_100">
            {dealsList.map((item, index) => (
              <li
                key={index}
                style={{ color: `#${item.color}` }}
                className={`d_flex align_items_center w_100 `}
              >
                <div className="d_flex align_items_center justify_content_between w_100 font_14_dark">
                  <p>{item.title}</p>
                  <p>{item.total} deals</p>
                </div>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default DealsCard;
