import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../components/buttons/Button";
import * as yup from "yup";
import { editOrg } from "../../../redux/slices/orgSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DragDropUploader from "../../../components/fileupload/DragDropUploader";
const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Name must be 3 characters at minimum")
    .required("Name is required"),
  email: yup
    .string()
    .matches(
      /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
      "Enter a valid email address"
    )
    .required("Email is required"),
  // password: yup
  //   .string()
  //   .min(8, "Password must be 8 characters at minimum")
  //   .max(32, '"Password must be 32 characters at maximum"')
  //   .required("Password is required"),
});

const EditOrganisationCard = ({ organisationDetails, goBack }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: organisationDetails?.name ?? "",
      description: organisationDetails?.description ?? "",
      email: organisationDetails?.email ?? "",
    },
  });
  const createData = useSelector((state) => state.form.createData);

  const dispatch = useDispatch();
  const editOrganisation = (data) => {
    console.log("data");
    dispatch(
      editOrg({
        url: `crm/user/organization/${organisationDetails.id}`,
        data: data,
        params: {},
      })
    );
  };
  const onErrors = (errors) => {
    console.log("errors=---->", errors);
  };
  useEffect(() => {
    if (createData?.createSuccess) {
      // close();
      //goBack()
      goBack();
    }
  }, [createData]);
  return (
    <div className="organisation_edit">
      <div className="organisation_container_edit">
        <form noValidate onSubmit={handleSubmit(editOrganisation, onErrors)}>
          {/* <label style={{color:"#151515",fontWeight:"600",fontSize:'16px'}}>Organisation Name</label> */}
          <label style={{ fontSize: "16px" }}>Organisation Name</label>
          <input
            type="text"
            name="name"
            {...register("name", {
              required: true,
            })}
          />
          <p className="error_message">{errors.name?.message}</p>

          {/* <label style={{color:"#151515",fontWeight:"600",fontSize:"16px"}}>Description</label> */}
          <label style={{ fontSize: "16px" }}>Description</label>

          <textarea
            type="text"
            name="description"
            rows={8}
            {...register("description", {
              required: true,
            })}
          />
          <p className="error_message">{errors.description?.message}</p>

          {/* <label className="" style={{color:"#151515",fontWeight:"600",fontSize:"16px"}}>Primary Email</label> */}
          <label className="" style={{ fontSize: "16px" }}>
            Primary Email
          </label>

          <input
            autoComplete="off"
            type="email"
            name="email"
            {...register("email", {
              required: true,
              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
            })}
          />
          <p className="error_message">{errors.email?.message}</p>
          {/* <label className="text_medium">Profile Image</label>
          <DragDropUploader /> */}
          <Button type="submit" className="primary_button marginT10">
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};

export default EditOrganisationCard;
