// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom_datepicker {
  border: 1px solid var(--input-field-border) !important;
  padding: 12px 20px;
}

.custom_timepicker {
  border: 1px solid var(--input-field-border) !important;
  padding: 12px 20px;
  width: 60px;
}`, "",{"version":3,"sources":["webpack://./src/fields/dates/style.scss"],"names":[],"mappings":"AAAA;EACE,sDAAA;EACA,kBAAA;AACF;;AAEA;EACE,sDAAA;EACA,kBAAA;EACA,WAAA;AACF","sourcesContent":[".custom_datepicker {\n  border: 1px solid var(--input-field-border) !important;\n  padding: 12px 20px;\n}\n\n.custom_timepicker {\n  border: 1px solid var(--input-field-border) !important;\n  padding: 12px 20px;\n  width: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
