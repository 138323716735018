import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const DateView = ({ date, format }) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return (
    <>
      {dayjs(date)
        .tz(timeZone)
        .format(format ?? "MM/DD/YYYY")}
    </>
  );
};

export default DateView;
