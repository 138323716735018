import { useState } from "react";
import SelectMenu from "../select/Select";
import CardTitle from "./CardTitle";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import PlusIcon from "../../assets/images/analytics_plus.svg";
import MinusIcon from "../../assets/images/analytics_minus.svg";
import ZoomIcon from "../../assets/images/analytics_zoom.svg";
import PickIcon from "../../assets/images/analytics_pick.svg";

import { IconButton } from "@mui/material";

const RevenueAnalytics = () => {
  const [selected, setSelected] = useState("all");

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  const DataFormater = (number) => {
    return "$" + number.toString();
  };

  const data = [
    {
      name: "Jan",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Feb",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Mar",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Apr",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "May",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Jun",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Jul",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Aug",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Sep",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Oct",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Nov",
      profit: 0,
      sales: 0,
      amount: 0,
    },
    {
      name: "Dec",
      profit: 0,
      sales: 0,
      amount: 0,
    },
  ];

  return (
    <div className="card_container">
      <CardTitle title="Revenue Analytics">
        <SelectMenu
          className="dashboard_filter"
          options={[
            { title: "View All", value: "all" },
            { title: "Today", value: "today" },
            { title: "Yesterday", value: "yesterday" },
            { title: "This month", value: "this_month" },
          ]}
          border={false}
          onChange={handleChange}
          selected={selected}
        />
      </CardTitle>
      <div className="card_content">
        <div className="d_flex align_items_center justify_content_between w_100">
          <p className="revenue_analytics_heading">
            Revenue Analytics with sales & profit (USD)
          </p>
          <div className="d_flex align_items_center">
            <IconButton>
              <img src={PlusIcon} alt="Plus" />
            </IconButton>
            <IconButton>
              <img src={MinusIcon} alt="Plus" />
            </IconButton>
            <IconButton>
              <img src={ZoomIcon} alt="Plus" />
            </IconButton>
            <IconButton>
              <img src={PickIcon} alt="Plus" />
            </IconButton>
          </div>
        </div>
        <div className="multi_chart" style={{ width: "100%", height: 460 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={DataFormater} dataKey="amount" />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="amount"
                fill="#F8F8F9"
                stroke="#F8F8F9"
              />
              <Line
                type="monotone"
                dataKey="sales"
                stroke="#192F1E"
                strokeDasharray="8 8"
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey="profit"
                stroke="#189D3D"
                strokeWidth={3}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default RevenueAnalytics;
