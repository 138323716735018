import { useState } from "react";
import CustomDate from "../../../fields/dates/CustomDate";
import dayjs from "dayjs";

const ProjectAssignDetails = ({
  label,
  icon,
  value,
  onClick,
  type,
  openDate,
  onDateChange,
  date,
  errorMessage,
}) => {
  return (
    <div>
      {type === "project_view" ? (
        <p
          className="m_0 font_12_dark_gray"
          style={{
            textAlign: "center",
          }}
        >
          <small>{label}</small>
        </p>
      ) : (
        <label className="font_12_dark_gray_inter">
          <small>{label}</small>
        </label>
      )}
      <div onClick={onClick} className="d_flex align_items_center pointer">
        {icon && (
          <img
            style={
              type === "project_view" ? { width: "20px", height: "20px" } : {}
            }
            src={icon}
            alt="Assign to"
          />
        )}
        {type === "date" && openDate ? (
          <div className="marginL10">
            <CustomDate
              startDate={date}
              // open={openDate}
              startOpen={openDate}
              onChange={onDateChange}
              shouldCloseOnSelect={true}
            />
          </div>
        ) : (
          <p className="font_14_dark m_0 marginL10">
            {type === "date" ? dayjs(date).format("MM/DD/YYYY") : value}
          </p>
        )}
      </div>
      <p className="error_message">{errorMessage}</p>
    </div>
  );
};

export default ProjectAssignDetails;
