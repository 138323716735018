import { createSlice } from "@reduxjs/toolkit";

const userSlice=createSlice({
    name:"user",
    initialState:{
        userList:[],
        loading:false
    },
    reducers:{
        getUserList(state){
            state.loading=true;
            return state;
        },
        setUserList(state,action){
            state.userList=action.payload;
            state.loading=false;
        }
    }
});

export const  {
    getUserList,
    setUserList
}=userSlice.actions;

export default userSlice.reducer;