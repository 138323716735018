import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React from "react";

import "./style.scss";
const CustomDateRangePicker = ({
  onChange,
  value,
  label,
  defaultRangePosition,
  defaultValue,
}) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["SingleInputDateRangeField"]}>
          <div className="date_picker_container">
            <DateRangePicker
              format="YYYY-MM-DD"
              defaultRangePosition={defaultRangePosition}
              defaultValue={defaultValue}
              slots={{ field: SingleInputDateRangeField }}
              onChange={onChange}
              value={value}
              label={label}
              enableAccessibleFieldDOMStructure={true}
              sx={{
                borderRadius: "50px",
              }}
            />
          </div>
        </DemoContainer>
      </LocalizationProvider>
    </>
  );
};

export default CustomDateRangePicker;
