import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    {...props}
  >
    <g
      stroke={props.color ?? "#FB9C37"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.7}
      clipPath="url(#a)"
    >
      <path d="M5 9.167A4.167 4.167 0 1 0 5 .833a4.167 4.167 0 0 0 0 8.334ZM6.25 3.75l-2.5 2.5m0-2.5 2.5 2.5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
