import { createSlice } from "@reduxjs/toolkit";

const dialogSlice = createSlice({
  name: "dialog",
  initialState: {
    openDialog: false,
  },
  reducers: {
    setDialog: (state, action) => {
      state.openDialog = action.payload;
    },
  },
});

export const { setDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
