import { COLUMN_NAMES_LIST } from "../../../components/project/list/constants";
import EditIcon from "../../../assets/images/projectedit.svg";
import CloseIcon from "../../../assets/images/nodownload";

import "./style.scss";
import { IconButton } from "@mui/material";

const WorkFlow = () => {
  return (
    <div className="workflow">
      <h1 className="text_center">Workflow</h1>
      <div className="flow_card_container">
        {COLUMN_NAMES_LIST.map((item, index) => (
          <>
            <div key={index} className="flow_card">
              <div className="d_flex align_items_center">
                <IconButton title="Rename">
                  <img src={EditIcon} alt="Edit" />
                </IconButton>
                <IconButton title="Remove">
                  <CloseIcon color={"#880808"} />
                </IconButton>
              </div>
              <p className="flow_status">{item.title}</p>
            </div>
            <div class="line"></div>
          </>
        ))}
      </div>
    </div>
  );
};

export default WorkFlow;
