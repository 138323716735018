// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invitee_list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: var(--white);
  box-shadow: 0px 4px 16px 0px var(--wrapper-shadow);
}
.invitee_list .invitee_listitem {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 10px;
}
.invitee_list .invitee_listitem:hover {
  background-color: aliceblue;
}`, "",{"version":3,"sources":["webpack://./src/components/project/view/appointment/style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,SAAA;EACA,UAAA;EACA,8BAAA;EACA,kDAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;AACJ;AAAI;EACE,2BAAA;AAEN","sourcesContent":[".invitee_list {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  background-color: var(--white);\n  box-shadow: 0px 4px 16px 0px var(--wrapper-shadow);\n  //   padding: 10px 0;\n  .invitee_listitem {\n    display: flex;\n    align-items: flex-start;\n    cursor: pointer;\n    padding: 10px;\n    &:hover {\n      background-color: aliceblue;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
