import { Grid, IconButton } from "@mui/material";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import ModalBody from "../../../modal/ModalBody";
import { useForm } from "react-hook-form";
import TextInput from "../../../../fields/text/TextInput";
import { useEffect } from "react";
import ModalFooter from "../../../modal/ModalFooter";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createAdditionalInfo } from "../../../../redux/slices/additionalInfoSlice";

const SystemDetailsForm = ({ onClose, systemDetails }) => {
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (systemDetails?.id) {
      data.id = systemDetails?.id;
    }
    const editedData = {
      systemdtl: [data],
    };

    dispatch(
      createAdditionalInfo({
        url: `/crm/org/${orgId}/proj/${id}/info?data=systemdtl`,
        data: editedData,
        params: {},
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (systemDetails?.systemSize) {
      setValue("systemSize", systemDetails?.systemSize);
    }
    if (systemDetails?.productionVolume) {
      setValue("productionVolume", systemDetails?.productionVolume);
    }
    if (systemDetails?.offset) {
      setValue("offset", systemDetails?.offset);
    }
    if (systemDetails?.systemCost) {
      setValue("systemCost", systemDetails?.systemCost);
    }
    if (systemDetails?.modules) {
      setValue("modules", systemDetails?.modules);
    }
    if (systemDetails?.inverters) {
      setValue("inverters", systemDetails?.inverters);
    }
    if (systemDetails?.panelCount) {
      setValue("panelCount", systemDetails?.panelCount);
    }
    if (systemDetails?.rackingRails) {
      setValue("rackingRails", systemDetails?.rackingRails);
    }
    if (systemDetails?.rackingAttachments) {
      setValue("rackingAttachments", systemDetails?.rackingAttachments);
    }
    if (systemDetails?.storage) {
      setValue("storage", systemDetails?.storage);
    }
    if (systemDetails?.storageType) {
      setValue("storageType", systemDetails?.storageType);
    }
  }, [systemDetails]);

  return (
    <>
      <ModalHead title="System Details">
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="System Size"
                // placeholder="Property Type"
                register={register}
                name="systemSize"
              />
              <p className="error_message">{errors.systemSize?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Production Volume"
                // placeholder="Year Built"
                register={register}
                name="productionVolume"
              />
              <p className="error_message">
                {errors.productionVolume?.message}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Offset"
                // placeholder="Area"
                register={register}
                name="offset"
              />
              <p className="error_message">{errors.offset?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="System Cost"
                // placeholder="Home Type"
                register={register}
                name="systemCost"
              />
              <p className="error_message">{errors.systemCost?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Modules"
                // placeholder="Home Type"
                register={register}
                name="modules"
              />
              <p className="error_message">{errors.modules?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Inverters"
                // placeholder="Home Type"
                register={register}
                name="inverters"
              />
              <p className="error_message">{errors.inverters?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Panel Count"
                // placeholder="Home Type"
                register={register}
                name="panelCount"
              />
              <p className="error_message">{errors.panelCount?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Racking Rails"
                // placeholder="Home Type"
                register={register}
                name="rackingRails"
              />
              <p className="error_message">{errors.rackingRails?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Racking Attachments"
                // placeholder="Home Type"
                register={register}
                name="rackingAttachments"
              />
              <p className="error_message">
                {errors.rackingAttachments?.message}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Storage"
                // placeholder="Home Type"
                register={register}
                name="storage"
              />
              <p className="error_message">{errors.storage?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Storage Type"
                // placeholder="Home Type"
                register={register}
                name="storageType"
              />
              <p className="error_message">{errors.storageType?.message}</p>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="primary_button">
            Save
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default SystemDetailsForm;
