import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    open: false,
    uploadDetails: null,
    modalMinmize: false,
    openDownloadModal:false
  },
  reducers: {
    getModal(state) {
      return state;
    },
    setModal: (state, action) => {
      state.open = action.payload.open;
      state.uploadDetails = action.payload.uploadDetails;
    },
    setMinimize: (state, action) => {
      state.modalMinmize = action.payload;
    },
    setOpenDownloadModal:(state,action)=>{
      console.log('action.pa',action.payload);
      state.openDownloadModal=action.payload.openDownloadModal;
    }
  },
});

export const { getModal, setModal, setMinimize ,setOpenDownloadModal} = modalSlice.actions;

export default modalSlice.reducer;
