import React from 'react'
import Diversity3Icon from "@mui/icons-material/Diversity3";
const GroupsCard = ({groupName,groupDescription}) => {
  return (
    <div className="light_bg_border marginT10">
    <div className="d_flex justify_content_between">
      <div>
        <div className="d_flex align_items_center">
          <Diversity3Icon
            style={{ color: "#189d3d" }}
            className="org_icon"
          />
          <div>
            <h4 className="my_0">{groupName}</h4>
          </div>
        </div>
        <p>{groupDescription}</p>
      </div>
      {/* {isOwner && (
        <div>
          <ThreeDotDropdown
            options={options}
            onSelect={(value) => handleSelect(value, grp)}
          />
        </div>
      )} */}
    </div>
  </div>
  )
}

export default GroupsCard