import * as dotProp from 'dot-prop';
import { isObject } from './util';

const Storage = {
  write: (key, value) => {
    if (isObject(value)) {
      value = JSON.stringify(value);
    }

    localStorage.setItem(key, value);
  },

  read: (key) => {
    const value = localStorage.getItem(key);

    if (!value) {
      return value;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },

  delete: (key) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },

  setting: (key, defaultValue = null) =>
    dotProp.getProperty(Storage.read('setting'), `data.${key}`, defaultValue),

  remember: (key, ttl = 900, refresh = false) =>
    new Promise((resolve, reject) => {
      const val = Storage.read(key) ?? false;
      const { data, expire_in } = val;
      const time = Math.floor(Date.now() / 1000);

      if (!refresh && expire_in && time > expire_in) {
        resolve(data);
      } else {
        reject((res) => {
          Storage.write(key, { expire_in: time + 10, data: res });
        });
      }
    })
};

export default Storage;