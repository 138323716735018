// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content_toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content_toolbar .welcome_text {
  color: var(--font-black);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin: 0;
}
.content_toolbar .welcome_sub_text {
  color: var(--grey);
  font-family: Montserrat;
  font-size: 13.008px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.512px;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .content_toolbar {
    margin-bottom: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/toolbar/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AAAE;EACE,wBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;AAEJ;AAAE;EACE,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,SAAA;AAEJ;;AAEA;EACE;IACE,mBAAA;EACF;AACF","sourcesContent":[".content_toolbar {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  .welcome_text {\n    color: var(--font-black);\n    font-family: Montserrat;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 27px;\n    margin: 0;\n  }\n  .welcome_sub_text {\n    color: var(--grey);\n    font-family: Montserrat;\n    font-size: 13.008px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 19.512px;\n    margin: 0;\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .content_toolbar {\n    margin-bottom: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
