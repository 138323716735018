import { Grid, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "../../../assets/images/close";
import { modalStyle } from "../../../components/constants/ConstData";
import BasicModal from "../../../components/modal/Modal";

const FilterModal = ({ open, close }) => {
  return (
    <>
      <BasicModal
        isOpen={open}
        onClose={close}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "400px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <>
          <div className="modal_header">
            <p className="modal_title "></p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          <div className="modal_body">
            <Grid container spacing={2}>
              <Grid item>
                <div className={""}>
                  <h3 className="organisation_link">Status</h3>
                </div>
                <div className={""}>
                  <h3 className="organisation_link">From Date</h3>
                </div>
                <div className={""}>
                  <h3 className="organisation_link">Due Date</h3>
                </div>
              </Grid>
              <Grid item></Grid>
            </Grid>
          </div>
          {/* </form> */}
        </>
      </BasicModal>
    </>
  );
};

export default FilterModal;
