import { createSlice } from "@reduxjs/toolkit";

const additionalInfoSlice = createSlice({
  name: "additionalInfo",
  initialState: {
    systemDetails: null,
    hoaDetails: null,
    financeDetails: null,
    ahjDetails: null,
    loading: false,
  },
  reducers: {
    getAdditionalInfo(state) {
      state.loading = true;
      return state;
    },
    createAdditionalInfo(state) {
      state.loading = true;
      return state;
    },
    // createFinanceDetails(state) {
    //     state.loading = true;
    //     return state;
    //   },

    //   createHoaDetails(state) {
    //     state.loading = true;
    //     return state;
    //   },
    setAdditionalInfo: (state, action) => {
      state.systemDetails = action.payload.systemdtl[0];
      state.hoaDetails = action.payload.hoa[0];
      state.financeDetails = action.payload.financialdtl[0];
      state.ahjDetails =
        action.payload.ahjdtl?.length > 0 ? action.payload.ahjdtl[0] : [];
      state.loading = false;
    },

    setAdditionalInfoLoadingFalse: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  getAdditionalInfo,
  createAdditionalInfo,
  setAdditionalInfo,
  setAdditionalInfoLoadingFalse,
} = additionalInfoSlice.actions;

export default additionalInfoSlice.reducer;
