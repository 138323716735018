import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import Button from "../buttons/Button";
import PlusIcon from "../../assets/images/plus";
import RemoveIcon from "@mui/icons-material/Remove";

const ContactForm = ({
  register,
  errors,
  type,
  onClick,
  showSecondary,
  btnDisabled,
}) => {
  return (
    <div style={{ marginTop: "16px" }} className="project_contact">
      <div className="d_flex align_items_center justify_content_between">
        <h4>{type === "pContact" ? "Primary " : "Secondary"} Contact</h4>
        {type === "pContact" && (
          <Button
            onClick={onClick}
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "18px",
              padding: "2px 10px",
            }}
            className="outline_button d_flex align_items_center"
            leftIcon={
              showSecondary ? <RemoveIcon /> : <PlusIcon color="#189d3d" />
            }
            disabled={btnDisabled}
          >
            Secondary Contact
          </Button>
        )}
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <label className="text_medium">First Name</label>
          <input
            type="text"
            name="firstName"
            autoComplete="off"
            {...register(`customerInfo.${type}.firstName`, {
              required: true,
            })}
          />
          <p className="error_message">{errors?.firstName?.message}</p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <label className="text_medium">Last Name</label>
          <input
            type="text"
            name="lastName"
            autoComplete="off"
            {...register(`customerInfo.${type}.lastName`, {
              required: true,
            })}
          />
          <p className="error_message">{errors?.lastName?.message}</p>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <label className="text_medium">Email</label>
          <input
            type="text"
            name="email"
            autoComplete="off"
            {...register(`customerInfo.${type}.email`, {
              required: true,
            })}
          />
          <p className="error_message">{errors?.email?.message}</p>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <label className="text_medium">Phone</label>
          <input
            type="text"
            name="mobile"
            autoComplete="off"
            {...register(`customerInfo.${type}.mobile`, {
              required: true,
            })}
          />
          <p className="error_message">{errors?.mobile?.message}</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactForm;
