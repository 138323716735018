import { Line, LineChart } from "recharts";

const InfoCard = ({
  id,
  icon,
  title,
  count,
  data,
  percent,
  percentColor,
  gradient,
}) => {
  return (
    <div className="dash_info_card">
      <div className="d_flex align_items_start">
        <img className="marginR10" src={icon} alt="Total Customers" />
        <div>
          <p className="dash_info_title">{title}</p>
          <p className="dash_info_count">{count}</p>
        </div>
      </div>
      <div>
        <LineChart width={100} height={100} data={data}>
          <defs>
            <linearGradient
              id={id}
              x1="0"
              y1="32.3478"
              x2="100"
              y2="32.3478"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={gradient.color1} stopOpacity="0.81" />
              <stop
                offset="0.98"
                stopColor={gradient.color2}
                stopOpacity="0.81"
              />
              <stop offset="1" stopColor={gradient.color2} stopOpacity="0.81" />
            </linearGradient>
          </defs>
          <Line
            type="monotone"
            dataKey="pv"
            stroke={`url(#${id})`}
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
        <p style={{ color: percentColor }} className="percent_info">
          {percent}
        </p>
        <p className="this_month">
          <small>this month</small>
        </p>
      </div>
    </div>
  );
};

export default InfoCard;
