import { useEffect, useRef } from "react";
import Loading from "../../../components/loading/Loading";
import { Grid, IconButton, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import CustomBreadcrumb from "../../../components/breadcrumb/CustomBreadcrumb";
import { CardItem } from "../../../utils/CardItem";
import { useDispatch, useSelector } from "react-redux";
import CustomerDetails from "../../../components/project/view/CustomerDetails";
import ProjectInfo from "../../../components/project/view/ProjectInfo";
import { getLead } from "../../../redux/slices/leadSlice";
import LeadForm from "../create/LeadForm";
import BasicModal from "../../../components/modal/Modal";
import { useState } from "react";
import ProjectAppointments from "../../../components/project/view/appointment/ProjectAppointments";
import { modalStyle } from "../../../components/constants/ConstData";
import ProjectForm from "../../projects/create/ProjectForm";
import Storage from "../../../utils/Storage";
import { getAppointmentList } from "../../../redux/slices/appointmentSlice";
import Talk from "talkjs";
import { setCreateSuccess } from "../../../redux/slices/formSlice";
import CustomChat from "../../../components/chat/CustomChat";
import FilesCard from "../../../components/project/view/fileupload/FilesCard";
import ProjectDetailsTab from "../../../components/project/view/ProjectDetailsTab";
import { clearCurrentFile } from "../../../redux/slices/fileUploadSlice";
import FileFolders from "../../../components/project/view/filefolders/FileFolders";
import ViewTitleBar from "../../../components/viewtitle/ViewTitleBar";
import ThreeDotDropdown from "../../../components/dropdown/ThreeDotDropdown";
import PropertyDetailsCard from "../../../components/project/view/propertydetails/PropertyDetailsCard";
import DateView from "../../../components/date/DateView";
import CreateDateIcon from "../../../assets/images/createdate.svg";
import DescriptionCard from "../../../components/project/view/DescriptionCard";
import OfficeSourceCard from "../../../components/project/view/OfficeSourceCard";
import { Edit } from "@mui/icons-material";

const options = [
  { name: "Edit", value: "edit" },
  { name: "Export to project", value: "export" },
  { name: "Delete", value: "delete" },
];

const LeadView = () => {
  const loading = useSelector((state) => state.lead.loading);
  const leadData = useSelector((state) => state.lead.leadData);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProject, setIsOpenProject] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const createData = useSelector((state) => state.form.createData);
  const [conversationValue, setConversationValue] = useState();
  const [chatboxValue, setChatboxValue] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [portalCreated, setPortalCreated] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // const propertyDetails = useSelector((state) => state.project.propertyDetails);
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;

  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const containerRef = useRef(null);

  const chatboxRef = useRef(null);

  const { id } = useParams();

  const breadcrumbs = [
    <Link key="1" to="/leads">
      Leads
    </Link>,
    <Typography key="2" color="text.primary">
      Lead detail
    </Typography>,
  ];

  const dispatch = useDispatch();

  const loadPortal = async () => {
    await Talk.ready
      .then(() => {
        /* Create the two users that will participate in the conversation */

        const me = new Talk.User({
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-7.jpg",
          // welcomeMessage: welcomeMessage ?? null,
          // welcomeMessage: welcomeMessage,
          role: "default",

          custom: {
            type: "projects",
            typeId: id,
          },
        });
        const other = new Talk.User({
          id: leadData?.customerInfo?.pContact?.id,
          name:
            leadData?.customerInfo?.pContact?.firstName +
            " " +
            leadData?.customerInfo?.pContact?.lastName,
          email: leadData?.customerInfo?.pContact?.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-8.jpg",
          // welcomeMessage: "Hey, how can I help?",

          role: "customer",
          // custom: {
          //   typeId: projectData?.id,
          //   type: "projects",
          // },
        });

        /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId: process.env.REACT_APP_CHAT_APP_ID,
            me: me,
          });
        }

        // /* Get a conversation ID or create one */

        const conversation = window.talkSession.getOrCreateConversation(
          leadData?.chatId
        );

        /* Set participants of the conversations */
        conversation.setParticipant(me);
        conversation.setParticipant(other);
        const chatbox = window.talkSession.createChatbox(conversationValue);
        chatboxRef.current = chatbox;

        if (containerRef.current && chatbox) {
          chatbox.mount(containerRef.current).then(() => {
            setPortalCreated(true);
          });
        }
      })
      .catch((e) => console.error(e));
  };

  const loadTalkApp = () => {
    Talk.ready
      .then(() => {
        /* Create the two users that will participate in the conversation */
        const me = new Talk.User({
          id: userInfo.id,
          name: userInfo.name,
          email: userInfo.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-7.jpg",

          role: "default",

          custom: {
            type: "projects",
            typeId: id,
          },
        });
        const other = new Talk.User({
          id: leadData?.customerInfo?.pContact?.id,
          name:
            leadData?.customerInfo?.pContact?.firstName +
            " " +
            leadData?.customerInfo?.pContact?.lastName,
          email: leadData?.customerInfo?.pContact?.email,
          // photoUrl: "https://talkjs.com/new-web/avatar-8.jpg",
          // welcomeMessage: "Hey, how can I help?",

          role: "customer",
          // custom: {
          //   typeId: projectData?.id,
          //   type: "projects",
          // },
        });

        /* Create a talk session if this does not exist. Remember to replace tthe APP ID with the one on your dashboard */
        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId: process.env.REACT_APP_CHAT_APP_ID,
            me: me,
          });
        }

        // /* Get a conversation ID or create one */
        const conversation = window.talkSession.getOrCreateConversation(
          leadData?.chatId
        );

        /* Set participants of the conversations */
        conversation.setParticipant(me);
        conversation.setParticipant(other);
        setConversationValue(conversation);
        // containerRef.current.style.display = "none";
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    /* Retrieve the two users that will participate in the conversation */

    /* Session initialization code */
    if (!loading && userInfo && leadData && leadData.chatId) {
      loadTalkApp();
    }
  }, [loading, leadData]);

  const handleClick = () => {
    if (conversationValue) {
      // setChatVisible(true);
      /* Create and mount chatbox in container */

      const chatbox = window.talkSession.createChatbox(conversationValue);
      chatboxRef.current = chatbox;
      setChatboxValue(chatbox);
      // Mount the chatbox to the container
      if (containerRef.current && chatbox) {
        chatbox.mount(containerRef.current);
        if (containerRef.current.style.display === "none") {
          containerRef.current.style.display = "block";
        }
      }
    }
  };

  const handleSendPortal = () => {
    loadPortal();
  };

  const sentPortalMessage = async () => {
    const chatData = [
      {
        text: "Hi!, Welcome to sunscape",
        sender: userInfo.id.toString(),
        type: "UserMessage",
      },
    ];

    try {
      const response = await fetch(
        `https://api.talkjs.com/v1/${process.env.REACT_APP_CHAT_APP_ID}/conversations/${leadData?.chatId}/messages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CHAT_APP_SECRET_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(chatData),
        }
      );

      dispatch(
        setCreateSuccess({
          message: "Email notification sent successfully",
          createSuccess: true,
          statusCode: response?.status,
        })
      );
      if (!response.ok) {
        throw new Error("Failed to send notification");
      }
    } catch (error) {
      console.error("Error sending email notification:", error.message);
    }
  };

  useEffect(() => {
    if (portalCreated) {
      sentPortalMessage();
    }

    return () => setPortalCreated(false);
  }, [portalCreated]);

  useEffect(() => {
    if (window.talkSession) {
      window.talkSession.unreads.on("change", function (unreadConversations) {
        setUnreadCount(unreadConversations[0]?.unreadMessageCount);
      });
    }
  }, [conversationValue]);

  useEffect(() => {
    if (chatboxValue) {
      chatboxValue.onCustomConversationAction("close-chat", (event) => {
        // Send the message to your backend here, so a moderator can review it.
        containerRef.current.style.display = "none";
        chatboxValue.destroy();
      });
    }
  }, [chatboxValue]);

  const loadLeads = () => {
    dispatch(
      getLead({
        url: `crm/org/${orgId}/leads/${id}`,
        params: {},
      })
    );
  };

  useEffect(() => {
    if (orgId) {
      loadLeads();
    }
  }, []);

  const handleItem = (value) => {
    switch (value) {
      case "edit":
        setIsOpen(true);
        break;
      case "delete":
        break;
      case "export":
        setIsOpenProject(true);
        break;
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsOpenProject(false);
  };

  const loadAppointmentList = () => {
    dispatch(
      getAppointmentList({
        url: `crm/org/${orgId}/leads/${id}/appointment/status/${tabValue}`,
        params: {},
      })
    );
  };

  const appointmentTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (createData?.createSuccess) {
      handleClose();
      loadLeads();
      loadAppointmentList();
      dispatch(clearCurrentFile());
    }
  }, [createData]);

  if (!isOpenProject && !isOpen && loading) {
    return <Loading />;
  }

  return (
    <div className="project_view">
      {/* <CustomBreadcrumb breadcrumbs={breadcrumbs} /> */}
      <div className={"title_bar"}>
        <ViewTitleBar
          firstName={leadData?.customerInfo?.pContact?.firstName}
          lastName={leadData?.customerInfo?.pContact?.lastName}
          email={leadData?.customerInfo?.pContact?.email}
          mobile={leadData?.customerInfo?.pContact?.mobile}
          address={leadData?.address}
        />
        <div className="d_flex created_details">
          {leadData?.createdAt && (
            <div className="">
              <p
                className="m_0 font_12_dark_gray"
                style={{
                  textAlign: "center",
                }}
              >
                Created
              </p>
              <div className="d_flex align_items_center">
                <img src={CreateDateIcon} alt="Assign to" />
                <p className="font_12_dark_gray marginL10">
                  <DateView date={leadData?.createdAt} />
                </p>
              </div>
            </div>
          )}

          {leadData?.dueDate && (
            <div className="marginL20">
              <p
                className="m_0 font_12_dark_gray"
                style={{
                  textAlign: "center",
                }}
              >
                Due Date
              </p>
              <div className="d_flex align_items_center">
                <img src={CreateDateIcon} alt="Assign to" />
                <p className="font_12_dark_gray marginL10">
                  <DateView date={leadData?.dueDate} />
                </p>
              </div>
            </div>
          )}
          {leadData?.createdBy?.name && (
            <div className="marginL20">
              <p
                className="m_0 font_12_dark_gray"
                style={{
                  textAlign: "center",
                }}
              >
                Created By
              </p>
              <div className="d_flex align_items_center justify_content_center">
                <p className="font_12_dark_gray marginL10 my_0">
                  {leadData?.createdBy?.name}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="d_flex align_items_center">
          <IconButton
            className=""
            size="small"
            onClick={() => handleItem("edit")}
          >
            <Edit />
          </IconButton>
        </div>
      </div>
      <div
        className="view_page_content"
        style={{ paddingLeft: "25px", paddingRight: "25px" }}
      >
        <Grid sx={{ marginTop: "16px" }} container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <CardItem height={isExpanded ? "auto" : "100px"}>
              <DescriptionCard
                data={leadData}
                onToggle={() => setIsExpanded(!isExpanded)}
                isExpanded={isExpanded}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CardItem height="100px">
              <OfficeSourceCard type="lead" data={leadData} />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={18} lg={4}>
            <CardItem height="500px">
              <div
                style={{ padding: "11.37px 20px 13.13px 20px" }}
                className="card_container"
              >
                <PropertyDetailsCard type="lead" cardData={leadData} />
              </div>
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={4}>
            <CardItem height="500px">
              <ProjectAppointments
                type="leads"
                loadAppointmentList={loadAppointmentList}
                onChange={appointmentTabChange}
                tabValue={tabValue}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={4}>
            <CardItem height="500px">
              <FileFolders type="leads" folderList={leadData?.folders} />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={4}>
            <CardItem height="360px">
              <ProjectInfo data={leadData} />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={4}>
            <CardItem height="360px">
              <CustomerDetails
                pContact={leadData?.customerInfo?.pContact}
                sContact={leadData?.customerInfo?.sContact}
                onChat={handleClick}
                unreadCount={unreadCount}
                onSendPortal={handleSendPortal}
              />
            </CardItem>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <CardItem height="510px">
              <ProjectDetailsTab projectData={leadData} type="leads" />
            </CardItem>
          </Grid>
        </Grid>
        <CustomChat innerRef={containerRef} />
        <BasicModal isOpen={isOpen} onClose={handleClose} sx={modalStyle}>
          <LeadForm id={id} leadData={leadData} onClose={handleClose} />
        </BasicModal>

        <BasicModal
          isOpen={isOpenProject}
          onClose={handleClose}
          sx={modalStyle}
        >
          <ProjectForm leadValue={leadData} onClose={handleClose} />
        </BasicModal>
      </div>
    </div>
  );
};

export default LeadView;
