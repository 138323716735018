import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../components/buttons/Button";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewOrg,
  editOrg,
  getOrgList,
  setDesignESRSuccess,
  setIsDesign,
  setOrgDefault,
  setOrgDefaultSO,
} from "../../redux/slices/orgSlice";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import { ExitToApp, Logout, Margin, Padding } from "@mui/icons-material";
import { getUserLogout } from "../../redux/slices/authSlice";
import Storage from "../../utils/Storage";
import ESRLogo from "../../assets/images/esrLogo.svg";
import ModalHead from "../../components/modal/ModalHead";
import ModalBody from "../../components/modal/ModalBody";
import BasicModal from "../../components/modal/Modal";
import { modalStyle } from "../../components/constants/ConstData";
import DesignWithEsrForm from "./DesignWithEsrForm";

const schema = yup.object().shape({
  name: yup
    .string()
    .min(3, "Name must be 3 characters at minimum")
    .required("Name is required"),
  email: yup
    .string()
    .matches(
      /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
      "Enter a valid email address"
    )
    .required("Email is required"),
  // password: yup
  //   .string()
  //   .min(8, "Password must be 8 characters at minimum")
  //   .max(32, '"Password must be 32 characters at maximum"')
  //   .required("Password is required"),
});

const NewOrganisation = () => {
  const orgInfo = useSelector((state) => state.org.orgInfo);
  const createData = useSelector((state) => state.form.createData);
  const orgLoading = useSelector((state) => state.org.loading);
  const createdOrg = useSelector((state) => state.org.createdOrg);
  const isDesign = useSelector((state) => state.org.isDesign);
  const designESR = useSelector((state) => state.org.designESR);

  const [openViewModal, setOpenViewModal] = useState(false);

  let { id } = useParams();

  const [orgData, setOrgData] = useState();
  const newData = orgInfo
    ?.filter((item) => item.id == id)
    .map((items) => items)[0];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: newData?.name ?? "",
      description: newData?.description ?? "",
      email: newData?.email ?? "",
    },
  });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    // if (!id && orgInfo !== null && orgInfo.name !== "CRM DEFAULT") {
    //   navigate("/org");
    // }
  }, [id, orgInfo]);

  const Logout = () => {
    dispatch(getUserLogout());
  };

  const onSubmit = (data) => {
    if (id) {
      dispatch(
        editOrg({ url: `crm/user/organization/${id}`, data: data, params: {} })
      );
    } else {
      dispatch(
        createNewOrg({ url: "crm/user/organization", data: data, params: {} })
      );
    }
  };

  const onErrors = (errors) => {
    console.log("errors=---->", errors);
  };

  useEffect(() => {
    console.log("orgLoading=-------->", orgLoading);
  }, [orgLoading]);

  const loadDefault = () => {
    dispatch(
      setOrgDefault({
        url: `crm/user/organization/${createdOrg?.id}/default`,
        params: {},
      })
    );

    const defaultSOData = {
      offices: [
        {
          name: "Design",
          description: "Group for design-related tasks",
          address: "123 Main St",
          orgId: 123,
          email: "design@example.com",
        },
        {
          name: "Development",
          description: "Group for development-related tasks",
          address: "456 Elm St",
          orgId: 123,
          email: "development@example.com",
        },
      ],
      sources: [
        {
          name: "Online",
          description: "Online sources for leads",
          orgId: 123,
        },
        {
          name: "Referral",
          description: "Referrals from existing clients",
          orgId: 123,
        },
      ],
    };
    dispatch(
      setOrgDefaultSO({
        url: `crm/user/organization/${createdOrg.id}/defaultSO`,
        data: defaultSOData,
        params: {},
      })
    );
    if (!orgLoading) {
      dispatch(getOrgList({ url: "crm/user/organization", params: {} }));

      navigate("/org");
      dispatch(setDesignESRSuccess(null));
    }
  };

  useEffect(() => {
    if (createData?.createSuccess) {
      if (createdOrg && createdOrg.id) {
        if (isDesign) {
          console.log("isDesign=--------->", isDesign);

          setOpenViewModal(true);
        } else {
          loadDefault();
        }
      }
    }
  }, [createData]);

  useEffect(() => {
    if (designESR?.id) {
      loadDefault();
      dispatch(setIsDesign(false));
    }
  }, [designESR]);

  return (
    <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center justify_content_between">
          <div className="d_flex align_items_center">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <h3>New Organization</h3>
          </div>
          {orgInfo?.length === 0 && (
            <IconButton
              sx={{
                borderRadius: 0,
                border: "1px solid",
                borderColor: "primary.main",
                borderRadius: "8px",
              }}
              onClick={Logout}
            >
              <p className="font_12_dark_gray">Logout </p>
              <ExitToApp />
            </IconButton>
          )}
        </div>
        {orgInfo?.length === 0 && (
          <p>Please add your organization details to continue </p>
        )}
        <form noValidate onSubmit={handleSubmit(onSubmit, onErrors)}>
          <label className="text_medium">Organization Name</label>
          <input
            type="text"
            name="name"
            {...register("name", {
              required: true,
            })}
          />
          <p className="error_message">{errors.name?.message}</p>

          <label className="text_medium">Description</label>
          <textarea
            type="text"
            name="description"
            rows={8}
            {...register("description", {
              required: true,
            })}
          />
          <p className="error_message">{errors.description?.message}</p>

          <label className="text_medium">Primary Email</label>
          <input
            autoComplete="off"
            type="email"
            name="email"
            {...register("email", {
              required: true,
              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
            })}
          />
          <p className="error_message">{errors.email?.message}</p>
          {!id && (
            <div>
              <Button
                type="button"
                style={{ padding: "5px 12px", fontSize: "14px" }}
                className={`${
                  isDesign ? "primary_button" : "outline_button"
                } d_flex align_items_center`}
                leftIcon={
                  <img
                    src={ESRLogo}
                    style={{ width: "25px", height: "25px" }}
                  />
                }
                onClick={() => dispatch(setIsDesign(!isDesign))}
              >
                Design with ESR
              </Button>
            </div>
          )}

          {/* <label className="text_medium">Password</label>
          <input
            autoComplete="new-password"
            type="password"
            name="password"
            {...register("password", { required: true, minLength: 6 })}
          />
          <p className="error_message">{errors.password?.message}</p> */}
          <Button
            style={{ marginTop: "70px" }}
            type="submit"
            className="primary_button marginB10"
          >
            Save
          </Button>
        </form>
      </div>

      <BasicModal
        isOpen={openViewModal}
        onClose={() => setOpenViewModal(false)}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <DesignWithEsrForm />
      </BasicModal>
    </div>
  );
};

export default NewOrganisation;
