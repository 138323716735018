import * as React from "react";
import Button from "../../buttons/Button";
import CardTitle from "../../dashboard/CardTitle";
import UserDetails from "../../userdetails/UserDetails";
import AvatarIcon from "../../../assets/images/dealer.jpeg";
import CallIcon from "../../../assets/images/callgreen.svg";
import MailIcon from "../../../assets/images/mailgreen.svg";
import { Box, IconButton, Tabs } from "@mui/material";
import {
  CustomTabPanel,
  StyledTab,
  a11yProps,
} from "../../constants/Constants";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";

// const dealersList = [
//   {
//     name: "Michael Jordan",
//     email: "michael.jordan@example.com",
//     image: AvatarIcon,
//   },
//   {
//     name: "Emigo Kiaren",
//     email: "emigo.kiaren@gmail.com",
//     image: AvatarIcon,
//   },
//   {
//     name: "Randy Origoan",
//     email: "randy.origoan@gmail.com",
//     image: AvatarIcon,
//   },
//   {
//     name: "George Pieterson",
//     email: "george.pieterson@gmail.com",
//     image: AvatarIcon,
//   },
//   {
//     name: "Kiara Advain",
//     email: "kiaraadvain214@gmail.com",
//     image: AvatarIcon,
//   },
// ];

const AssignedToCard = ({ assigneelist, assignedto }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="card_container">
      {/* <CardTitle title="Assigned To" type="project_view">
        <Button className="dot_button">
          <div className="dot_kebab"></div>
        </Button>
      </CardTitle> */}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          // textColor="secondary"
          // indicatorColor="secondary"

          TabIndicatorProps={{
            style: {
              backgroundColor: "#189d3d",
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab
            icon={<AssignmentTurnedInIcon />}
            iconPosition="start"
            label="Assigned To"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(0)}
          />
          <StyledTab
            icon={<AssignmentReturnIcon />}
            iconPosition="start"
            label="Previously Assigned To"
            sx={{
              fontSize: "10px",
            }}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <div className="card_content">
        <CustomTabPanel value={value} index={0}>
          {assignedto && (
            <div className="d_flex align_items_center justify_content_between w_100 marginT10">
              <UserDetails
                image={AvatarIcon}
                name={assignedto.name}
                email={assignedto.email}
              />
              {/* <div>
                <IconButton>
                  <img src={CallIcon} alt="Call" />
                </IconButton>
                <IconButton>
                  <img src={MailIcon} alt="Mail" />
                </IconButton>
              </div> */}
            </div>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {assigneelist && assigneelist?.length > 0 ? (
            assigneelist?.map((item, index) => (
              <div
                key={index}
                className="d_flex align_items_center justify_content_between w_100 marginT10"
              >
                <UserDetails
                  image={AvatarIcon}
                  name={item.name}
                  email={item.email}
                />
                {/* <div>
                  <IconButton>
                    <img src={CallIcon} alt="Call" />
                  </IconButton>
                  <IconButton>
                    <img src={MailIcon} alt="Mail" />
                  </IconButton>
                </div> */}
              </div>
            ))
          ) : (
            <p>Nobody assigned previously</p>
          )}
        </CustomTabPanel>
      </div>
    </div>
  );
};

export default AssignedToCard;
