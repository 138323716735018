// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-viewer {
  position: relative;
  background-color: var(--file-viewer-bg) !important;
  height: 100%;
  padding: 0 20px;
}

.react-viewer-mask {
  z-index: 1400 !important;
  background-color: var(--file-viewer-bg) !important;
}

.react-viewer-close {
  z-index: 1410 !important;
}

.react-viewer-canvas {
  z-index: 1405 !important;
}

.react-viewer-footer {
  z-index: 1405 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/fileviewer/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kDAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,wBAAA;EACA,kDAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AACA;EACE,wBAAA;AAEF","sourcesContent":[".file-viewer {\n  position: relative;\n  background-color: var(--file-viewer-bg) !important;\n  height: 100%;\n  padding: 0 20px;\n}\n\n.react-viewer-mask {\n  z-index: 1400 !important;\n  background-color: var(--file-viewer-bg) !important\n  ;\n}\n.react-viewer-close {\n  z-index: 1410 !important;\n}\n\n.react-viewer-canvas {\n  z-index: 1405 !important;\n}\n.react-viewer-footer {\n  z-index: 1405 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
