// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalbodyCheckListWrapper {
  height: 100%;
}
.modalbodyCheckListWrapper .modal_body {
  padding: 6px 24px;
  margin-bottom: 20px;
  height: 100%;
  overflow-y: scroll;
}

.item_title {
  color: #151515;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  width: 100%;
  padding-left: 16px;
}

.line {
  border: 0.5px solid #cdd2e1;
  height: 0.5px;
  width: 100%;
  margin-left: 16px;
}

.addIcon {
  display: flex;
  justify-content: center;
  width: 100%;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #75787d;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/pages/organisation/view/ChekListCard.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,iBAAA;EACA,mBAAA;EAEA,YAAA;EACA,kBAAA;AACR;;AAGA;EACI,cAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;AAAJ;;AAIA;EACI,2BAAA;EACA,aAAA;EACA,WAAA;EACA,iBAAA;AADJ;;AAIA;EACI,aAAA;EACA,uBAAA;EACA,WAAA;AADJ;;AAIA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AADJ","sourcesContent":[".modalbodyCheckListWrapper{\n    height: 100%;\n    .modal_body{\n        padding: 6px 24px;\n        margin-bottom: 20px;\n        //height: 70%;\n        height: 100%;\n        overflow-y: scroll;\n    }\n}\n\n.item_title{\n    color: #151515;\n    font-family: Montserrat;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 125%;\n    width: 100%;\n    padding-left: 16px;\n\n}\n\n.line{\n    border: .5px solid #cdd2e1;\n    height: .5px;\n    width: 100%;\n    margin-left: 16px;\n}\n\n.addIcon{\n    display: flex;\n    justify-content: center;\n    width: 100%;\n}\n\n.flexCenter{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #75787d;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
