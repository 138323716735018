import { useDispatch } from "react-redux";
import { setTitle } from "../redux/slices/titleSlice";
import { useEffect } from "react";

const Insights = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTitle("Stats/Insights"));
  }, []);
  return <div>Insights</div>;
};

export default Insights;
