import {
  Autocomplete,
  Avatar,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import PlusIcon from "../../../assets/images/plus";
import CustomBreadcrumb from "../../../components/breadcrumb/CustomBreadcrumb";
import Button from "../../../components/buttons/Button";
import CustomDateRangePicker from "../../../components/dateRangePicker/CustomDateRangePicker";
import CustomSearch from "../../../components/searchbar/CustomSearch";
import DataTable from "../../../components/table/DataTable";
import ToolBar from "../../../components/toolbar/ToolBar";
import {
  getGroupList,
  getLeadListOrg,
  getMainProjectStatusListOrg,
  getModeratorsListOrg,
  getOrganisationDetails,
  getOrgList,
  getPendingUserListOrg,
  getProjectListOrg,
  getProjectStatusListOrg,
  getUserListOrg,
} from "../../../redux/slices/orgSlice";
import { setTitle } from "../../../redux/slices/titleSlice";
import { CardItem } from "../../../utils/CardItem";
import { stringAvatar } from "../../../utils/util";
import AddGroupsModal from "./AddGroupsModal";
import AddOrganisationStatusModal from "./AddOrganisationStatusModal";
import DefaultAssigne from "./DefaultAssigne";
import EditOrganisationCard from "./EditOrganisationCard";
import GroupsViewCard from "./GroupsViewCard";
import InviteUserModal from "./InviteUserModal";
import ProjectStatusDetailsCard from "./ProjectStatusDetailsCard";
import "./style.scss";
import FilterIcon from "../../../assets/images/filter";
import FilterModal from "./FilterModal";
import { getMaterialList } from "../../../redux/slices/materialSlice";
import AddMaterialsModal from "./AddMaterialsModal";
import ProjectStatusCard from "./ProjectStatusCard";
import { Edit } from "@mui/icons-material";
import BasicModal from "../../../components/modal/Modal";
import FileUploadForm from "../../../components/project/view/fileupload/FileUploadForm";
import { modalStyle } from "../../../components/constants/ConstData";
import { singleFileUploadSuccess } from "../../../redux/slices/fileUploadSlice";
import Loading from "../../../components/loading/Loading";
import DefaultAssigneNew from "./DefaultAssigneNew";
import CheckListCard from "./CheckListCard";
// import { DataGrid } from "@mui/x-data-grid";
const leadColumns = [
  {
    field: "id",
    headerName: "Lead Id",
    width: 80,
  },
  {
    field: "name",
    headerName: "Lead Name",
    width: 150,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },
  {
    field: "address",
    headerName: "Address",
    width: 300,
    renderCell: (params) => {
      return <Typography>{params?.row?.address}</Typography>;
    },
  },
];
const projectColumns = [
  {
    field: "id",
    headerName: "Project Id",
    width: 80,
  },
  {
    field: "name",
    headerName: "Project Name",
    width: 150,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },
  {
    field: "address",
    headerName: "Address",
    width: 300,
    renderCell: (params) => {
      return <Typography>{params?.row?.address}</Typography>;
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 300,
    renderCell: (params) => {
      return <Typography>{params?.row?.finalStatus}</Typography>;
    },
  },
];
const teamColumns = [
  {
    field: "id",
    headerName: "User Id",
    width: 80,
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 300,
    renderCell: (params) => {
      return <Typography>{params?.row?.email}</Typography>;
    },
  },
  {
    field: "user_type",
    headerName: "User Type",
    width: 300,
    renderCell: (params) => {
      let userType = "User";
      if (params.row.isowner === "true") userType = "Owner";
      else if (params?.row?.isModerator == "true") userType = "Moderator";
      return <Typography>{userType}</Typography>;
    },
  },
];
const statusColumns = [
  {
    field: "order",
    headerName: "Order",
    width: 80,
  },
  {
    field: "name",
    headerName: "Name",
    width: 150,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },
];
const groupColumns = [
  {
    field: "id",
    headerName: "Group Id",
    width: 80,
  },
  {
    field: "mame",
    headerName: "Group Name",
    width: 250,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },

  {
    field: "description",
    headerName: "Description",
    width: 300,
    renderCell: (params) => <Typography>{params?.row?.desc}</Typography>,
  },
  // {
  //   //field: "actions",
  //  // headerName: "Actions",
  //   width: 100,
  //   renderCell: (params) => {
  //     const handleEditClick = (e) => {
  //       e.stopPropagation();
  //       console.log('inside handle edit click icon');
  //       // Implement your edit logic here
  //       console.log(`Edit clicked for row ${params.row.id}`);
  //     };
  //   //  const [openEditGroupModal,setOpenEditGroupModal]=useState(false);
  //     return (
  //       <IconButton onClick={handleEditClick} color="primary">
  //         <CustomEditIcon onClick={(e)=>{
  //           console.log('inside custom edit icon click fn')
  //           console.log('params',params.row)
  //           //setOpenEditGroupModal(true)

  //             e.stopPropagation();
  //         }}/>
  //       </IconButton>
  //     );
  //   },
  // },
];
const pendingUsersColumn = [
  {
    field: "id",
    headerName: "User Id",
    width: 80,
  },

  {
    field: "email",
    headerName: "Email",
    width: 300,
    renderCell: (params) => {
      return <Typography>{params?.row?.email}</Typography>;
    },
  },
];
const manageMaterialsColumn = [
  {
    field: "id",
    headerName: "Material Id",
    width: 80,
  },
  {
    field: "name",
    headerName: "Material Name",
    width: 200,
    // renderCell: (params) => {
    //   return <Typography>{params?.row?.name}</Typography>;
    // },
  },

  {
    field: "basePrice",
    headerName: "Base Price",
    width: 150,
    renderCell: (params) => <Typography>{params?.row?.baseprice}</Typography>,
  },
  {
    field: "unit",
    headerName: "Unit",
    width: 150,
    renderCell: (params) => <Typography>{params?.row?.baseuom}</Typography>,
  },
  {
    field: "description",
    headerName: "Description",
    width: 100,
    renderCell: (params) => <Typography>{params?.row?.desc}</Typography>,
  },
];
const breadcrumbs = [
  <Link key="1" to="/org">
    Organization
  </Link>,
  <Typography key="2" color="text.primary">
    Organization View
  </Typography>,
];

const LINKS = {
  USER_LINK: {
    INDEX: "Users",
    ALL: "All",
    INVITES: "Invites",
    MODERATORS: "Moderators",
  },
  PROJECTS_LINK: "Projects",
  LEADS_LINK: "Leads",
  WORKFLOW_LINK: {
    INDEX: "WorkFlow",
    MANAGE_STATUS: "Manage Status",
    MULTIPLE_ASSIGNE: "Multiple Assigne",
  },
  GROUPS: "Groups",
  SETTINGS_LINK: {
    INDEX: "Settings",
    // GROUPS: "Groups",
    // INVITE_PEOPLE: "Invite People",
    EDIT_ORGANISATION: "Edit Organization",
    // MANAGE_STATUS: "Manage Status",
    MANAGE_MATERIALS: "Manage Materials",
    CHECK_LIST: "Check List",
    // TRANSFER_OWNERSHIP: "Transfer Ownership",
  },
};
const OrganisationView = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { orgName, email } = location.state || {};
  const [selectedLink, setSelectedLink] = useState(LINKS.USER_LINK.INDEX);
  const [selectedSublink, setSelectedSublink] = useState(LINKS.USER_LINK.ALL);
  const [leadStartFrom, setLeadStartFrom] = useState("2021-01-01");
  const [leadStartEnd, setLeadStartEnd] = useState("2029-01-01");
  const [projectStartFrom, setProjectStartFrom] = useState("2021-01-01");
  const [projectStartEnd, setProjectStartEnd] = useState("2029-01-01");
  const [projectDueFrom, setProjectDueFrom] = useState("2021-01-01");
  const [projectDueEnd, setProjectDueEnd] = useState("2029-01-01");
  const [selectedUserType, setSelectedUserType] = useState("Users");

  const [projectStatusDetailsToShow, setProjectDetailsStatusToShow] = useState(
    {}
  );
  const [projectStatusCheckListToShow,setProjectStatusCheckListToShow]=useState({});
  const [groupDetailsToShow, setGroupDetailsToShow] = useState({});
  const [reload, setReload] = useState(false);
  const [showGroupDetailsCard, setShowGroupDetailsCard] = useState(false);
  const [projectStatusDetailsCardShow, setProjectStatusDetailsCard] =
    useState(false);
  const [showProjectStatusCard, setShowProjectStatusCard] = useState(false);

  const [searchValue, setSearchValue] = useState(null);
  const [projectMainStatusSelected, setProjectMainStatusSelected] =
    useState(null);

  const [openAddStatusModal, setOpenAddStatusModal] = useState(false);
  const [openAddGroupsModal, setOpenAddGroupsModal] = useState(false);
  const [openUserInviteModal, setOpenUserInviteModal] = useState(false);
  const [openProjectFilterModal, setOpenProjectFilterModal] = useState(false);
  const [openAddMaterialsModal, setOpenAddMaterialsModal] = useState(false);
  const [showCheckListcard, setShowCheckListCard] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const singleUpSuccess = useSelector((state) => state.upload.singleUpSuccess);
  const snackbarData = useSelector((state) => state.form.snackbarData);
  const moderatorList = useSelector((state) => state.org.moderatorsListOrg);
  const projectList = useSelector((state) => state.org.projectListOrg);
  const leadList = useSelector((state) => state.org.leadListOrg);
  const userList = useSelector((state) => state.org.userListOrg);
  const pendingUserList = useSelector((state) => state.org.pendingUserListOrg);
  const materials = useSelector((state) => state.material.materials);
  const group = useSelector((state) => state.org.group);
  const organisationDetails = useSelector(
    (state) => state.org.organisationDetails
  );
  const createData = useSelector((state) => state.form.createData);
  const projectStatusList = useSelector(
    (state) => state.org.projectStatusListOrg
  );
  const mainprojectStatusList = useSelector(
    (state) => state.org.mainProjectStatusListOrg
  );
  const organisationDetailsLoading = useSelector(
    (state) => state.org.organisationDetailsLoading
  );

  const changeLink = (link) => {
    setSelectedLink(link);
  };
  const changeSubLink = (parentLink, sublink) => {
    if (parentLink !== selectedLink) return;
    setSelectedSublink(sublink);
  };
  const formatDate = (date) => {
    if (!date) return "";
    return dayjs(date).format("YYYY-MM-DD"); // Adjust format as needed
  };

  const handleClose = () => {
    setIsOpen(false);
    dispatch(singleFileUploadSuccess(false));
  };

  const loadProjects = () => {
    dispatch(
      getProjectListOrg({
        url: `/crm/org/${id}/proj`,
        params: {
          orgId: Number(id),
          page: 1,
          pageSize: 10,
          startFrom: projectStartFrom,
          startEnd: projectStartEnd,
          dueFrom: projectDueFrom,
          dueEnd: projectDueEnd,
        },
      })
    );
  };
  const loadLeads = () => {
    dispatch(
      getLeadListOrg({
        url: `/crm/org/${id}/leads/list`,
        params: {
          orgId: Number(id),
          page: 1,
          pageSize: 10,
          startFrom: leadStartFrom,
          startEnd: leadStartEnd,
        },
      })
    );
  };

  const loadUsers = () => {
    dispatch(
      getUserListOrg({
        url: `/crm/user/organization/${id}/users`,
      })
    );
  };
  const loadModerators = () => {
    dispatch(
      getModeratorsListOrg({
        url: `/crm/org/${id}/moderators`,
      })
    );
  };
  const loadProjectStatus = () => {
    dispatch(
      getProjectStatusListOrg({
        url: `/crm/org/${id}/proj/status`,
        params: {
          orgId: id,
        },
      })
    );
  };
  const loadMainProjectStatusList = () => {
    dispatch(
      getMainProjectStatusListOrg({
        url: `/crm/org/${id}/proj/mainStatus`,
        params: {
          orgId: id,
        },
      })
    );
  };
  const loadGroups = () => {
    dispatch(
      getGroupList({
        url: `crm/user/groups/organization/${id}`,
        params: {},
      })
    );
  };
  const loadOrganisationDetails = () => {
    dispatch(
      getOrganisationDetails({
        url: `/crm/user/organization/${id}`,
        params: { orgId: id },
      })
    );
  };
  const loadPendingUsers = () => {
    dispatch(
      getPendingUserListOrg({
        url: `/crm/user/organization/${id}/users`,
        params: {
          orgId: id,
          isPending: 1,
        },
      })
    );
  };
  const loadMaterials = () => {
    // dispatch(getMaterialList({ url: `/crm/org/${id}/bom/store`, params: {} }));
  };
  useEffect(() => {
    loadProjects();
  }, [projectStartFrom, projectStartEnd, projectDueFrom, projectDueEnd]);
  useEffect(() => {
    loadLeads();
  }, [leadStartFrom, leadStartEnd]);
  useEffect(() => {
    loadUsers();
  }, [selectedUserType]);
  useEffect(() => {
    // loadModerators();
    dispatch(setTitle("Organization"));
  }, []);

  useEffect(() => {
    loadGroups();
  }, [createData]);
  useEffect(() => {
    loadOrganisationDetails();
  }, [createData]);
  useEffect(() => {
    loadPendingUsers();
  }, [createData]);
  useEffect(() => {
    loadMainProjectStatusList();
  }, []);
  useEffect(() => {
    loadMaterials();
  }, [createData]);
  useEffect(() => {
    console.log("reload", reload);
    loadProjectStatus();
  }, [createData, reload]);
  useEffect(() => {
    if (snackbarData?.createSuccess) {
      loadProjectStatus();
    }
  }, [snackbarData]);

  useEffect(() => {
    if (singleUpSuccess) {
      handleClose();
      loadOrganisationDetails();
      dispatch(getOrgList({ url: "crm/user/organization", params: {} }));
    }
  }, [singleUpSuccess]);

  if (organisationDetailsLoading) {
    return <Loading />;
  }

  return (
    <div className="organisation_view">
      <CustomBreadcrumb breadcrumbs={breadcrumbs} />
      <Grid sx={{ marginTop: "16px" }} container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <CardItem height="810px">
            <div
              className="d_flex flex_column align_items_center justify_content_center"
              style={{ paddingTop: "20px" }}
            >
              <div
                className="orgprofile_image_container"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {organisationDetails?.logodtls ? (
                  <img
                    src={organisationDetails?.logodtls}
                    alt="Organization"
                    className="orgprofile_image"
                    loading="lazy"
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(
                      organisationDetails?.name
                        ? organisationDetails.name
                        : orgName
                    )}
                    sx={{ bgcolor: "#388e3c" }}
                    className="orgprofile_image"
                  />
                )}

                {isHovered && (
                  <div className="edit_overlay">
                    <IconButton
                      className="edit_button"
                      size="small"
                      color="white"
                      onClick={() => setIsOpen(true)}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                )}
              </div>
              {/* {organisationDetails?.logodtls ? (
                <img
                  style={{ width: "56px", height: "56px", borderRadius: "50%" }}
                  src={organisationDetails?.logodtls}
                  alt="Organisation"
                />
              ) : (
                <Avatar
                  {...stringAvatar(
                    organisationDetails?.name
                      ? organisationDetails.name
                      : orgName
                  )}
                  sx={{ width: 56, height: 56, bgcolor: "#388e3c" }}
                />
              )} */}

              {/* </IconButton> */}
              <h2>
                {organisationDetails?.name ? organisationDetails.name : orgName}
              </h2>
              <div>
                <div className="d_flex">
                  <p>
                    <b>Email:</b>
                  </p>
                  <p className="marginL10">
                    {organisationDetails?.email
                      ? organisationDetails.email
                      : email}
                  </p>
                </div>
              </div>
              <div style={{ width: "85%" }}>
                <div
                  className={
                    selectedLink == LINKS.USER_LINK.INDEX
                      ? "organisation_link_box_active"
                      : "organisation_link_box"
                  }
                  onClick={() => {
                    setProjectStatusDetailsCard(false);
                    setShowGroupDetailsCard(false);
                    setShowProjectStatusCard(false);
                    setShowCheckListCard(false);
                    setSelectedSublink(LINKS.USER_LINK.ALL);
                    changeLink(LINKS.USER_LINK.INDEX);
                  }}
                >
                  <h3 className="organisation_link">Users</h3>
                </div>
                {selectedLink === LINKS.USER_LINK.INDEX && (
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div></div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div>
                        <div className="">
                          <h4
                            className={
                              selectedSublink === LINKS.USER_LINK.ALL
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            onClick={() =>
                              changeSubLink(
                                LINKS.USER_LINK.INDEX,
                                LINKS.USER_LINK.ALL
                              )
                            }
                          >
                            All Users
                          </h4>
                        </div>
                        <div>
                          <h4
                            className={
                              selectedSublink === LINKS.USER_LINK.MODERATORS
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            onClick={() =>
                              changeSubLink(
                                LINKS.USER_LINK.INDEX,
                                LINKS.USER_LINK.MODERATORS
                              )
                            }
                          >
                            Moderators
                          </h4>
                        </div>
                        <div>
                          <h4
                            className={
                              selectedSublink === LINKS.USER_LINK.INVITES
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            onClick={() =>
                              changeSubLink(
                                LINKS.USER_LINK.INDEX,
                                LINKS.USER_LINK.INVITES
                              )
                            }
                          >
                            Invites
                          </h4>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
                <div
                  onClick={() => {
                    setShowProjectStatusCard(false);
                    setShowGroupDetailsCard(false);
                    setProjectStatusDetailsCard(false);
                    setShowCheckListCard(false);
                    
                    changeLink(LINKS.GROUPS);
                  }}
                  className={
                    selectedLink == LINKS.GROUPS
                      ? "organisation_link_box_active"
                      : "organisation_link_box"
                  }
                >
                  <h3 className="organisation_link">Groups</h3>
                </div>
                <div
                  onClick={() => {
                    setShowGroupDetailsCard(false);
                    setProjectStatusDetailsCard(false);
                    changeLink(LINKS.PROJECTS_LINK);
                    setShowProjectStatusCard(false);
                  }}
                  className={
                    selectedLink == LINKS.PROJECTS_LINK
                      ? "organisation_link_box_active"
                      : "organisation_link_box"
                  }
                >
                  <h3 className="organisation_link">Projects</h3>
                </div>
                <div
                  onClick={() => {
                    setShowGroupDetailsCard(false);
                    setProjectStatusDetailsCard(false);
                    setShowProjectStatusCard(false);
                    setShowCheckListCard(false);
                    changeLink(LINKS.LEADS_LINK);
                  }}
                  className={
                    selectedLink == LINKS.LEADS_LINK
                      ? "organisation_link_box_active"
                      : "organisation_link_box"
                  }
                >
                  <h3 className="organisation_link">Leads</h3>
                </div>
                <div
                  onClick={() => {
                    setShowProjectStatusCard(true);
                    setShowGroupDetailsCard(false);
                    setProjectStatusDetailsCard(false);
                    setSelectedSublink("");
                    changeLink(LINKS.WORKFLOW_LINK.INDEX);
                    setShowCheckListCard(false);
                    // changeSubLink(
                    //   LINKS.WORKFLOW_LINK.INDEX,
                    //   LINKS.WORKFLOW_LINK.MANAGE_STATUS
                    // );
                  }}
                  className={
                    selectedLink == LINKS.WORKFLOW_LINK.INDEX
                      ? "organisation_link_box_active"
                      : "organisation_link_box"
                  }
                >
                  <h3 className="organisation_link">Work Flow</h3>
                </div>
                {selectedLink === LINKS.WORKFLOW_LINK.INDEX && (
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div></div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div>
                        {/* <div>
                          <h4
                            className={
                              selectedSublink ===
                              LINKS.WORKFLOW_LINK.MANAGE_STATUS
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            setProjectDetailsStatusToShow
                            onClick={() => {
                              setProjectStatusDetailsCard(false);
                              setShowProjectStatusCard(false);
                              changeSubLink(
                                LINKS.WORKFLOW_LINK.INDEX,
                                LINKS.WORKFLOW_LINK.MANAGE_STATUS
                              );
                            }}
                          >
                            Default Assigne
                          </h4>
                        </div> */}
                        <div>
                          <h4
                            className={
                              selectedSublink ===
                              LINKS.WORKFLOW_LINK.MULTIPLE_ASSIGNE
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            setProjectDetailsStatusToShow
                            onClick={() => {
                              setProjectStatusDetailsCard(false);
                              setShowProjectStatusCard(false);
                              setShowCheckListCard(false);
                              changeSubLink(
                                LINKS.WORKFLOW_LINK.INDEX,
                                LINKS.WORKFLOW_LINK.MULTIPLE_ASSIGNE
                              );
                            }}
                          >
                            Multiple Assigne
                          </h4>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                )}
                <div
                  onClick={() => {
                    setShowProjectStatusCard(false);
                    setShowGroupDetailsCard(false);
                    setProjectStatusDetailsCard(false);
                    setShowCheckListCard(false);
                    setSelectedSublink(LINKS.SETTINGS_LINK.EDIT_ORGANISATION);
                    changeLink(LINKS.SETTINGS_LINK.INDEX);
                    changeSubLink(
                      LINKS.SETTINGS_LINK.INDEX,
                      LINKS.SETTINGS_LINK.EDIT_ORGANISATION
                    );
                  }}
                  className={
                    selectedLink == LINKS.SETTINGS_LINK.INDEX
                      ? "organisation_link_box_active"
                      : "organisation_link_box"
                  }
                >
                  <h3 className="organisation_link">Settings</h3>
                </div>
                {selectedLink === LINKS.SETTINGS_LINK.INDEX && (
                  <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div></div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div>
                        <div>
                          <h4
                            className={
                              selectedSublink ===
                              LINKS.SETTINGS_LINK.EDIT_ORGANISATION
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            onClick={() =>
                              changeSubLink(
                                LINKS.SETTINGS_LINK.INDEX,
                                LINKS.SETTINGS_LINK.EDIT_ORGANISATION
                              )
                            }
                          >
                            Edit Organization
                          </h4>
                        </div>

                        <div>
                          <h4
                            className={
                              selectedSublink ===
                              LINKS.SETTINGS_LINK.MANAGE_MATERIALS
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            onClick={() =>
                              changeSubLink(
                                LINKS.SETTINGS_LINK.INDEX,
                                LINKS.SETTINGS_LINK.MANAGE_MATERIALS
                              )
                            }
                          >
                            Manage Materials
                          </h4>
                        </div>

                        {/* <div>
                          <h4
                            className={
                              selectedSublink === LINKS.SETTINGS_LINK.CHECK_LIST
                                ? "organisation_link_active"
                                : "organisation_link"
                            }
                            onClick={() =>
                              changeSubLink(
                                LINKS.SETTINGS_LINK.INDEX,
                                LINKS.SETTINGS_LINK.CHECK_LIST
                              )
                            }
                          >
                            Check List
                          </h4>
                        </div> */}
                      </div>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          </CardItem>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          {projectStatusDetailsCardShow ? (
            <>
              <ProjectStatusDetailsCard
                goBack={() => {
                  //loadProjectStatus();
                  setReload(!reload);

                  setProjectStatusDetailsCard(false);
                  setShowProjectStatusCard(true);
                  setShowCheckListCard(false);
                }}
                projectStatus={projectStatusDetailsToShow}
                reloadProjectStatusList={() => setReload(!reload)}
                projectStatusStagesList={projectStatusDetailsToShow.stages}
              />
            </>
          ) : showGroupDetailsCard ? (
            <>
              <GroupsViewCard
                groupDetails={groupDetailsToShow}
                goBack={() => setShowGroupDetailsCard(false)}
                orgId={id}
              />
            </>
          ) : showCheckListcard ? (
            <>
              <CheckListCard
                goBack={() => {
                  setShowCheckListCard(false);
                  setShowProjectStatusCard(true);
                }}
                projectStatus={projectStatusCheckListToShow}
              />
            </>
          ) : (
            <>
              <CardItem height="810px">
                <div className="card_container">
                  {/* <CardTitle title={selectedLink} type={"project_view"}></CardTitle> */}
                  <div style={{ padding: "20px" }}>
                    <ToolBar
                      title={
                        selectedLink === LINKS.WORKFLOW_LINK.INDEX &&
                        selectedSublink !== LINKS.WORKFLOW_LINK.MANAGE_STATUS &&
                        selectedSublink !== LINKS.WORKFLOW_LINK.MULTIPLE_ASSIGNE
                          ? "Status"
                          : selectedLink === LINKS.SETTINGS_LINK.INDEX &&
                            selectedSublink !==
                              LINKS.SETTINGS_LINK.CHECK_LIST &&
                            selectedSublink !==
                              LINKS.SETTINGS_LINK.MANAGE_MATERIALS
                          ? "Settings"
                          : selectedSublink ===
                              LINKS.SETTINGS_LINK.CHECK_LIST &&
                            selectedLink === LINKS.SETTINGS_LINK.INDEX
                          ? "Check List"
                          : selectedSublink ===
                              LINKS.SETTINGS_LINK.MANAGE_MATERIALS &&
                            selectedLink === LINKS.SETTINGS_LINK.INDEX
                          ? "Manage Materials"
                          : selectedLink === LINKS.PROJECTS_LINK
                          ? "Projects"
                          : selectedLink === LINKS.LEADS_LINK
                          ? "Leads"
                          : selectedLink === LINKS.GROUPS
                          ? "Groups"
                          : selectedLink === LINKS.USER_LINK.INDEX &&
                            selectedSublink === LINKS.USER_LINK.ALL
                          ? "Users"
                          : selectedLink === LINKS.USER_LINK.INDEX &&
                            selectedSublink === LINKS.USER_LINK.MODERATORS
                          ? "Moderators"
                          : selectedLink === LINKS.USER_LINK.INDEX &&
                            selectedSublink === LINKS.USER_LINK.INVITES
                          ? "User invites"
                          : selectedLink === LINKS.WORKFLOW_LINK.INDEX &&
                            selectedSublink ===
                              LINKS.WORKFLOW_LINK.MANAGE_STATUS
                          ? " Default Assigne"
                          : selectedLink === LINKS.WORKFLOW_LINK.INDEX &&
                            selectedSublink ===
                              LINKS.WORKFLOW_LINK.MULTIPLE_ASSIGNE
                          ? "Multiple Assigne"
                          : ""
                      }
                    >
                      <div className="d_flex align_items_center justify_content_between">
                        {
                          //  selectedLink == LINKS.PROJECTS_LINK ||
                          selectedLink == LINKS.LEADS_LINK ? (
                            <>
                              <div className="marginR10">
                                <CustomDateRangePicker
                                  label={"Start Date"}
                                  defaultValue={[
                                    dayjs("2021-01-01"),
                                    dayjs("2029-01-01"),
                                  ]}
                                  onChange={(e, value) => {
                                    if (e[0]) {
                                      if (selectedLink == "Leads") {
                                        setLeadStartFrom(formatDate(e[0]));
                                      }
                                      if (selectedLink == "Projects") {
                                        setProjectStartFrom(formatDate(e[0]));
                                      }
                                    }
                                    if (e[1]) {
                                      if (selectedLink == "Leads") {
                                        setLeadStartEnd(formatDate(e[1]));
                                      }
                                      if (selectedLink == "Projects") {
                                        setProjectStartEnd(formatDate(e[1]));
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </>
                          ) : selectedLink === LINKS.USER_LINK.INDEX &&
                            (selectedSublink == LINKS.USER_LINK.ALL ||
                              selectedSublink ===
                                LINKS.USER_LINK.MODERATORS) ? (
                            <>
                              <CustomSearch
                                placeholder={"Search"}
                                onChange={(e) => {
                                  let value = e.target.value.trim();
                                  if (value == "") {
                                    setSearchValue(null);
                                  } else {
                                    console.log(
                                      "e.target.value",
                                      e.target.value
                                    );
                                    setSearchValue(e.target.value);
                                  }
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )
                        }

                        {selectedLink == LINKS.PROJECTS_LINK && (
                          <>
                            <div className="d_flex align_items_center justify_content_between">
                              <div
                                style={{
                                  paddingTop: "9px",
                                  marginRight: "10px",
                                }}
                              >
                                {/* <Button
                                  type="submit"
                                  className="default_button d_flex align_items_center marginR10"
                                  leftIcon={<FilterIcon color="#75787D" />}
                                  onClick={()=>setOpenProjectFilterModal(true)}
                                >
                                  Filters
                                </Button> */}
                                <Autocomplete
                                  disablePortal
                                  clearIcon={null}
                                  id="combo-box-demo"
                                  // clearOnEscape
                                  options={mainprojectStatusList.map((item) => {
                                    return {
                                      label: item.displayname,
                                      id: item.id,
                                    };
                                  })}
                                  onChange={(e, value) => {
                                    setProjectMainStatusSelected(value.label);
                                  }}
                                  sx={{ width: 250 }}
                                  renderInput={(params) => {
                                    return (
                                      <>
                                        <TextField {...params} label="Status" />
                                      </>
                                    );
                                  }}
                                />
                              </div>
                              <div className="marginR10">
                                <CustomDateRangePicker
                                  label={"Start Date"}
                                  defaultValue={[
                                    dayjs("2021-01-01"),
                                    dayjs("2029-01-01"),
                                  ]}
                                  onChange={(e, value) => {
                                    if (e[0]) {
                                      if (selectedLink == "Leads") {
                                        setLeadStartFrom(formatDate(e[0]));
                                      }
                                      if (selectedLink == "Projects") {
                                        setProjectStartFrom(formatDate(e[0]));
                                      }
                                    }
                                    if (e[1]) {
                                      if (selectedLink == "Leads") {
                                        setLeadStartEnd(formatDate(e[1]));
                                      }
                                      if (selectedLink == "Projects") {
                                        setProjectStartEnd(formatDate(e[1]));
                                      }
                                    }
                                  }}
                                />
                              </div>

                              <CustomDateRangePicker
                                label={"Due Date"}
                                defaultValue={[
                                  dayjs("2021-01-01"),
                                  dayjs("2029-01-01"),
                                ]}
                                onChange={(e, value) => {
                                  if (e[0]) {
                                    if (selectedLink == LINKS.PROJECTS_LINK) {
                                      setProjectDueFrom(formatDate(e[0]));
                                    }
                                  }
                                  if (e[1]) {
                                    if (selectedLink == LINKS.PROJECTS_LINK) {
                                      setProjectDueEnd(formatDate(e[1]));
                                    }
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                        {selectedLink === LINKS.WORKFLOW_LINK.INDEX &&
                          selectedSublink !==
                            LINKS.WORKFLOW_LINK.MANAGE_STATUS &&
                          selectedSublink !==
                            LINKS.WORKFLOW_LINK.MULTIPLE_ASSIGNE && (
                            <>
                              <Button
                                type="submit"
                                className="primary_button d_flex align_items_center"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "18px",
                                  padding: "5px 20px",
                                }}
                                leftIcon={<PlusIcon color="#fff" />}
                                onClick={() => setOpenAddStatusModal(true)}
                              >
                                Add Status
                              </Button>
                            </>
                          )}
                        {selectedLink === LINKS.SETTINGS_LINK.INDEX &&
                          selectedSublink ===
                            LINKS.SETTINGS_LINK.MANAGE_MATERIALS && (
                            <>
                              <Button
                                type="submit"
                                className="primary_button d_flex align_items_center"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "18px",
                                  padding: "5px 20px",
                                }}
                                leftIcon={<PlusIcon color="#fff" />}
                                onClick={() => setOpenAddMaterialsModal(true)}
                              >
                                Add Material
                              </Button>
                            </>
                          )}
                        {selectedLink === LINKS.GROUPS && (
                          <>
                            <Button
                              type="submit"
                              className="primary_button d_flex align_items_center"
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                lineHeight: "18px",
                                padding: "5px 20px",
                              }}
                              leftIcon={<PlusIcon color="#fff" />}
                              onClick={() => setOpenAddGroupsModal(true)}
                            >
                              Add Group
                            </Button>
                          </>
                        )}
                        {selectedLink === LINKS.USER_LINK.INDEX &&
                          selectedSublink === LINKS.USER_LINK.INVITES && (
                            <>
                              <Button
                                type="submit"
                                className="primary_button d_flex align_items_center"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  lineHeight: "18px",
                                  padding: "5px 20px",
                                }}
                                leftIcon={<PlusIcon color="#fff" />}
                                onClick={() => setOpenUserInviteModal(true)}
                              >
                                Invite User
                              </Button>
                            </>
                          )}
                      </div>
                    </ToolBar>
                  </div>

                  <div style={{ padding: "20px" }}>
                    {selectedLink === LINKS.WORKFLOW_LINK.INDEX &&
                    selectedSublink !== LINKS.WORKFLOW_LINK.MANAGE_STATUS &&
                    showProjectStatusCard ? (
                      <>
                        {/* <DataTable
                          columns={statusColumns}
                          rows={projectStatusList}
                          onRowClick={(row) => {
                            setProjectDetailsStatusToShow(row.row);
                            setProjectStatusDetailsCard(true);
                          }}
                        /> */}
                        <ProjectStatusCard
                          orgId={id}
                          showProjectStatusStage={(projectstatus) => {
                            setReload(!reload);
                            //  setShowProjectStatusCard(false);
                            setProjectDetailsStatusToShow(projectstatus);
                            setShowProjectStatusCard(false);
                            setProjectStatusDetailsCard(true);
                          }}
                          showProjectStatusCheckList={(projectstatus) => {
                            setShowCheckListCard(true);
                            setShowProjectStatusCard(false);
                            setProjectStatusCheckListToShow(projectstatus)
                          }}
                          projectStatusList={projectStatusList}
                        />
                      </>
                    ) : selectedLink === LINKS.SETTINGS_LINK.INDEX &&
                      selectedSublink ===
                        LINKS.SETTINGS_LINK.EDIT_ORGANISATION ? (
                      <>
                        <EditOrganisationCard
                          organisationDetails={organisationDetails}
                          goBack={() => {
                            setSelectedLink(LINKS.SETTINGS_LINK.INDEX);
                            setSelectedSublink(
                              LINKS.SETTINGS_LINK.EDIT_ORGANISATION
                            );
                          }}
                        />
                      </>
                    ) : selectedLink === LINKS.SETTINGS_LINK.INDEX &&
                      selectedSublink ===
                        LINKS.SETTINGS_LINK.MANAGE_MATERIALS ? (
                      <>
                        {/* <div>Manage materials</div> */}
                        <DataTable
                          //rows={[...group].reverse()}
                          rows={[...materials].reverse()}
                          columns={manageMaterialsColumn}
                          // onRowClick={(row) => {
                          //   setGroupDetailsToShow(row.row);
                          //   setShowGroupDetailsCard(true);
                          // }}
                        />
                      </>
                    ) : selectedLink === LINKS.WORKFLOW_LINK.INDEX &&
                      selectedSublink === LINKS.WORKFLOW_LINK.MANAGE_STATUS ? (
                      <>
                        <div style={{ height: "700px", overflowY: "scroll" }}>
                          <DefaultAssigne />
                          {/* <DefaultAssigneNew/> */}
                        </div>
                      </>
                    ) : selectedLink === LINKS.WORKFLOW_LINK.INDEX &&
                      selectedSublink ===
                        LINKS.WORKFLOW_LINK.MULTIPLE_ASSIGNE ? (
                      <>
                        {/* <div style={{ height: "650px", overflowY: "scroll" }}> */}
                          <DefaultAssigneNew users={userList} groups={group} />
                        {/* </div> */}
                      </>
                    ) : selectedLink === LINKS.SETTINGS_LINK.INDEX &&
                      selectedSublink === LINKS.SETTINGS_LINK.CHECK_LIST ? (
                      <></>
                    ) : selectedLink === LINKS.GROUPS ? (
                      <>
                        <DataTable
                          rows={[...group].reverse()}
                          columns={groupColumns}
                          onRowClick={(row) => {
                            setGroupDetailsToShow(row.row);
                            setShowGroupDetailsCard(true);
                          }}
                        />
                      </>
                    ) : (
                      <DataTable
                      
                        rows={
                          selectedLink == LINKS.PROJECTS_LINK
                            ? projectMainStatusSelected
                              ? projectList
                                  .filter(
                                    (item) =>
                                      item.finalStatus ==
                                      projectMainStatusSelected
                                  )
                                  .reverse()
                              : [...projectList].reverse()
                            : selectedLink == LINKS.LEADS_LINK
                            ? [...leadList].reverse()
                            : selectedLink === LINKS.USER_LINK.INDEX &&
                              selectedSublink === LINKS.USER_LINK.ALL
                            ? searchValue
                              ? [
                                  ...userList
                                    .filter((item) => {
                                      return (
                                        item?.name
                                          ?.toLowerCase()
                                          ?.includes(
                                            searchValue?.toLowerCase()
                                          ) ||
                                        item?.email
                                          ?.toLowerCase()
                                          ?.includes(searchValue?.toLowerCase())
                                      );
                                    })
                                    .map((item) => {
                                      return {
                                        ...item,
                                        id: item.userId,
                                      };
                                    }),
                                ].reverse()
                              : [
                                  ...userList.map((item) => {
                                    return {
                                      ...item,
                                      id: item.userId,
                                    };
                                  }),
                                ].reverse()
                            : selectedLink === LINKS.USER_LINK.INDEX &&
                              selectedSublink === LINKS.USER_LINK.MODERATORS
                            ? searchValue
                              ? moderatorList.filter(
                                  (item) =>
                                    item.name
                                      .toLowerCase()
                                      .includes(searchValue?.toLowerCase()) ||
                                    item.email
                                      .toLowerCase()
                                      .includes(searchValue?.toLowerCase())
                                )
                              : [...moderatorList].reverse()
                            : selectedLink === LINKS.USER_LINK.INDEX &&
                              selectedSublink === LINKS.USER_LINK.INVITES
                            ? [
                                ...pendingUserList.map((item) => {
                                  return {
                                    ...item,
                                    id: item.userId,
                                  };
                                }),
                              ].reverse()
                            : []
                        }
                        columns={
                          selectedLink == LINKS.PROJECTS_LINK
                            ? projectColumns
                            : selectedLink == LINKS.LEADS_LINK
                            ? leadColumns
                            : selectedLink === LINKS.USER_LINK.INDEX &&
                              (selectedSublink === LINKS.USER_LINK.ALL ||
                                selectedSublink === LINKS.USER_LINK.MODERATORS)
                            ? teamColumns
                            : selectedLink === LINKS.USER_LINK.INDEX &&
                              selectedSublink === LINKS.USER_LINK.INVITES
                            ? pendingUsersColumn
                            : []
                        }
                      />
                    )}
                  </div>
                </div>
              </CardItem>
            </>
          )}
        </Grid>
      </Grid>
      <AddOrganisationStatusModal
        open={openAddStatusModal}
        close={() => setOpenAddStatusModal(false)}
        orgId={id}
        //  / totalStatuses={projectStatusList.length}
      />
      <AddGroupsModal
        open={openAddGroupsModal}
        close={() => setOpenAddGroupsModal(false)}
        orgId={id}
      />
      <InviteUserModal
        open={openUserInviteModal}
        close={() => setOpenUserInviteModal(false)}
        orgId={id}
      />
      <FilterModal
        open={openProjectFilterModal}
        close={() => setOpenProjectFilterModal(false)}
      />
      <AddMaterialsModal
        open={openAddMaterialsModal}
        close={() => setOpenAddMaterialsModal(false)}
        orgId={id}
      />
      <BasicModal
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        isOpen={isOpen}
        onClose={handleClose}
        sx={modalStyle}
      >
        <FileUploadForm
          onClose={handleClose}
          title={"Organization Logo"}

          // type={uploadDetails?.type}
          // typeId={uploadDetails?.typeId}
          // folderId={uploadDetails?.folderId}
        />
      </BasicModal>
    </div>
  );
};

export default OrganisationView;
