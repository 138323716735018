import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../redux/slices/modalSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", sm: "50%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "100%",
};

export default function BasicModal({
  sx,
  boxStyle,
  isOpen,
  children,
  onClose,
  hideBackdrop,
}) {
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <div>
      <Modal
        
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={sx}
        hideBackdrop={hideBackdrop}
      >
        <Box sx={boxStyle ?? style}>{children}</Box>
      </Modal>
    </div>
  );
}
