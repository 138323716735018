import { Paper, styled } from "@mui/material";

export const CardItem = styled(Paper)(({ theme, height, showscrollbar }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  borderRadius: "8px",
  color: theme.palette.text.secondary,
  height: height ?? "auto",
  boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
  marginBottom: "15px",
  // overflowY: showscrollbar ?? "auto",
  WebkitScrollBar: "none",
}));
