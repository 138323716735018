import { Avatar, IconButton } from "@mui/material";
import { stringAvatar } from "../../utils/util";

const UserAvatar = ({ name }) => {
  return (
    <IconButton sx={{ p: 0, marginRight: "8px" }}>
      <Avatar
        style={{ width: "30px", height: "30px" }}
        {...stringAvatar(name)}
      />
    </IconButton>
  );
};

export default UserAvatar;
