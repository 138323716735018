import { createSlice } from "@reduxjs/toolkit";

const appointmentSlice = createSlice({
  name: "appointment",
  initialState: {
    appointmentList: null,

    loading: false,
  },
  reducers: {
    createNewAppointment(state) {
      state.loading = true;
      return state;
    },

    editAppointment(state) {
      state.loading = true;
      return state;
    },

    cancelAppointment(state) {
      state.loading = true;
      return state;
    },

    getAppointmentList(state) {
      state.loading = true;
      return state;
    },

    setAppointmentList: (state, action) => {
      state.appointmentList = action.payload;
      state.loading = false;
    },
    setAppointmentLoadingFalse: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  createNewAppointment,
  getAppointmentList,
  setAppointmentList,
  editAppointment,
  cancelAppointment,
  setAppointmentLoadingFalse,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
