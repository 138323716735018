import { Box, Grid, IconButton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomAutoComplete from "../../../fields/autoComplete/CustomAutoComplete";
import Button from "../../../components/buttons/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGroupList, inviteNewUser } from "../../../redux/slices/orgSlice";
import { useEffect, useState } from "react";
import Storage from "../../../utils/Storage";

const UserInvite = () => {
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [groupSelected, setGroupSelected] = useState();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const group = useSelector((state) => state.org.group);
  const loading = useSelector((state) => state.org.loading);
  // const orgId = JSON.parse(sessionStorage.getItem("org")).id;
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;

  const createData = useSelector((state) => state.form.createData);

  const onSubmit = (data) => {
    data.orgId = id;
    data.groupId = groupSelected;
    dispatch(inviteNewUser({ url: "crm/user/invite", data: data, params: {} }));
  };

  useEffect(() => {
    if (open) {
      dispatch(
        getGroupList({ url: `crm/user/groups/organization/${id}`, params: {} })
      );
    }
  }, [open]);

  useEffect(() => {
    if (createData?.createSuccess) {
      navigate("/org");
    }
  }, [createData]);

  return (
    <div className="organisation">
      <div className="organisation_container">
        <div className="d_flex align_items_center">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <h2 style={{ margin: "0" }} className="text_center">
            Invite People
          </h2>
        </div>

        <div>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Grid sx={{ marginTop: "16px" }} container spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <label>Team Member</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  {...register("email", {
                    required: true,
                    pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <label>Group</label>
                <CustomAutoComplete
                  name="groupId"
                  className="user_access_level"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id
                  }
                  loading={loading}
                  options={group}
                  onChange={(event, newValue) => {
                    setGroupSelected(newValue.id);
                  }}
                  register={register}
                  renderOption={(props, option) => (
                    <Box style={{ fontSize: 13 }} {...props}>
                      {option.name}
                    </Box>
                  )}
                  value={groupSelected}
                  getOptionLabel={(option) => {
                    if (option.name) {
                      return option?.name;
                    } else {
                      return "Please Select";
                    }
                  }}
                  noOptionsText={
                    <div className="d_flex flex_column align_items_center justify_content_center">
                      <p>No Data Found</p>
                      <Button
                        className="primary_button_small"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/org/${orgId}/group/create`);
                        }}
                      >
                        Create New
                      </Button>
                    </div>
                  }
                  // label="Access level"
                  // {...register("access_level", {
                  //   required: true,
                  // })}
                />
              </Grid>
            </Grid>
            <Button type="submit" className="primary_button marginT10">
              Send Invites
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserInvite;
