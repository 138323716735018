import * as React from "react";
import Button from "../../../buttons/Button";
import CardTitle from "../../../dashboard/CardTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { modalStyle } from "../../../constants/ConstData";
import BasicModal from "../../../modal/Modal";
import AddIcon from "@mui/icons-material/Add";
import BOMForm from "./BOMForm";
import { useDispatch, useSelector } from "react-redux";
import { getBomList } from "../../../../redux/slices/materialSlice";
import { useParams } from "react-router-dom";
import Storage from "../../../../utils/Storage";
import CustomIconButton from "../../../buttons/CustomIconButton";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Edit, Padding } from "@mui/icons-material";
import DeveloperBoardOffIcon from "@mui/icons-material/DeveloperBoardOff";
import EmptyList from "../../../emptylist/EmptyList";
import Loading from "../../../loading/Loading";
import BOMFormItem from "./BOMFormItem";
import CurrencyView from "../../../currency/CurrencyView";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { generatePdf } from "../../../../utils/generatePdf";
const dealBreakdownList = [
  {
    name: "SOLAREDGE S440 POWER OPTIMIZERS",
    quantity: 29,
  },
  {
    name: "SOLAREDGE SE10000H-US (240V/10000W) INVERTER",
    quantity: 1,
  },
  {
    name: "EZ SOLARDECK JUNCTION BOX 2, EZSLR, JB-1.2",
    quantity: 1,
  },
];

const BOMCard = ({ title, type }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenEdit, setIsOpenEdit] = React.useState(false);

  const createData = useSelector((state) => state.form.createData);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { id } = useParams();
  const bomList = useSelector((state) => state.material.bomList);
  const bomLoading = useSelector((state) => state.material.bomLoading);
  const [totalBomPrice, setTotalBomPrice] = React.useState(0);

  const [editItem, setEditItem] = React.useState(null);

  const dispatch = useDispatch();

  const loadBom = () => {
    const baseUrl = `/crm/org/${orgId}/proj/${id}/bom`;
    const url = type === "adder" ? `${baseUrl}/adder` : baseUrl;
    dispatch(getBomList({ url: url, params: {} }));
  };

  const newBOM = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const downloadAsPdf = (e) => {
    e.preventDefault();
    let itemDetailsHeaders = [
      "Sl.No",
      "Item Name",
      "Price",
      "Unit",
      "Quantity",
      "Total",
    ];
    console.log("bomList[0]?.materials", bomList[0]?.materials);
    const itemDetailsRows = bomList[0]?.materials?.map((item, index) => [
      (index + 1).toString(),
      item.name.toString(),
      item.price?.toString(),
      item?.uom.toString(),
      item.qty?.toString(),
      (Number(item.price) * Number(item.qty)).toString(),
    ]);
    let columnWidths = [15, 50, 30, 30, 30, 30];
    // Define table styles
    const headerStyles = {
      fillColor: [240, 240, 240],
      textColor: [0],
      fontFamily: "Newsreader",
      fontStyle: "bold",
    };
    generatePdf(
      itemDetailsHeaders,
      itemDetailsRows,
      columnWidths,
      headerStyles
    );
  };

  const handleEdit = (item) => {
    console.log("Item=---------->", item);
    setEditItem(item);

    setIsOpenEdit(true);
  };

  const handleClose = () => {
    setEditItem(null);
    setIsOpen(false);
    setIsOpenEdit(false);
  };

  React.useEffect(() => {
    if (orgId) {
      loadBom();
    }
  }, []);

  React.useEffect(() => {
    if (createData?.createSuccess) {
      handleClose();
      loadBom();
    }
  }, [createData]);

  React.useEffect(() => {
    console.log("bomList=------------->", bomList);
  }, [bomList]);

  React.useEffect(() => {
    const totalValue = bomList[0]?.materials?.reduce((accumulator, item) => {
      return accumulator + item.qty * item.price;
    }, 0);
    setTotalBomPrice(totalValue);
  }, [bomList]);

  if (bomLoading) {
    return <Loading />;
  }

  return (
    <>
      <CardTitle
        title={totalBomPrice > 0 && <CurrencyView amount={totalBomPrice} />}
        type="project_view"
        style={{ padding: "20px 0" }}
      >
        <div>
          <div className="d_flex">
            <div style={{marginRight:"10px"}}>
              {/* {bomList[0]?.materials?.length > 0 && (
                <>
                <Tooltip title="Generate pdf" placement="top-start">
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={downloadAsPdf}
                  >
                    <PictureAsPdfIcon className="btn_icon" />
                  </IconButton>
                </Tooltip>

                </>
              )} */}
            </div>
            <div className="d_flex " style={{alignItems:'center'}}>
              <Button
                onClick={newBOM}
                className="icon_button d_flex align_items_center primary_bg text_white"
              >
                <AddIcon className="btn_icon" />
                Add New
              </Button>
            </div>
          </div>
        </div>
      </CardTitle>
      {bomList[0]?.materials?.length > 0 ? (
        bomList[0]?.materials?.map((item, index) => (
          <Accordion
            key={index}
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "4px",
              background: "rgba(55, 144, 107, 0.08)",
              border: "none",
              boxShadow: "none",
              marginBottom: "10px",
              ":before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#189d3d",
                  }}
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="w_100 d_flex align_items_center justify_content_between">
                <Typography
                  sx={{
                    color: "#189d3d",
                  }}
                  className="font_12_green_600"
                >
                  {item?.name}
                </Typography>

                <CurrencyView amount={item?.price * item?.qty} />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="d_flex align_items_center justify_content_between">
                <Typography className="m_0 font_12">
                  <b>Price:</b> {item?.price}
                </Typography>
                <Typography className="m_0 font_12">
                  <b>Quantity:</b> {item?.qty}
                </Typography>
                <Typography className="m_0 font_12">
                  <b>Unit:</b> {item?.uom}
                </Typography>
              </div>
              <div className="d_flex align_items_center justify_content_end">
                <IconButton size="small" onClick={() => handleEdit(item)}>
                  <Edit />
                </IconButton>
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <EmptyList
          icon={<DeveloperBoardOffIcon />}
          message={`No ${title} found`}
        />
      )}
      <BasicModal
        isOpen={isOpen}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <BOMForm
          onClose={handleClose}
          // editItem={editItem}
          view={bomList[0]?.materials.length > 0 ? "edit" : "new"}
          bomId={bomList[0]?.id}
          existingMaterials={bomList[0]?.materials}
          type={type}
        />
      </BasicModal>
      <BasicModal
        isOpen={isOpenEdit}
        onClose={handleClose}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "80%", sm: "60%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          // height: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        <BOMFormItem
          onClose={handleClose}
          editItem={editItem}
          bomId={bomList[0]?.id}
          existingMaterials={bomList[0]?.materials}
        />
      </BasicModal>
    </>
  );
};

export default BOMCard;
