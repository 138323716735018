import { put, fork, call, takeLatest } from "redux-saga/effects";
import { auth, facebookAuthProvider, googleAuthProvider } from "../../firebase";
import {
  authFailure,
  authSuccess,
  getSignInWithFacebook,
  getSignInWithGoogle,
  getUser,
  // getUser,
  getUserLogin,
  getUserLogout,
  getUserRegister,
  getUserResetPassword,
  getVerifyMobile,
  logoutSuccess,
  setUser,
  setUserFailed,
  verifySuccess,
} from "../slices/authSlice";
import { getItem } from "../../services/service";
import { setOrgList } from "../slices/orgSlice";
import { setCreateFailed, setCreateSuccess } from "../slices/formSlice";
import { setDialog } from "../slices/dialogSlice";
import Storage from "../../utils/Storage";

function* createUserSaga({ payload }) {
  try {
    const response = yield call(
      [auth, auth.createUserWithEmailAndPassword],
      payload.email,
      payload.password
    );
    auth.currentUser
      .updateProfile({
        displayName: payload.displayName,
      })
      .then(
        auth.currentUser
          .getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // Send token to your backend via HTTPS
            // ...
            if (idToken) {
              sessionStorage.setItem("token", JSON.stringify(idToken));
              // window.location.reload(false);

              // dispatch(getOrgList({ url: "crm/user/organization", params: {} }));
            }
          })
          .catch(function (error) {
            // Handle error
          })
      );

    yield put(authSuccess(response?.user));
  } catch (error) {
    yield put(authFailure(error.code));
  }
}

function* oncreateUserSaga() {
  yield takeLatest(getUserRegister.type, createUserSaga);
}

function* googleSignInSaga({ payload }) {
  try {
    const response = yield call([
      auth,
      auth.signInWithPopup(googleAuthProvider),
    ]);
    // auth.currentUser.updateProfile({
    //   displayName: payload.displayName,
    // });

    yield put(authSuccess(response?.user));
  } catch (error) {
    yield put(authFailure(error.code));
  }
}

function* onSignInWitthGoogleSaga() {
  yield takeLatest(getSignInWithGoogle.type, googleSignInSaga);
}

function* facebookSignInSaga({ payload }) {
  try {
    const response = yield call([
      auth,
      auth.signInWithPopup(
        facebookAuthProvider.addScope("user_birthday, email")
      ),
    ]);
    // auth.currentUser.updateProfile({
    //   displayName: payload.displayName,
    // });

    yield put(authSuccess(response?.user));
  } catch (error) {
    yield put(authFailure(error.code));
  }
}

function* onSignInWitthFacebookSaga() {
  yield takeLatest(getSignInWithFacebook.type, facebookSignInSaga);
}

function* loginUserSaga({ payload }) {
  try {
    auth.setPersistence("session");
    const response = yield call(
      [auth, auth.signInWithEmailAndPassword],
      payload.email,
      payload.password
    );
    // response.user.sendEmailVerification();
    // auth.currentUser.updateProfile({
    //   displayName: "Energyscape Renewables",
    // });

    yield put(authSuccess(response?.user));
  } catch (error) {
    yield put(authFailure(error.code));
  }
}

function* onLoginUserSaga() {
  yield takeLatest(getUserLogin.type, loginUserSaga);
}

function* logoutUserSaga() {
  try {
    const response = yield call([auth, auth.signOut]);
    sessionStorage.clear();
    Storage.clear();

    yield put(logoutSuccess({ message: "success" }));
    yield put(setOrgList(null));
  } catch (error) {
    // yield put(authFailure(error.code));
  }
}

function* onLogoutUserSaga() {
  yield takeLatest(getUserLogout.type, logoutUserSaga);
}

function* onLoadUserAsync({ payload }) {
  try {
    const { url, params } = payload;
    const response = yield call(getItem, { url, params });
    sessionStorage.setItem("user", JSON.stringify(response.data));
    // if (response.status === 200) {

    yield put(setUser({ ...response.data }));
    // }
  } catch (error) {
    if (error.message.includes("Firebase ID token has expired.")) {
      yield put(setDialog(true));
    } else {
      yield put(
        setCreateFailed({
          ...error?.errors?.data,
          createSuccess: false,
          statusCode: error.errors.status,
        })
      );
    }

    // yield put(setUserFailed(true));
  }
}

function* onLoadUser() {
  yield takeLatest(getUser.type, onLoadUserAsync);
}

function* UserResetPasswordSaga({ payload }) {
  try {
    const response = yield call(
      [auth, auth.sendPasswordResetEmail],
      payload.email
    );
    // response.user.sendEmailVerification();
    console.log("RESETRES=-------->", response);
    yield put(
      setCreateSuccess({
        message:
          "A link to reset your password is sent to your verified email Id. Please click the link and reset your password and login again",
        createSuccess: true,
        statusCode: 201,
      })
    );
    yield put(authSuccess(response?.user));
  } catch (error) {
    yield put(authFailure(error.code));
  }
}

function* onUserResetPasswordSaga() {
  yield takeLatest(getUserResetPassword.type, UserResetPasswordSaga);
}

export const authSagas = [
  fork(oncreateUserSaga),
  fork(onLoginUserSaga),
  fork(onLogoutUserSaga),
  fork(onSignInWitthGoogleSaga),
  fork(onSignInWitthFacebookSaga),
  fork(onLoadUser),
  fork(onUserResetPasswordSaga),
];
