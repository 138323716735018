import { Grid, IconButton } from "@mui/material";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import ModalBody from "../../../modal/ModalBody";
import { useForm } from "react-hook-form";
import TextInput from "../../../../fields/text/TextInput";
import { useEffect } from "react";
import ModalFooter from "../../../modal/ModalFooter";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createAdditionalInfo } from "../../../../redux/slices/additionalInfoSlice";

const FinancialDetailsForm = ({ onClose, financeDetails }) => {
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (financeDetails?.id) {
      data.id = financeDetails?.id;
    }
    const editedData = {
      financialdtl: [data],
    };

    dispatch(
      createAdditionalInfo({
        url: `/crm/org/${orgId}/proj/${id}/info?data=financialdtl`,
        data: editedData,
        params: {},
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (financeDetails?.financeType) {
      setValue("financeType", financeDetails?.financeType);
    }
    if (financeDetails?.loanAmount) {
      setValue("loanAmount", financeDetails?.loanAmount);
    }
    if (financeDetails?.baseppw) {
      setValue("baseppw", financeDetails?.baseppw);
    }
    // if (financeDetails?.adderppw) {
    //   setValue("adderppw", financeDetails?.adderppw);
    // }
    if (financeDetails?.dealerFee) {
      setValue("dealerFee", financeDetails?.dealerFee);
    }
    if (financeDetails?.totalppw) {
      setValue("totalppw", financeDetails?.totalppw);
    }
  }, [financeDetails]);

  return (
    <>
      <ModalHead title="Financial Info">
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Finance Type"
                // placeholder="Property Type"
                register={register}
                name="financeType"
              />
              <p className="error_message">{errors.financeType?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Loan Amount"
                // placeholder="Year Built"
                register={register}
                name="loanAmount"
              />
              <p className="error_message">{errors.loanAmount?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Base PPW"
                // placeholder="Area"
                register={register}
                name="baseppw"
              />
              <p className="error_message">{errors.baseppw?.message}</p>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Adder PPW"
                // placeholder="Home Type"
                register={register}
                name="adderppw"
              />
              <p className="error_message">{errors.adderppw?.message}</p>
            </Grid> */}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Dealer Fee"
                // placeholder="Home Type"
                register={register}
                name="dealerFee"
              />
              <p className="error_message">{errors.dealerFee?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Total PPW"
                // placeholder="Home Type"
                register={register}
                name="totalppw"
              />
              <p className="error_message">{errors.totalppw?.message}</p>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="primary_button">
            Save
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default FinancialDetailsForm;
