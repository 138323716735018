import { Grid, IconButton } from "@mui/material";
import ModalHead from "../../../modal/ModalHead";
import CloseIcon from "../../../../assets/images/close";
import ModalBody from "../../../modal/ModalBody";
import { useForm } from "react-hook-form";
import TextInput from "../../../../fields/text/TextInput";
import { useEffect } from "react";
import ModalFooter from "../../../modal/ModalFooter";
import Button from "../../../buttons/Button";
import Storage from "../../../../utils/Storage";
import { useParams } from "react-router-dom";
import { editProject } from "../../../../redux/slices/projectSlice";
import { useDispatch } from "react-redux";

const PropertyDetailsForm = ({ onClose, propertyDetails }) => {
  const userInfo = JSON.parse(sessionStorage.getItem("user"));

  const orgId = Storage.read(`org${userInfo?.id}`)?.id;
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("Data=---------->", data);
    if (propertyDetails?.imageUrl) {
      data.imageUrl = propertyDetails?.imageUrl;
    }
    const editedData = {
      propertyDetails: [data],
    };
    dispatch(
      editProject({
        url: `crm/org/${orgId}/proj/${id}`,
        data: editedData,
        params: {},
      })
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (propertyDetails?.propertyType) {
      setValue("propertyType", propertyDetails?.propertyType);
    }
    if (propertyDetails?.yearofBuilt) {
      setValue("yearofBuilt", propertyDetails?.yearofBuilt);
    }
    if (propertyDetails?.propertyArea) {
      setValue("propertyArea", propertyDetails?.propertyArea);
    }
    if (propertyDetails?.homeType) {
      setValue("homeType", propertyDetails?.homeType);
    }
    if (propertyDetails?.architecturalStyle) {
      setValue("architecturalStyle", propertyDetails?.architecturalStyle);
    }
    if (propertyDetails?.foundation) {
      setValue("foundation", propertyDetails?.foundation);
    }
    if (propertyDetails?.roof) {
      setValue("roof", propertyDetails?.roof);
    }
    if (propertyDetails?.wall) {
      setValue("wall", propertyDetails?.wall);
    }
  }, [propertyDetails]);

  return (
    <>
      <ModalHead title="Property Details">
        <div className="modal_header_button_wrapper">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </ModalHead>
      <form
        className="project_form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Property Type"
                // placeholder="Property Type"
                register={register}
                name="propertyType"
              />
              <p className="error_message">{errors.propertyType?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Year Built"
                // placeholder="Year Built"
                register={register}
                name="yearofBuilt"
              />
              <p className="error_message">{errors.yearofBuilt?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Area"
                // placeholder="Area"
                register={register}
                name="propertyArea"
              />
              <p className="error_message">{errors.propertyArea?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Home Type"
                // placeholder="Home Type"
                register={register}
                name="homeType"
              />
              <p className="error_message">{errors.homeType?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Architectural Style"
                // placeholder="Architectural Style"
                register={register}
                name="architecturalStyle"
              />
              <p className="error_message">
                {errors.architecturalStyle?.message}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Foundation"
                // placeholder="Foundatione"
                register={register}
                name="foundation"
              />
              <p className="error_message">{errors.foundation?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Roof"
                // placeholder="Roof"
                register={register}
                name="roof"
              />
              <p className="error_message">{errors.roof?.message}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextInput
                inputClass="input_field_style m_0"
                label="Wall"
                // placeholder="Roof"
                register={register}
                name="wall"
              />
              <p className="error_message">{errors.wall?.message}</p>
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" className="primary_button">
            Save
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default PropertyDetailsForm;
