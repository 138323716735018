import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    width={22}
    height={22}
    {...props}
  >
    <path
      d="M183.295 123.586H55.05a17.654 17.654 0 0 1-15.791-9.76l-12.776-25.55 12.776-25.55a17.652 17.652 0 0 1 15.791-9.76h128.246a17.654 17.654 0 0 1 15.791 9.76l12.775 25.55-12.776 25.55a17.652 17.652 0 0 1-15.791 9.76z"
      style={{
        fill: "#ffc36e",
      }}
    />
    <path
      d="M183.295 123.586H55.05a17.654 17.654 0 0 1-15.791-9.76l-12.776-25.55 12.776-25.55a17.652 17.652 0 0 1 15.791-9.76h128.246a17.654 17.654 0 0 1 15.791 9.76l12.775 25.55-12.776 25.55a17.652 17.652 0 0 1-15.791 9.76z"
      style={{
        fill: "#ffc36e",
      }}
    />
    <path
      d="M485.517 70.621H26.483a8.829 8.829 0 0 0-8.828 8.828v44.138h476.69V79.448a8.828 8.828 0 0 0-8.828-8.827z"
      style={{
        fill: "#eff2fa",
      }}
    />
    <path
      d="M17.655 105.931h476.69v17.655H17.655z"
      style={{
        fill: "#e1e6f2",
      }}
    />
    <path
      d="M494.345 88.276H217.318a8.827 8.827 0 0 0-7.895 4.879l-10.336 20.671a17.655 17.655 0 0 1-15.791 9.76H55.05a17.654 17.654 0 0 1-15.791-9.76L28.922 93.155a8.827 8.827 0 0 0-7.895-4.879h-3.372C7.904 88.276 0 96.18 0 105.931v335.448c0 9.751 7.904 17.655 17.655 17.655h476.69c9.751 0 17.655-7.904 17.655-17.655V105.931c0-9.751-7.904-17.655-17.655-17.655z"
      style={{
        fill: "#ffd782",
      }}
    />
    <path
      d="M485.517 441.379H26.483a8.829 8.829 0 0 1 0-17.656h459.034a8.828 8.828 0 0 1 0 17.656z"
      style={{
        fill: "#ffc36e",
      }}
    />
    <path
      d="M326.621 220.69h132.414a8.829 8.829 0 0 0 8.828-8.828v-70.621a8.829 8.829 0 0 0-8.828-8.828H326.621a8.829 8.829 0 0 0-8.828 8.828v70.621a8.829 8.829 0 0 0 8.828 8.828z"
      style={{
        fill: "#eff2fa",
      }}
    />
    <path
      d="M441.379 167.724h-97.103a8.829 8.829 0 0 1 0-17.656h97.103a8.828 8.828 0 0 1 0 17.656z"
      style={{
        fill: "#c7cfe2",
      }}
    />
    <path
      d="M441.379 203.034h-97.103a8.829 8.829 0 0 1 0-17.656h97.103a8.828 8.828 0 0 1 0 17.656z"
      style={{
        fill: "#d7deed",
      }}
    />
  </svg>
);
export default SvgComponent;
