var colors = [
  ["#49b6f51a", "#49B6F5"],
  ["#845adf1a", "#189D3D"],
  ["#e6533c1a", "#E6533C"],
  ["#f5b8491a", "#F5B849"],
  ["#26bf941a", "#26BF94"],

  // etc…
];

function getRandomColorCombination(colors) {
  var random = parseInt(Math.random() * colors.length);
  return colors[random];
}

const MultiColorText = ({ text }) => {
  return (
    <div
      style={{
        backgroundColor: getRandomColorCombination(colors)[0],
        color: getRandomColorCombination(colors)[1],
        opacity: 0.6,
        borderRadius: "20px",
        padding: "4px 8px",
        fontSize: "8px",
        fontFamily: "Inter",
        fontWeight: "600",
        lineHeight: "9.756px",
      }}
    >
      {text}
    </div>
  );
};

export default MultiColorText;
