import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/images/close";
import PlusIcon from "../../../assets/images/plus";
import Button from "../../../components/buttons/Button";
import { modalStyle } from "../../../components/constants/ConstData";
import BasicModal from "../../../components/modal/Modal";
import DataTable from "../../../components/table/DataTable";
import ToolBar from "../../../components/toolbar/ToolBar";
import CustomAutoComplete from "../../../fields/autoComplete/CustomAutoComplete";
import { getGroupList, inviteNewUser } from "../../../redux/slices/orgSlice";
import { getUserList } from "../../../redux/slices/userSlice";
import Storage from "../../../utils/Storage";

const columns = [
  {
    field: "id",
    headerName: "User Id",
    width: 80,
  },
  {
    field: "mame",
    headerName: "User Name",
    width: 150,
    renderCell: (params) => {
      return <Typography>{params?.row?.name}</Typography>;
    },
  },

  {
    field: "email",
    headerName: "Email",
    width: 300,
    renderCell: (params) => (
      <Typography>{params?.row?.email}</Typography>
    ),
  },
  {
    field: "mobile",
    headerName: "Phone",
    width: 300,
    renderCell: (params) => (
      <Typography>{params?.row?.customerInfo?.pContact?.mobile}</Typography>
    ),
  },
  {
    field: "user_type",
    headerName: "User Type",
    width: 300,
    renderCell: (params) => {
      let userType='User';
      if(params.row.isowner==='true')userType='Owner'
      else if(params?.row?.isModerator=='true')userType='Moderator'

      return (
      <Typography>{userType}</Typography>

      )
    },
  },
];

const UserList = () => {
  const [openInviteUserModal, setOpenInviteUserModal] = useState(false);
  const navigate = useNavigate();
  const userList = useSelector((state) => state.user.userList);
  const loading = useSelector((state) => state.lead.loading);
  const [groupSelected, setGroupSelected] = useState();
  const dispatch = useDispatch();
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const currentOrganization = Storage.read(`org${userInfo?.id}`);
  const [open, setOpen] = useState(false);

  const loadUsers = () => {
    console.log("currentOrganization.id", currentOrganization.id);
    dispatch(
      getUserList({
        url: `crm/user/organization/${currentOrganization.id}/users`,
      })
    );
  };
  const group = useSelector((state) => state.org.group);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.orgId = currentOrganization.id;
    data.groupId = groupSelected;
    dispatch(inviteNewUser({ url: "crm/user/invite", data: data, params: {} }));
  };

  useEffect(() => {
   // if (open) {
      dispatch(
        getGroupList({ url: `crm/user/groups/organization/${currentOrganization.id}`, params: {} })
      );
   // }
  }, []);
  useEffect(() => {
    loadUsers();
  }, []);
  return (
    <div style={{ padding: "25px" }} className="leads">
      <ToolBar title="Users">
        <div className="d_flex align_items_center">
          <Button
            type="submit"
            className="primary_button d_flex align_items_center"
            style={{
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "18px",
              padding: "5px 20px",
            }}
            leftIcon={<PlusIcon color="#fff" />}
            onClick={() => setOpenInviteUserModal(true)}
          >
            Invite User
          </Button>
        </div>
      </ToolBar>
      <div className="marginT10">
        <DataTable
          rows={[
            ...userList.map((item) => {
              return {
                ...item,
                id: item.userId,
              };
            }),
          ].reverse()}
          columns={columns}
         // onRowClick={(row) => navigate(`/users/${row.id}`)}
          loading={loading}
        />
      </div>

      <BasicModal
        isOpen={openInviteUserModal}
        onClose={() => setOpenInviteUserModal(false)}
        boxStyle={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "42%",
          bgcolor: "background.paper",
          boxShadow: 24,
          height: "400px",
          display: "flex",
          flexDirection: "column",
        }}
        sx={modalStyle}
      >
        {/* <LeadForm
          // loadLeads={loadLeads}
          onClose={() => setOpenInviteUserModal(false)}
        /> */}
        <>
          <div className="modal_header">
            <p className="modal_title ">Invite Users</p>
            <div className="modal_header_button_wrapper">
              <IconButton onClick={() => setOpenInviteUserModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          {/* <form
            className="project_form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          > */}
            <div className="modal_body">
              <div
                className="organisation"
                style={{ display: "block", padding: "0px" }}
              >
                <div className="">
                  <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid sx={{ marginTop: "16px" }} container spacing={2}>
                      <Grid item xs={12} sm={12} md={7} lg={7}>
                        <label>Team Member</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          {...register("email", {
                            required: true,
                            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <label>Group</label>
                        <CustomAutoComplete
                          name="groupId"
                          className="user_access_level"
                          open={open}
                          onOpen={() => {
                             setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value.id
                          }
                          loading={loading}
                          options={group}
                          onChange={(event, newValue) => {
                            setGroupSelected(newValue.id);
                          }}
                          register={register}
                          renderOption={(props, option) => (
                            <Box style={{ fontSize: 13 }} {...props}>
                              {option.name}
                            </Box>
                          )}
                          value={groupSelected}
                          getOptionLabel={(option) => {
                            if (option.name) {
                              return option?.name;
                            } else {
                              return "Please Select";
                            }
                          }}
                          noOptionsText={
                            <div className="d_flex flex_column align_items_center justify_content_center">
                              <p>No Data Found</p>
                              <Button
                                className="primary_button_small"
                                onClick={(e) => {
                                  e.preventDefault();
                                  // navigate(`/org/${orgId}/group/create`);
                                }}
                              >
                                Create New
                              </Button>
                            </div>
                          }
                          // label="Access level"
                          // {...register("access_level", {
                          //   required: true,
                          // })}
                        />
                      </Grid>
                    </Grid>
                    <Button type="submit" className="primary_button marginT10">
                      Send Invites
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          {/* </form> */}
        </>
      </BasicModal>
    </div>
  );
};

export default UserList;
