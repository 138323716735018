import CardTitle from "./CardTitle";
import SearchBar from "../searchbar/SearchBar";
import DropdownButton from "../dropdown/DropdownButton";
import DataTable from "../table/DataTable";
import UserDetails from "../userdetails/UserDetails";
import AvatarIcon from "../../assets/images/dealer.jpeg";
import DownloadIcon from "../../assets/images/dowload.svg";
import EditIcon from "../../assets/images/edit.svg";
import MultiColorText from "../text/MulticolorText";
import CustomIconButton from "../buttons/CustomIconButton";

const dropdownList = [
  { title: "Name", value: "name" },
  { title: "Date", value: "date" },
  { title: "Category", value: "category" },
];

const columns = [
  {
    field: "sales_rep",
    headerName: "Sales Rep",
    width: 150,
    renderCell: (params) => (
      <UserDetails name={params.value} image={params.row.userIcon} />
    ),
  },
  { field: "category", headerName: "Category", width: 150 },
  { field: "mail", headerName: "Mail", width: 200 },
  {
    field: "location",
    headerName: "Location",
    width: 100,
    renderCell: (params) => <MultiColorText text={params.value} />,
  },
  {
    field: "date",
    headerName: "Date",
    width: 200,
  },
  {
    field: "action",
    headerName: "Action",
    width: 150,
    renderCell: (params) => (
      <div className="d_flex align_items_center">
        <CustomIconButton
          icon={DownloadIcon}
          sx={{
            backgroundColor: "rgba(38, 191, 148, 0.1)",
            borderRadius: "4px",
            marginRight: "10px",
          }}
        />
        <CustomIconButton
          icon={EditIcon}
          sx={{
            backgroundColor: "rgba(132, 90, 223, 0.10)",
            borderRadius: "4px",
          }}
        />
      </div>
    ),
  },
];

const rows = [
  {
    id: 1,
    sales_rep: "Mayor Kelly",
    category: "Manufacture",
    mail: "mayorkelly@gmail.com",
    location: "Germany",
    date: "Sep 15 - Oct 12, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
  {
    id: 2,
    sales_rep: "Andrew Garfield",
    category: "Development",
    mail: "andrewgarfield@gmail.com",
    location: "Canada",
    date: "Apr 10 - Dec 12, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
  {
    id: 3,
    sales_rep: "Simon Cowel",
    category: "Service",
    mail: "simoncowel234@gmail.com",
    location: "Europe",
    date: "Sep 15 - Oct 12, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
  {
    id: 4,
    sales_rep: "Mirinda Hers",
    category: "Stark",
    mail: "mirindahers@gmail.com",
    location: "USA",
    date: "Apr 14 - Dec 14, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
  {
    id: 5,
    sales_rep: "Jacob Smith",
    category: "Marketing",
    mail: "jacobsmith@gmail.com",
    location: "Singapore",
    date: "Feb 25 - Nov 25, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
  {
    id: 6,
    sales_rep: "Cowel Mirinda",
    category: "Social Plataform",
    mail: "cowelmirinda@gamil.com",
    location: "USA",
    date: "Apr 14 - Dec 14, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
  {
    id: 7,
    sales_rep: "Garfield Mayor",
    category: "Social Plataform",
    mail: "garfieldmayor@gamil.com",
    location: "Singapore",
    date: "Feb 25 - Nov 25, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
  {
    id: 8,
    sales_rep: "Kelly Jacob",
    category: "Marketing",
    mail: "kellyjacob@gmail.com",
    location: "Europe",
    date: "Sep 15 - Oct 12, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
  {
    id: 9,
    sales_rep: "Andrew Smith",
    category: "Service",
    mail: "andrewsmith@gmail.com",
    location: "Canada",
    date: "Feb 25 - Nov 25, 2023",
    action: "action",
    userIcon: AvatarIcon,
  },
];

const ProjectStatistics = () => {
  return (
    <div className="card_container">
      <CardTitle title="Project Statistics">
        <div className="d_flex align_items_center">
          <SearchBar />
          <DropdownButton
            className="primary_button_small"
            id="sort_by"
            title="Sort By"
            options={dropdownList}
          />
        </div>
      </CardTitle>
      <div className="card_content">
        {/* <DataTable
          rows={rows}
          columns={columns}
          style={{ height: 390, width: "100%" }}
        /> */}
      </div>
    </div>
  );
};

export default ProjectStatistics;
